import React, { useEffect, useState, useRef} from 'react'
import styled from "styled-components"
import applications from '../util/application'
import aws from '../util/aws'
import { AdditionalAttachments, MessageBar, Row, Text, BoxRow, Box, Cells, Cell, Check, Upload, Dots, Menu, Label, Button, AddDocuments, SelectPacket, Required, Add} from './index'
import $ from 'jquery'
import randomString from 'randomstring'
import JSZip from 'jszip';
import saveAs from 'save-as'

const Attached = styled(Label)`
  margin-right: 32px;
`

export default props => {
  const [app, setApp] = useState(props.application)
  const [showAddDocuments, setShowAddDocuments] = useState(false)

  async function download(files, name){
    const zip = new JSZip();

    for(var i in files){
      const buffer = await aws.getObject(files[i])
      zip.file(files[i], buffer.data);
    }

    const zipped = await zip.generateAsync({ type: "blob" })
    saveAs(zipped, name.replace(/ /g, '_') + "_attachments.zip")
  }

  async function downloadAll(documents){
    const zip = new JSZip();

    for(var i in documents){
      const files = documents[i].files
      for(var j in files){
        const buffer = await aws.getObject(files[j])
        zip.file(files[j], buffer.data);
      }
    }

    const zipped = await zip.generateAsync({ type: "blob" })
    saveAs(zipped, props.application.myInfo.contact_info.name.replace(/ /g, '_') + "_attachments.zip")
  }

  async function clearAttached(doc){
    const check = window.confirm('Are you sure you want to remove attached documents?')
    if(check){
      doc.files = undefined
      const application = await applications.get(app.id)
      for(var i in application.data.myDocuments.required_documents){
        if(application.data.myDocuments.required_documents[i].title == doc.title){
          application.data.myDocuments.required_documents[i] = doc
        }
      }
      await applications.update(application.data)
      props.refresh()
    }
  }

  async function remove(doc){
    const check = window.confirm('Are you sure you want to remove required document from packet?')
    if(check){
      const application = await applications.get(app.id)
      application.data.myDocuments.required_documents = application.data.myDocuments.required_documents.filter(item => item.title !== doc.title)
      await applications.update(application.data)
      props.refresh()
    }
  }

  useEffect(() => {
    setApp(props.application)
  }, [props.application]);

  return (
    <Row direction="column" padding={"32px 40px"}>
      <Row height={"56px"} justify="space-between" alignItems="center" padding={"0 5px 16px 5px"}>
        <SelectPacket {...props} refresh={props.refresh} application={app}/>
      </Row>

      <Box>
        <BoxRow padding={"16px 24px"} justify="space-between" alignItems="center">
          <Text kind="p" weight={800} size={"14px"} uppercase>Attachments</Text>

          <Cells>
            <Cell width={15}>
              <AdditionalAttachments refresh={props.refresh} application={app} />
            </Cell>

            <Cell width={35}>
              <Menu options={[{label: 'Add Attachment(s)', action: () => setShowAddDocuments(true) }, {label: 'Download All', action: () => downloadAll(app.myDocuments.required_documents)}]} />
            </Cell>
          </Cells>
        </BoxRow>

        {app.myDocuments.required_documents && app.myDocuments.required_documents.length ? app.myDocuments.required_documents.map((doc, index) => (
          <BoxRow padding={"16px 24px"} justify="space-between" alignItems="center" key={index}>
            <Row alignItems="center">
              <Check checked={(doc.files && doc.files.length > 0) || doc.object_name || doc.received} />
              <Text kind="p" size={"15px"}>{doc.title} {doc.required && <Required />}</Text>
            </Row>

            <Cells>
              <Cell width={140}>
                {doc.object_name && !doc.files ? <Attached>{doc.object_name}</Attached> : null}
                {doc.files && doc.files.length > 0 ? <Attached>{doc.files.length} File(s) Attached</Attached> :  null}
                {doc.received && (!doc.files && (doc.files && !doc.files.length)) ? <Attached>Received</Attached> :  null}
              </Cell>

              <Cell width={110}>
                <Upload application={app} document={doc} complete={e => props.refresh(e)}>
                  {doc.object_name ? (
                    <Button secondary={doc.object_name}>{doc.object_name ? 'Change' : 'Attach'}</Button>
                  ) : (
                    <Button secondary={doc.files && doc.files.length > 0}>{doc.files && doc.files.length > 0 ? 'Change' : 'Attach'}</Button>
                  )}
                </Upload>
              </Cell>

              <Cell width={35}>
                <Dots src="icon-dots.svg" options={[{label: 'Download', disabled: (!doc.files && !doc.object_name), action: () => download(doc.object_name ? [doc.object_name] : doc.files, doc.title)}, {label:'Clear Attached', disabled: (!doc.files && !doc.object_name), action: () => clearAttached(doc)}, {label:'Remove', action: () => remove(doc)}]} />
              </Cell>
            </Cells>

          </BoxRow>
        )) : <BoxRow padding={"16px 24px"} justify="space-between" alignItems="center"><Text kind="p">No attachments requested</Text></BoxRow>}

      </Box>

      <AddDocuments application={app} show={showAddDocuments} close={() => setShowAddDocuments(false)} complete={() => props.refresh()} />
    </Row>
  )
}
