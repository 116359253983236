import React from 'react'
import styled from "styled-components"

const DocWrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding: 30px;
width: 100%;
  margin: 0 auto;
  min-height: 100vh;

  .esign-doc-workarea {
    position: relative;
    margin: 10px auto;
    max-width: 816px;
    width: 100%;
  }

  .esign-page {
    margin: 10px auto;
    background: white;
    position: relative;
    width: 100%;
    width: 816px;
    height: 1056px;
    box-shadow: 0 0 12px rgba(0,0,0,0.2);
    margin-top: -1px;
    transform-origin: top center;
    background-size: cover !important;
  }

  .esign-field {
    position: absolute;
    min-height: 12px;
    display: flex;
    background: white;

    .checkbox-outline, .signature-box, .initial-box, .text-input {
      &.error {
        border: 2px solid red !important;
      }
    }

    &[data-type="signature"] {
      .signature {
        font-family: 'Homemade Apple', cursive;
        white-space: nowrap;
        padding-top: 3px;
      }

      .signature-box {
        cursor: pointer;
        border: 2px solid #1E9CD7;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        p {
          font-size: 18px;
          white-space: nowrap;
        }
        .instructions {
          position: absolute;
          top: 115%;
          left: 8px;
          white-space: nowrap;
          font-size: 12px;
          display: none;
          font-family: 'Open Sans', sans-serif;
          font-weight: 900;
          color: red;
        }

        &:focus {
          box-shadow: 0px 0px 15px #2a9dd4;
          .instructions {
            display: block;
          }
        }
      }
    }

    &[data-type="initials"] {
      .initial-box {
        cursor: pointer;
        border: 2px solid #1E9CD7;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        p {
          font-size: 22px;
        }
        .instructions {
          position: absolute;
          top: 130%;
          left: -18px;
          white-space: nowrap;
          font-size: 12px;
          display: none;
          font-family: 'Open Sans', sans-serif;
          font-weight: 900;
          color: red;
        }
        &:focus {
          box-shadow: 0px 0px 15px #2a9dd4;
          .instructions {
            display: block;
          }
        }
      }
    }

    &[data-type="checkbox"] {
      .checkbox-outline {
        width: 20px !important;
        height: 20px !important;
        border: 2px solid #1E9CD7;
        border-radius: 3px;
      }
      input[type=checkbox] {
        display: block;
        margin: 0 1px;
        font-size: 1em;
        width: 1em;
        padding: 0;
        opacity: 1;
        border: 1px solid #1E9CD7;
      }
    }

    &[data-textalignment="Center"] {
      text-align: center !important;
      align-self: center;
    }

    &[data-textalignment="Right"] {
      text-align: right !important;
    }

    &[data-isrequired="true"] {
      &:before {
        content: "*";
        font-family: "Glyphicons Halflings";
        position: absolute;
        right: -12px;
        top: -2px;
        font-size: 9px;
        color: red;
      }
    }

    &[data-filler="Community"] {
      input[type=text], textarea, .date, .text {
        border: 2px solid #1E9CD7;
      }
    }

    input[type=text], textarea, .date, .text {
      font-size: 11px;
      padding: 0 5px;
      border-radius: 0px;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      margin: 0;
      color: black;
      letter-spacing: 0px;
    }
  }

  @media (max-width: 786px) {
    .esign-doc-wrapper {
      zoom: .55;
    }
  
  }
`

export default props => <DocWrapper {...props} />
