import React from 'react'
import styled from "styled-components"

const Label = styled.label`
  font-size: 12px;
  display: block;
  color: ${props => props.color ? props.color + ' !important' : '#4D5864'};
  margin: 4px;
  @media (prefers-color-scheme: dark) {
    color: white;
  }
`

export default props => <Label {...props} />
