import { css } from 'styled-components';

const sizes = {
  phonePlus: '440px',
  tablet: '768px',
  tabletPlus: '960px',
  laptop: '1024px',
  laptopPlus: '1150px',
  desktop: '1230px',
};

export default Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media ${label === 'laptop' && 'screen and '} (min-width: ${sizes[label]}) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});
