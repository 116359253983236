import React, { useEffect, useState, useRef} from 'react'
import styled from "styled-components"
import applications from '../util/application'
import agreements from '../util/agreements'
import packets from '../util/packets'
import { PrintAll, AddAgreements, MessageBar, Row, Text, BoxRow, Checkbox, Box, Cells, Cell, Check, Upload, Dots, Menu, Label, Button, Input, Select, SelectPacket, Modal, UploadPhysician, Pill, Required } from './index'
import randomString from 'randomstring'
import JSZip from 'jszip';
import saveAs from 'save-as'
import twilio from '../util/twilio'
import moment from 'moment'
import emailer from '../util/emails'
import aws from '../util/aws'
import request from 'request'
import FormData from 'form-data'

const Mark = styled(Check)`
  cursor: pointer
`

const Overflow = styled.div`
  max-height: 295px;
  overflow: scroll;
`

export default props => {
  const [app, setApp] = useState()
  const [physician, setPhysician] = useState({})
  const [message, setMessage] = useState()
  const [changed, change] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [contactMethod, setContactMethod] = useState()
  const [faxes, setFaxes] = useState()
  const [emails, setEmails] = useState()
  const [showAddDocuments, setShowAddDocuments] = useState(false)
  const [showPrintAllModal, setShowPrintAllModal] = useState(false)
  const [printAgreements, setPrintAllAgreements] = useState()

  function updateField(e){
    physician[e.target.name] = e.target.value
    setPhysician(physician)
  }

  function updatePreferred(e){
    physician.physician_contact_method = e.value
    setPhysician(physician)
  }

  async function savePhysicianInformation(){
    const latestApplication = await applications.get(app.id)
    latestApplication.data.myInfo.physician = physician
    await applications.update(latestApplication.data)
    setMessage('Physician Information Updated')
    change(!changed)
    props.refresh()
  }

  function markAgreement(agreement){
    for(var i in app.agreements.physician){
      if(app.agreements.physician[i].template_id == agreement.template_id) {
        if(app.agreements.physician[i].marked) {
          app.agreements.physician[i].marked = false
        } else {
          app.agreements.physician[i].marked = true
        }
      }
    }
    setApp(app)
  }

  async function sendPhysicianDocuments(){
    if(contactMethod == 'Fax'){
      if(!physician.physician_fax_number){
        setMessage('Please enter a fax number')
        change(!changed)
      } else {
        setMessage('Sending Fax...')
        change(!changed)
        try{
          const pdfKey = await agreements.generatePhysicianFaxPdf(app)
          const faxed = await twilio.sendFax(physician.physician_fax_number, pdfKey.data.Key)
          const latestApplication = await applications.get(app.id)
          if(!latestApplication.data.progress.physician) latestApplication.data.progress.physician = {faxes: []}
          latestApplication.data.progress.physician.faxes.push(faxed.data)
          await applications.update(latestApplication.data)
          setMessage('Fax Sent!')
          change(!changed)
          setShowModal(false)
          props.refresh()
        } catch {
          setMessage('Error sending fax')
          change(!changed)
        }
      }
    }

    if(contactMethod == 'Email'){
      if(!physician.physician_email_address){
        setMessage('Please enter an email address')
        change(!changed)
      } else {
        const array = []
        for(var i in app.agreements.physician){
          for(var j in app.agreements.physician[i].pages){
            const object = {
              filename: `${app.agreements.physician[i].name}-page-${i}.${app.agreements.physician[i].pages[j].background.split('.').pop()}`,
              path: app.agreements.physician[i].pages[j].background
            }
            array.push(object)
          }
        }
        //const pdfKey = await agreements.generatePhysicianFaxPdf(app)
        //const pdfObject = await aws.getObject(pdfKey.data.Key)
        //console.log(pdfObject)
        const obj = {
          from: "hello@seniorsign.io",
          to: physician.physician_email_address,
          subject: `${app.myInfo.contact_info.name} needs forms signed`,
          message: `Please print and sign the attached documents for ${app.myInfo.contact_info.name}`,
          attachments: array
        }
        setMessage('Sending Email')
        change(!changed)
        const sent = await emailer.sendNew(obj)

        const latestApplication = await applications.get(app.id)
        if(!latestApplication.data.progress.physician) latestApplication.data.progress.physician = {faxes: [], emails: []}
        delete sent.data.raw
        sent.data.dateCreated = new Date()
        latestApplication.data.progress.physician.emails.push(sent.data)
        await applications.update(latestApplication.data)

        setMessage('Email sent successfully')
        change(!changed)
        setShowModal(false)
        props.refresh()
        console.log(sent)
      }
    }
  }

  async function findFaxes(application){
    if(application.progress.physician){
      const array = []
      for(var i in application.progress.physician.faxes){
        let fax = await twilio.fetchFax(application.progress.physician.faxes[i].sid)
        array.push(fax.data)
      }
      setEmails(application.progress.physician.emails)
      setFaxes(array)
    }
  }

  async function download(files, name){
    const zip = new JSZip();

    for(var i in files){
      const buffer = await aws.getObject(files[i])
      zip.file(files[i], buffer.data);
    }

    const zipped = await zip.generateAsync({ type: "blob" })
    saveAs(zipped, name.replace(/ /g, '_') + "_phsyician_attachments.zip")
  }

  async function clearAttached(doc){
    const check = window.confirm('Are you sure you want to remove attached documents?')
    if(check){
      doc.files = undefined
      const application = await applications.get(app.id)
      for(var i in application.data.agreements.physician){
        if(application.data.agreements.physician[i].name == doc.name){
          application.data.agreements.physician[i] = doc
        }
      }
      await applications.update(application.data)
      props.refresh()
    }
  }

  async function remove(doc){
    const check = window.confirm('Are you sure you want to remove document from packet?')
    if(check){
      const application = await applications.get(app.id)
      application.data.agreements.physician = application.data.agreements.physician.filter(item => item.name !== doc.name)
      await applications.update(application.data)
      props.refresh()
    }
  }

  function toggleAgreement(agreement){
    for(var i in app.agreements.physician){
      if(app.agreements.physician[i].template_id == agreement.template_id) app.agreements.physician[i].selected = !app.agreements.physician[i].selected
    }
  }

  function printAll(){
    const printPacket = {
      agreements: {
        resident: app.agreements.physician.filter(agreement => agreement.selected)
      }
    }
    if(app.agreements.physician.filter(agreement => agreement.selected).length > 0) {
      setPrintAllAgreements(printPacket)
      setShowPrintAllModal(false)
    } else {
      alert('Please select at least one document to print')
    }
  }

  async function downloadAll(documents){
    const zip = new JSZip();

    for(var i in documents){
      const files = documents[i].files
      for(var j in files){
        const buffer = await aws.getObject(files[j])
        zip.file(files[j], buffer.data);
      }
    }

    const zipped = await zip.generateAsync({ type: "blob" })
    saveAs(zipped, props.application.myInfo.contact_info.name.replace(/ /g, '_') + "_physician_documents.zip")
  }

  async function markeAllReceived(documents){
    for(var i in documents) documents[i].received = true
    const application = await applications.get(app.id)
    application.data.agreements.physician = documents
    await applications.update(application.data)
    props.refresh()
  }

  async function markReceived(doc){
    const application = await applications.get(app.id)
    for(var i in application.data.agreements.physician){
      if(application.data.agreements.physician[i].name == doc.name){
        application.data.agreements.physician[i].received = true
      }
    }
    await applications.update(application.data)
    props.refresh()
  }

  useEffect(() => {
    async function init(){
      console.log(props.application)
      for(var i in props.application.agreements.physician){
        props.application.agreements.physician[i].marked = true
        props.application.agreements.physician[i].selected = true
      }
      findFaxes(props.application)
      setApp(props.application)
      setPhysician(props.application.myInfo.physician || {})
      change(!changed)
    }

    init()
  }, [props.application]);

  if(!app) return null
  return (
    <Row direction="column" padding={"32px 40px"}>
      <Row height={"56px"} justify="space-between" alignItems="center" padding={"0 5px 16px 5px"}>
        <SelectPacket {...props} refresh={props.refresh} application={app}/>

      </Row>

      <Row justify="space-between">
        <Row padding={"0 16px 0 0"}>
          <Box>
            <BoxRow padding={"16px 24px"} justify="space-between" alignItems="center">
              <Text kind="p" weight={800} size={"14px"} uppercase>Physician Information</Text>
            </BoxRow>

            <Row direction="column"  padding={"24px"}>
              <Row margin={"0 0 16px"}>
                <Row direction="column" padding={"0 16px 0 0"}>
                  <Input name="physician_first_name" initialvalue={physician.physician_first_name} change={updateField}/>
                  <Label>First Name</Label>
                </Row>

                <Row direction="column">
                  <Input name="physician_last_name" initialvalue={physician.physician_last_name} change={updateField}/>
                  <Label>Last Name</Label>
                </Row>
              </Row>

              <Row margin={"0 0 16px"} direction="column">
                <Input name="physician_email_address" initialvalue={physician.physician_email_address} change={updateField}/>
                <Label>Email Address</Label>
              </Row>

              <Row>
                <Row direction="column" padding={"0 16px 0 0"}>
                  <Input mask={"(999) 999-9999"} name="physician_phone_number" initialvalue={physician.physician_phone_number} change={updateField}/>
                  <Label>Phone Number</Label>
                </Row>

                <Row direction="column">
                  <Input mask={"(999) 999-9999"} name="physician_fax_number" initialvalue={physician.physician_fax_number} change={updateField}/>
                  <Label>Fax Number</Label>
                </Row>
              </Row>
            </Row>

            <Row padding={"24px"} justify={"flex-end"}>
              <Button onClick={savePhysicianInformation}>{physician.physician_first_name ? 'Update' : 'Save'}</Button>
            </Row>
          </Box>
        </Row>

        <Row padding={"0 0 0 16px"}>
          <Box>
            <BoxRow padding={"16px 24px"} justify="space-between" alignItems="center">
              <Text kind="p" weight={800} size={"14px"} uppercase>Action Log</Text>

              <Cells>
                <Cell width={105}>
                  <Text kind="p" center weight={800} size={"14px"} uppercase>Date</Text>
                </Cell>

                <Cell width={135}>
                  <Text kind="p" center weight={800} size={"14px"} uppercase>Status</Text>
                </Cell>
              </Cells>
            </BoxRow>

            <Overflow>
              {faxes ? faxes.map((item, index) => (
                <BoxRow padding={"16px 24px"} justify="space-between" alignItems="center">
                  <Text kind="p" size={"15px"} weight={500}>Fax {item.to}</Text>

                  <Cells>
                    <Cell width={115}>
                      <Text kind="p" center size={"12px"} uppercase>{moment(item.dateCreated).format('MM/DD/YY h:mm a')}</Text>
                    </Cell>

                    <Cell width={135}>
                      {item.status == 'delivered' && <Pill green>{item.status}</Pill>}
                      {item.status !== 'delivered' && <Pill gray>{item.status}</Pill>}
                    </Cell>
                  </Cells>
                </BoxRow>
              )) : null }

              {emails ? emails.map((item, index) => (
                <BoxRow padding={"16px 24px"} justify="space-between" alignItems="center">
                  {item.envelope ? <Text kind="p" size={"15px"} weight={500}>Email Sent to {item.envelope.to}</Text> : <Text kind="p" size={"15px"} weight={600}>Email failed to send</Text>}

                  <Cells>
                    <Cell width={115}>
                      {item.dateCreated && <Text kind="p" center size={"12px"} uppercase>{moment(item.dateCreated).format('MM/DD/YY h:mm a')}</Text>}
                    </Cell>

                    <Cell width={135}>
                      {item.envelope ? <Pill green>Sent</Pill> : <Pill red>Failed</Pill>}
                    </Cell>
                  </Cells>
                </BoxRow>
              )) : null }
            </Overflow>

            {app.agreements.physician && app.agreements.physician.length ? (
              <BoxRow padding={"16px 24px"} justify="space-between" alignItems="center">
                <Text kind="p" size={"15px"} weight={600}>Send Documents</Text>

                <Cells>
                  <Cell width={135}>
                    <Button onClick={() => setShowModal(true)}>Send</Button>
                  </Cell>
                </Cells>
              </BoxRow>
            ) : (
              <BoxRow padding={"16px 24px"} justify="space-between" alignItems="center">
                <Text kind="p" size={"15px"} weight={600}>No Physician Documents to send</Text>
              </BoxRow>
            )}

          </Box>
        </Row>
      </Row>

      <Row padding={"32px 0 0"}>
        <Box>
          <BoxRow padding={"16px 24px"} justify="space-between" alignItems="center">
            <Text kind="p" weight={800} size={"14px"} uppercase>Document Name</Text>

            <Cells>
              <Cell width={150}>
                <Text kind="p" center weight={800} size={"14px"} uppercase>Attached File(s)</Text>
              </Cell>

              <Cell width={105}>
                <Text kind="p" center weight={800} size={"14px"} uppercase>Status</Text>
              </Cell>

              <Cell width={35}>
                <Menu options={[{label: 'Add Physician Form(s)', action: () => setShowAddDocuments(true) }, {label: 'Mark All Received', action: () => markeAllReceived(app.agreements.physician)}, {label: 'Download All', action: () => downloadAll(app.agreements.physician)}, {label: 'Print All', action: () => setShowPrintAllModal(true)}]} />
              </Cell>
            </Cells>
          </BoxRow>

          {app.agreements.physician && app.agreements.physician.length ? app.agreements.physician.map((agreement, index) => (
            <BoxRow padding={"16px 24px"} justify="space-between" alignItems="center" key={index}>
              <Row alignItems="center">
                <Mark checked={agreement.received || (agreement.files && agreement.files.length > 0)} />
                <Text kind="p" size={"15px"}>{agreement.name} {agreement.details.required && <Required />}</Text>
              </Row>

              <Cells>
                <Cell width={150}>
                  {agreement.files && <Text kind="p" size="12px">{agreement.files.length} file(s) attached</Text>}
                  {agreement.received && !(agreement.files || (agreement.files && !agreement.files.length)) ? <Text kind="p" size="12px">Received</Text> :  null}
                </Cell>

                <Cell width={105}>
                  <UploadPhysician application={app} agreement={agreement} complete={e => props.refresh(e)}>
                    <Button secondary={agreement.files && agreement.files.length > 0}>{agreement.files && agreement.files.length > 0 ? 'Change' : 'Attach'}</Button>
                  </UploadPhysician>
                </Cell>

                <Cell width={35}>
                  <Dots src="icon-dots.svg" options={[{label:'Mark Received', disabled: false, action: () => markReceived(agreement)}, {label: 'Download', disabled: !agreement.files, action: () => download(agreement.files, agreement.name)}, {label:'Clear Attached', disabled: !agreement.files, action: () => clearAttached(agreement)}, {label:'Remove', action: () => remove(agreement)}]} />
                </Cell>
              </Cells>
            </BoxRow>
          )) : (
            <BoxRow padding={"16px 24px"} justify="space-between" alignItems="center">
              <Text kind="p" size={"15px"} weight={600}>No Physician Documents to send</Text>
            </BoxRow>
          )}
        </Box>
      </Row>

      <Modal title={"Send Physician Documents"} show={showModal} close={() => setShowModal(false)}>
        <Row padding="24px" direction="column">
          <Text kind="p" color="#4D5864" margin={"0 0 16px"}>How would you like to send these documents?</Text>

          <Row padding={"0 0 16px"} direction="column">
            <Select listen={physician} change={e => setContactMethod(e.value)} initialvalue={{label:physician.physician_contact_method}} name="physician_contact_method" options={[{label: 'Fax', value: 'Fax'}, {label: 'Email', value: 'Email'}]} />
            <Label color="#4D5864">Contact Method</Label>
          </Row>

          {contactMethod == 'Email' && (
            <Row direction="column">
              <Input name="physician_email_address" initialvalue={physician.physician_email_address} change={updateField}/>
              <Label color="#4D5864">Email Address</Label>
            </Row>
          )}

          {contactMethod == 'Fax' && (
            <Row direction="column">
              <Input mask={"+19999999999"} name="physician_fax_number" initialvalue={physician.physician_fax_number} change={updateField}/>
              <Label color="#4D5864">Fax Number</Label>
            </Row>
          )}

          {app.agreements.physician && app.agreements.physician.length ? app.agreements.physician.map((agreement, index) => {
            return (
              <Row padding={"16px 0"} alignItems="center" key={index}>
                <Checkbox detect={app} onClick={() => markAgreement(agreement)} selected={agreement.marked} />
                <Text kind="p" size={"15px"} color="#4D5864">{agreement.name}</Text>
              </Row>
            )
          }) : (
            <Row padding={"16px 24px"} justify="space-between" alignItems="center">
              <Text kind="p" size={"15px"} weight={600}>No Physician Documents to send</Text>
            </Row>
          )}

          <Row padding="16px 0 0" justify="flex-end">
            {!contactMethod && <Button disabled>Send</Button>}
            {contactMethod && <Button onClick={sendPhysicianDocuments}>Send</Button>}
          </Row>
        </Row>
      </Modal>

      <Modal title={"Print All"} show={showPrintAllModal} close={() => setShowPrintAllModal(false)}>
        <Row padding="24px" direction="column">
            <Text weight="700" padding="0 0 16px">Select Documents</Text>

            {app.agreements.physician.length && app.agreements.physician.map((agreement, index) => {
              return (
                <Row key={index} alignItems="center" padding="8px 0">
                  <Checkbox onClick={() => toggleAgreement(agreement)} selected={agreement.selected}/>
                  <Text>{agreement.name}</Text>
                </Row>
              )
            })}

            <Row justify="flex-end">
              <Button onClick={printAll}>Print</Button>
            </Row>
        </Row>
      </Modal>

      <AddAgreements physician application={app} show={showAddDocuments} close={() => setShowAddDocuments(false)} complete={() => props.refresh()} />

      <MessageBar message={message} listen={changed} />

      <PrintAll application={printAgreements} auditTrail={false}/>
    </Row>
  )
}
