import React, { useState, useEffect } from "react"
import styled from "styled-components"
import application from '../util/application'
import communities from '../util/community'
import user from '../util/user'
import colors from '../util/colors'
import agreements from '../util/agreements'
import packetsHelper from '../util/packets'
import companies from '../util/company'
import { Header, Sidebar, Row, Text, Box, Wrapper, Link, Button, Input, BoxRow, Dashboard, Cells, Cell, Dots, HelpButton, Label, Select, Checkbox, Radio, Close, Loading, Required } from '../components'
import queryString from 'query-string'

const Dash = styled(Dashboard)`
  padding: 48px 84px;
`

const Steps = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px 0 48px;
`

const Step = styled.div`
  width: 30px;
  height: 30px;
  background: ${props => props.active ? colors.blue : '#C4CAD0'};
  border-radius: 50%;
  color: white;
  font-weight: 900;
  font-size: 12px;
  display: flex;
  line-height: 1em;
  align-items: center;
  justify-content: center;
  position: relative;
  @media (prefers-color-scheme: dark) {
    background: ${props => props.active ? colors.blue : '#7f8992'};
  }
`

const Title = styled.div`
  width: 100%;
  left: -40px;
  position: absolute;
  text-align: center;
  bottom: -20px;
  color: #4D5864;
  white-space: nowrap;
  @media (prefers-color-scheme: dark) {
    color: white;
  }
`

const Line = styled.div`
  width: 160px;
  height: 2px;
  background: #C4CAD0;
  @media (prefers-color-scheme: dark) {
    background: #7f8992;
  }
`

const WrapLeft = styled.div`
  width: 100%;
  margin-right: 16px;
`

const WrapRight = styled.div`
  width: 100%;
  margin-left: 16px;
`

const WrapCenter = styled.div`
  width: 100%;
`

const Actions = styled.div`
  & button {
    margin-left: 16px;
  }
`

const ListItem = styled(Row)`
  border-bottom: 1px solid #C4CAD0;
  @media (prefers-color-scheme: dark) {
    border-bottom: 1px solid #66686b;
  }
`

const Column = styled(Row)`
  border-right: 1px solid #C4CAD0;
  @media (prefers-color-scheme: dark) {
    border-right: 1px solid #66686b;
  }
`

const Wrap = styled.div`
  max-width: 800px;
  margin: 0 auto;
`

const AddResident = (props) => {
  const [loaded, setLoaded] = useState()
  const [step, setStep] = useState(1)
  const [location, setLocation] = useState()
  const [templates, setTemplates] = useState([])
  const [selectedTemplates, setSelectedTemplates] = useState([])
  const [packetsSelectList, setPacketsSelectList] = useState([])
  const [communityAdminSelectList, setCommunityAdminSelectList] = useState([])
  const [packets, setPackets] = useState([])
  const [changed, change] = useState() //used to decect change for checkboxes
  const [resident, setResident] = useState({})
  const [error, setError] = useState()
  const [availableStep, setAvailableStep] = useState(1)
  const [community, setCommunity] = useState()
  const [uploadsList, setUploadsList] = useState([])
  const [physicianList, setPhysicianList] = useState([])
  const [readAndReview, setReadAndReview] = useState()
  const [currentUser, setCurrentUser] = useState()

  function updateField(e){
    resident[e.target.name] = e.target.value
    
    if(e.target.name === 'email_address'){
      resident[e.target.name] = e.target.value.trim()
    }

    resident[e.target.name] = e.target.value
    setResident(resident)
    validate()
  }

  function updateSelect(e, field){
    resident[field] = e.value
    if(field == 'assignee') {
      setCurrentUser(e)
    }
    setResident(resident)
    validate()
  }

  function validate(){
    let validStep = 1
    if(resident.first_name && resident.last_name) validStep = 3
    setAvailableStep(validStep)
  }

  function toggleTemplate(template){
    template.selected = !template.selected
    for(var i in templates){
      if(templates[i].id == template.id) templates[i] = template
    }
    setTemplates(templates)
    change(!changed)
  }

  function togglePhysicianDocument(template){
    template.selected = !template.selected
    for(var i in physicianList){
      if(physicianList[i].id == template.id) physicianList[i] = template
    }
    setPhysicianList(physicianList)
    change(!changed)
  }

  function checkSelected(id){
    return selectedTemplates.includes(id)
  }

  function selectPacket(input){
    console.log(packets)
    if(packets.length){
      console.log(input)
      change(!changed)
      setSelectedTemplates(packets.filter((instance => instance.name == input.value))[0].templates)
      for(var i in templates){
        const number = selectedTemplates.filter(template => template == templates[i].id)
        if(number.length > 0) templates[i].selected = true
      }
      setTemplates(templates)
      resident.packet_name = input.value
      setResident(resident)
    }
  }

  function getDepartments(){
    var array = []
    if(community.details.departments){
      for(var i in community.details.departments){
        const obj = {
          label: community.details.departments[i].name,
          value: community.details.departments[i].name
        }

        if(currentUser.details && currentUser.details.departments){
          for(var j in currentUser.details.departments){
            if(currentUser.details.departments[j] == obj.label) array.push(obj)
          }
        }
      }
    }

    if(array.length > 0) resident.department = array[0].value
    return array
  }

  function getAdmins(users){
    const array = []
    for(var i in users){
      const obj = {
        label: users[i].name,
        value: users[i].id,
        details: users[i].details
      }
      array.push(obj)
    }
    if(array.length > 0) resident.assignee = array[0].value
    return array
  }

  function setUploadDocsList(docs, companyData){
    if(docs.settings && docs.settings.available_company_documents){
      const array = []
      for(var i in docs.settings.available_company_documents){
        let obj
        let match = false
        const doc = docs.settings.available_company_documents[i]
        for(var j in companyData.forms.required_documents){
          if(companyData.forms.required_documents[j].title && companyData.forms.required_documents[j].title == doc) {
            match = companyData.forms.required_documents[j]
          }
        }
        if(match){
          obj = match
        } else {
          obj = {
            title: doc,
            files: [],
            object_name: null,
            selected: false,
            required: false
          }
        }
        array.push(obj)
      }
      setUploadsList(array)
    }
  }

  function toggleUpload(uploadDoc){
    uploadDoc.selected = !uploadDoc.selected
    for(var i in uploadsList){
      if(uploadsList[i].title == uploadDoc.title) uploadsList[i] = uploadDoc
    }
    setUploadsList(uploadsList)
    change(!changed)
  }

  async function finish(){
    try{
      const new_user = await user.inviteResident({email: resident.email_address.trim(), name: resident.first_name + ' ' + resident.last_name, access: 'resident', details: {}, progress: 'Invite Sent', facilityId: community.id, facilityName: community.details.name, password: 'Oiad8f3D2', department: resident.department})
      resident.id = new_user.data.id
      assignApplication()
    } catch (error){
      const check_user = await user.getByEmail(resident.email_address)
      if(check_user.data && check_user.data.id){
        resident.id = check_user.data.id
        assignApplication()
      }
    }

    async function assignApplication(){
      const new_application = application.generateApplication(resident, community, templates, uploadsList, physicianList, readAndReview)
      const created_application = await application.create(new_application)
      props.history.push('/application/' + created_application.data[0].id)
    }
  }

  function toggleReadAndReview(doc){
    doc.selected = !doc.selected
    for(var i in readAndReview){
      if(readAndReview[i].title == doc.title) readAndReview[i] = doc
    }
    setReadAndReview(readAndReview)
    change(!changed)
  }

  useEffect(() => {
    async function setup(){
      try{
        console.log(props.location.search)
        const parsed = queryString.parse(props.location.search, {decode: false});
        console.log(parsed)
        //Get location and templates
        const me = await user.get()
        const location = await communities.get(me.data.facilityId)
        const agreementsList = await agreements.getAgreements(location.data.companyId, me.data.facilityId)
        const communityAdmins = await user.listCommunityAdmins(me.data.facilityId, 0)
        const company = await companies.get(location.data.companyId)
        if(parsed.email) {
          resident.email_address = parsed.email
          setResident(resident)
        }

        setCurrentUser(me.data)
        setReadAndReview(company.data.forms.read_and_review)
        setCommunity(location.data)
        setCommunityAdminSelectList(getAdmins(communityAdmins.data))
        setTemplates(agreementsList.filter(agreement => !agreement.details.physician_doc))
        setPhysicianList(agreementsList.filter(agreement => agreement.details.physician_doc))
        setPackets(location.data.packets.new_list)
        setPacketsSelectList(packetsHelper.getList(location.data.packets.new_list))
        setUploadDocsList(location.data, company.data)
        //Set Selected Templates from first packet
        if(location.data.packets && location.data.packets.new_list) setSelectedTemplates(location.data.packets.new_list[0].templates)
        setLoaded(true)
      } catch(error) {
        console.log(error)
        props.history.push('/login')
      }
    }

    setup()
  }, [])

  return (
    <main>
      <Row>

        <Dash>
          <Row justify="flex-end">
            <Link to="/dashboard">
              <Close />
            </Link>
          </Row>
          {loaded ? (
            <Wrap>
              <Text kind="p" weight={900} center uppercase>Add Resident</Text>
              <Steps>
                <Step active={step == 1}> 1<Title>Resident Information</Title> </Step>
                <Line />
                <Step active={step == 2}>2<Title>Department Details</Title></Step>
                <Line />
                <Step active={step == 3}>3<Title>Packet Selection</Title></Step>
              </Steps>

              {step == 1 && (
                <Box>
                  <BoxRow>
                    <Row padding={"24px"} justify="space-between" alignItems="center">
                      <Text kind="p" weight={900} center uppercase>Resident Information</Text>

                      {/*<Button outline>Import</Button>*/}
                    </Row>
                  </BoxRow>
                  <Row direction="column"  padding={"24px"}>
                    <Row margin={"0 0 16px"}>
                      <WrapLeft>
                        <Input name="first_name" initialvalue={resident.first_name} change={updateField} />
                        <Label>First Name<Required /></Label>
                      </WrapLeft>

                      <Row direction="column">
                        <Input name="middle_name" initialvalue={resident.middle_name} change={updateField} />
                        <Label>Middle Name</Label>
                      </Row>

                      <WrapRight>
                        <Input name="last_name" initialvalue={resident.last_name} change={updateField} />
                        <Label>Last Name<Required /></Label>
                      </WrapRight>
                    </Row>

                    <Row margin={"0 0 16px"}>
                      <WrapLeft>
                        <Input mask={"(999) 999-9999"} name="phone_number" initialvalue={resident.phone_number} change={updateField} />
                        <Label>Phone Number<Required /></Label>
                      </WrapLeft>

                      <Row direction="column">
                        <Input name="email_address" initialvalue={resident.email_address.trim()} change={updateField}/>
                        <Label>Email Address</Label>
                      </Row>
                    </Row>

                    <Row>
                      <WrapLeft>
                        <Input name="street_address" initialvalue={resident.street_address} change={updateField}/>
                        <Label>Street Address</Label>
                      </WrapLeft>

                      <WrapCenter>
                        <Input name="city" initialvalue={resident.city} change={updateField}/>
                        <Label>City</Label>
                      </WrapCenter>

                      <Row direction="column" width="15%" margin={"0 0 0 16px"}>
                        <Input name="state" initialvalue={resident.state} change={updateField}/>
                        <Label>State</Label>
                      </Row>

                      <Row direction="column" width="15%" margin={"0 0 0 16px"}>
                        <Input name="zip" initialvalue={resident.zip} change={updateField}/>
                        <Label>Zip</Label>
                      </Row>
                    </Row>
                  </Row>
                  {error && <Text kind="p">{error}</Text>}
                  <Row padding={"0 24px 24px"} justify={"space-between"}>
                    <div />
                    {availableStep > 1 && <Button primary onClick={() => setStep(2)}>Next</Button>}
                    {availableStep == 1 && <Button primary disabled>Next</Button>}
                  </Row>
                </Box>
              )}

              {step == 2 && (
                <Box>
                  <BoxRow>
                    <Row padding={"24px"}>
                      <Text kind="p" weight={900} center uppercase>Department Details</Text>
                    </Row>
                  </BoxRow>
                  <Row direction="column"  padding={"24px"}>
                    <Row margin={"0 0 16px"}>
                      {getDepartments().length > 0 ? (
                        <WrapLeft>
                          <Select listen={currentUser} change={e => updateSelect(e, 'department')} options={getDepartments()}/>
                          <Label><Required />Department / Care Type</Label>
                        </WrapLeft>
                      ) : (
                        <WrapLeft>
                          <Label>No Departments Available</Label>
                        </WrapLeft>
                      )}


                      <WrapRight>
                        <Input name="unit_number" initialvalue={resident.unit_number} change={updateField}/>
                        <Label>Unit Number</Label>
                      </WrapRight>
                    </Row>

                    <Row margin={"0 0 16px"}>
                      <WrapLeft>
                        <Select change={e => updateSelect(e, 'assignee')} options={communityAdminSelectList}/>
                        <Label><Required />Community Assignee</Label>
                      </WrapLeft>

                      <WrapRight>
                        <Input mask={"99/99/9999"} name="move_in_date" initialvalue={resident.move_in_date} change={updateField}/>
                        <Label>Move-in Date</Label>
                      </WrapRight>
                    </Row>
                  </Row>
                  <Row padding={"0 24px 24px"} justify={"space-between"}>
                    <div />
                    <Actions>
                      <Button outline onClick={() => setStep(1)}>Back</Button>
                      {availableStep > 2 && <Button primary onClick={() => setStep(3)}>Next</Button>}
                      {availableStep <= 2 && <Button primary disabled>Next</Button>}
                    </Actions>
                  </Row>
                </Box>
              )}

              {step == 3 && (
                <Box>
                  <BoxRow>
                    <Row justify="space-between" padding={"24px"}>
                      <Row direction="column" width={"75%"} padding={"0 16px 0 0"}>
                        <Text kind="p" weight={900} uppercase>Packet Selection</Text>

                        <Row padding={"24px 0 0"}>
                          <Select initialvalue={{label:'Select Packet'}} change={e => selectPacket(e)} options={packetsSelectList}/>
                        </Row>
                      </Row>

                      <Row direction="column" width={"25%"} padding={"0 0 0 16px"}>
                        <Row justify="center">
                          <Text kind="p" weight={900} center uppercase>Due Date</Text>
                        </Row>
                        <Row justify="space-between" padding={"24px 0 0"} alignItems="center">
                          <Input mask={'99/99/9999'} name="due_date" initialvalue={resident.due_date} change={updateField} />
                        </Row>
                      </Row>
                    </Row>
                  </BoxRow>

                  <Row justify="space-between">
                    <Column padding={"0 24px"} direction="column">
                      <ListItem padding={"24px 16px"}>
                        <Text kind="p" weight={900} uppercase>Resident Agreements</Text>
                      </ListItem>
                      {templates && templates.map((template, index) => (
                        <ListItem alignItems="center" padding={"24px 16px"} key={index}>
                          <Checkbox detect={changed} selected={template.selected} onClick={() => toggleTemplate(template)}/>
                          <Text kind="p">{template.details.name} {template.details.required && <Required />}</Text>
                        </ListItem>
                      ))}
                    </Column>

                    <Row direction="column" style={{borderLeft: '1px solid #ddd', marginLeft: '-1px'}}>
                      {physicianList.length > 0 && (
                          <Row padding={"0 24px"} margin={"0 0 64px"} direction="column">
                            <ListItem padding={"24px 16px"}>
                              <Text kind="p" weight={900} uppercase>Physician Documents</Text>
                            </ListItem>

                            {physicianList.map((template, index) => (
                              <ListItem alignItems="center" padding={"24px 16px"} key={index}>
                                <Checkbox detect={changed} selected={template.selected} onClick={() => toggleTemplate(template)}/>
                                <Text kind="p">{template.details.name} {template.details.required && <Required />}</Text>
                              </ListItem>
                            ))}
                          </Row>
                      )}

                      {uploadsList.length > 0 && (
                        <Row padding={"0 24px"} margin={"0 0 64px"} direction="column">
                          <ListItem padding={"24px 16px"}>
                            <Text kind="p" weight={900} uppercase>Resident Attachments</Text>
                          </ListItem>

                          {uploadsList.map((upload, index) => (
                            <ListItem alignItems="center" padding={"24px 16px"} key={index}>
                              <Checkbox detect={changed} selected={upload.selected} onClick={() => toggleUpload(upload)}/>
                              <Text kind="p">{upload.title} {upload.required} {upload.required ? <Required /> : null}</Text>
                            </ListItem>
                          ))}
                        </Row>
                      )}

                      {readAndReview.length > 0 && (
                        <Row padding={"0 24px"} direction="column">
                          <ListItem padding={"24px 16px"}>
                            <Text kind="p" weight={900} uppercase>Read and Review</Text>
                          </ListItem>

                          {readAndReview.map((doc, index) => (
                            <ListItem alignItems="center" padding={"24px 16px"} key={index}>
                              <Checkbox detect={changed} selected={doc.selected} onClick={() => toggleReadAndReview(doc)}/>
                              <Text kind="p">{doc.title}</Text>
                            </ListItem>
                          ))}
                        </Row>
                      )}
                    </Row>
                  </Row>

                  <Row padding={"24px"} justify={"space-between"}>
                    <div />
                    <Actions>
                      <Button outline onClick={() => setStep(2)}>Back</Button>
                      <Button primary onClick={finish}>Finish</Button>
                    </Actions>
                  </Row>
                </Box>
              )}
              <HelpButton />
            </Wrap>
          ) : <Loading />}
        </Dash>
      </Row>
    </main>
  )
}

export default AddResident
