import React from 'react'
import styled from "styled-components"

const Check = styled.img`
  margin-right: 16px;
`

export default props => {
  if(props.checked) return <Check {...props} src="/icon-checked.svg"/>
  if(!props.checked) return <Check {...props} src="/icon-circle.svg"/>
}
