import axios from "axios"
import urls from './urls'
import templates from './templates'
import agreements from './agreements'
import moment from 'moment'

function list(id, offset){
  return axios.get(urls.api + 'listFacilityApplications/' + id + '/' + (offset * 20) + '/20', {'headers':{
    'X-Auth-Token': localStorage.token
  }})
}

function listAll(id){
  return axios.get(urls.api + 'applications/' + id, {'headers':{
    'X-Auth-Token': localStorage.token
  }})
}

function get(id){
  return axios.get(urls.api + 'application/' + id, {'headers':{
    'X-Auth-Token': localStorage.token
  }})
}

function create(application){
  return axios.post(urls.api + 'application/', application, {'headers':{
    'X-Auth-Token': localStorage.token,
  }})
}

function remove(id){
  return axios.delete(urls.api + 'application/' + id, {'headers':{
    'X-Auth-Token': localStorage.token,
  }})
}

function listByDepartment(id, department, offset){
  return axios.get(urls.api + 'listFacilityDepartmentApplications/' + id + '/' + department + '/' + (offset * 20) + '/20', {'headers':{
    'X-Auth-Token': localStorage.token
  }})
}

function countByDepartment(id, department){
  return axios.get(urls.api + 'countFacilityDepartmentApplications/' + id + '/' + department, {'headers':{
    'X-Auth-Token': localStorage.token
  }})
}

function countApplications(id){
  return axios.get(urls.api + 'countFacilityApplications/' + id, {'headers':{
    'X-Auth-Token': localStorage.token
  }})
}

function listResidentApplications(id, offset){
  return axios.get(urls.api + 'listFacilityApplications/' + id + '/' + (offset * 20) + '/20', {'headers':{
    'X-Auth-Token': localStorage.token
  }})
}

function update(application){
  return axios.patch(urls.api + 'application/', application, {'headers':{
    'X-Auth-Token': localStorage.token
  }})
}

function communityTurn(signatures){
  let turn = false
  if(signatures.Community && signatures.Resident){
    if(signatures.Community.order == 0 && !signatures.Community.signed) turn = true
    if(signatures.Resident.signed && !signatures.Community.signed) turn = true
  }
  return turn
}

function managerTurn(signatures){
  let turn = false
  if(signatures.Manager && signatures.Resident && !communityTurn(signatures) && !residentTurn(signatures)){
    if(signatures.Manager.order == 0 && !signatures.Manager.signed) turn = true
    if(signatures.Resident.signed && !signatures.Manager.signed) turn = true
  }
  return turn
}

function residentTurn(signatures){
  let turn = false
  //Resident only
  if(!signatures.Community && !signatures.Manager && signatures.Resident){
    if(!signatures.Resident.signed) turn = true
  }
  //Community and Resident
  if(signatures.Community && signatures.Resident && !signatures.Manager && !signatures.Resident.signed){
    if(signatures.Community.signed) turn = true
    if(signatures.Resident.order == 0) turn = true
  }
  //Community Mangager and Resident
  if(signatures.Community && signatures.Resident && signatures.Manager && !signatures.Resident.signed  && !signatures.Manager.signed){
    if(signatures.Community.signed) turn = true
    if(signatures.Resident.order == 0) turn = true
  }
  //Resident and Manager
  if(signatures.Manager && signatures.Resident && !signatures.Community && !signatures.Resident.signed){
    if(signatures.Resident.order == 0) turn = true
  }
  //Resident only
  if(!signatures.Manager && signatures.Resident && !signatures.Community && !signatures.Resident.signed){
    turn = true
  }
  return turn
}

function allSignaturesComplete(signatures){
  let complete = true
  for(var i in signatures){
    if(!signatures[i].signed) complete = false
  }
  return complete
}

function allAgreementsPrepared(data){
  let complete = true
  for(var i in data){
    for(var j in data[i].signatures){
      if(data[i].signatures.Community && data[i].signatures.Community.order == 0 && !data[i].signatures.Community.signed) complete = false
    }
  }
  return complete
}

async function prepareAgreement(agreement, applicationId, props){
  if(agreement.agreement_id) {
    props.history.push('/sign/' + agreement.agreement_id + '/' + applicationId + '/Resident')
  }
  else {
    const template = await templates.get(agreement.template_id)
    const newAgreement = await agreements.create(template.data)
    await agreements.assignNewAgreementId(newAgreement.data[0].id, agreement.template_id, applicationId)
    props.history.push('/sign/' + newAgreement.data[0].id + '/' + applicationId + '/Resident')
  }
}

async function removeAgreement(applicationId, agreement){
  const application = await get(applicationId)

  for(var i in application.data.agreements.resident) {
    if(application.data.agreements.resident[i].template_id == agreement.template_id)
      application.data.agreements.resident.splice(i, 1)
  }

  await update(application.data)
}

function allRequiredDocsComplete(application){
  let complete = true
  for(var i in application.agreements.resident){
    if(application.agreements.resident[i].details.required && !allSignaturesComplete(application.agreements.resident[i].signatures)){
      complete = false
    }
  }
  return complete
}

function allAgreementsComplete(application){
  let complete = true
  for(var i in application.agreements.resident){
    if(!allSignaturesComplete(application.agreements.resident[i].signatures)){
      complete = false
    }
  }
  return complete
}

function allResidentDocsComplete(application){
  let complete = true
  for(var i in application.agreements.resident){
    if(residentTurn(application.agreements.resident[i].signatures)) complete = false
  }
  return complete
}

function physicianDocsSent(trail){
  let complete = true
  if(!trail) complete = false
  if(trail && (trail.emails && !trail.emails.length) && (trail.faxes && !trail.faxes.length)) complete = false
  return complete
}

function allPhysicianDocsComplete(application){
  let complete = true
  for(var i in application.agreements.physician){
    if(application.agreements.physician[i].details.required && !application.agreements.physician[i].received && !application.agreements.physician[i].files){
      complete = false
    }
  }
  return complete
}

function prepAgreements(agreements){
  const array = []
  for(var i in agreements){
    const obj = {
      name: agreements[i].details.name,
      template_id: agreements[i].id,
      pages: agreements[i].pages.pages,
      details: agreements[i].details,
      agreement_id: null,
      status: null,
      signatures: {}
    };
    for (var j in agreements[i].signatureOrder.signers) {
      obj.signatures[agreements[i].signatureOrder.signers[j]] = {
        signed: false,
        order: parseInt(j)
      };
    }
    array.push(obj)
  }
  return array
}

function generateApplication(resident, location, agreements, documents, physicianList, readAndReview){
  const new_application = {
    progress: {
      current: "New!"
    },
    department: resident.department,
    myInfo: {
      audit_trail: {
        created: new Date()
      },
      application_info: {
        due_date: resident.due_date,
        move_in_date: resident.move_in_date,
        facility_name: location.details.provider.companyName || "",
        facility_email: location.details.provider.email || "",
        facility_phone: location.details.provider.phone || "",
        company_name: location.details.provider.llc || "",
        manager_title: location.details.provider.job || "",
        manager_name: resident.assignee || "",
        manager_phone: location.details.provider.phone || "",
        packet_name: resident.packet_name
      },
      contact_info: {
        preferred_name: null,
        first_name: resident.first_name,
        middle_name: resident.middle_name,
        last_name: resident.last_name,
        phone_number: resident.phone_number,
        email: resident.email_address,
        name:
          resident.first_name + " " +
          ((resident.middle_name && resident.middle_name !== 'undefined') ? resident.middle_name + ' ' + resident.last_name : resident.last_name)
      },
      responsible_party: {
        rp_name: null,
        rp_date_of_birth: null,
        rp_ssn: null,
        rp_relationship: null,
        rp_phone: null,
        rp_work_phone: null,
        rp_email_address: null,
        rp_street_address: null,
        rp_street_address_2: null,
        rp_city: null,
        rp_state: null,
        rp_zip: null,
        rp_country: null
      },
      personal_info: {
        date_of_birth: null,
        ssn: null,
        medicare_number: null,
        gender: null,
        //marital_status: null,
        spouses_name: null,
        spouse_moving_in: null,
        religion: null,
        military_veteran: null,
        military_branch: null,
        allergies: null
      },
      care_providers: {
        hospice: null,
        funeral_home: null,
        pharmacy: null,
        physician_name: null,
        physician_phone: null
      },
      lifestyle: {
        hobbies: null,
        favorite_color: null
      }
    },
    agreements: {
      resident: prepAgreements(agreements.filter(agreement => agreement.selected)),
      physician: prepAgreements(physicianList.filter(agreement => agreement.selected))
    },
    myDocuments: {
      required_documents: documents.filter(document => document.selected),
      read_and_review: readAndReview.filter(document => document.selected)
    },
    physicianEmail: resident.first_name + " " + ((resident.middle_name && resident.middle_name !== 'undefined') ? resident.middle_name + ' ' + resident.last_name : resident.last_name),
    facilityId: location.id,
    userId: resident.id
  };
  return new_application
}

function generateHtml(agreement){
  console.log(agreement)

  var html = `
    <!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.0 Transitional//EN" "http://www.w3.org/TR/REC-html40/loose.dtd">
    <html>
      <head>
        <META http-equiv="Content-Type" content="text/html; charset=iso-8859-1">
        <META http-equiv="Content-Style-Type" content="text/css">
        <link href="https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800&display=swap" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css?family=La+Belle+Aurore&display=swap" rel="stylesheet" />
      </head>
      <body style="margin:0; padding:0;">
        <style>
          html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; font-size: 100%; font: inherit; vertical-align: baseline; text-rendering: geometricPrecision; }
          /* HTML5 display-role reset for older browsers */
          article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section { display: block; }
          body { line-height: 1; }
          ol, ul { list-style: none; }
          blockquote, q { quotes: none; }
          table { border-collapse: collapse; border-spacing: 0; }
          a, a:hover, a:focus, button:focus, button:active { color: inherit; text-decoration: none; outline: none; }
          * { -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; outline: none; font-family: Century Gothic; }
        </style>
  `

  for(var i in agreement.pages.pages){
    var styles = 'overflow: hidden; position: relative;'
    var ratio = 0.75
    if(agreement.details.size){
      styles += 'width: '+ (agreement.details.size.width*72) +'px; height: '+ (agreement.details.size.height*72) +'px;'
      ratio = 1
    } else {
      styles += 'width: 612px; height: 792px;'
    }

    var page = '<div style="'+styles+' background: url('+agreement.pages.pages[i].background+'); background-size: cover;"><div>'

    for(var j in agreement.pages.pages[i].fields){
      var field = agreement.pages.pages[i].fields[j]
      if(field.value && field.value !== 'null'){
        if(field.type == 'textbox'){
          page += '<p style="position: absolute; \
          top: '+(parseInt(field.position.y) * ratio)+'px; \
          left: '+(parseInt(field.position.x) * ratio)+'px; \
          width: '+(parseInt(field.position.w) * ratio)+'px; \
          height: '+(parseInt(field.position.h) * ratio)+'px; \
          text-align: '+field.textalignment+'; \
          font-size: 11px; \
          padding-left: 5px; \
          background: white; \
          font-family: Open Sans,sans-serif;">\
          '+convertToString(field.value).replace(/_/g, ' ')+'\
          </p>'
        }
        else if(field.type == 'checkbox'){
          if(field.value == "true" || field.value == true){
            page += ' <div style="width: 15px; height: 15px; background: white; border: 2px solid #aaa; border-radius: 2px; top: '+(parseInt(field.position.y) * ratio)+'px; left: '+(parseInt(field.position.x) * ratio)+'px; position: absolute;"> \
            <img \
            style="position: relative; \
            top: -2px; \
            width: 15px; \
            height: auto;" \
            src="https://s3-us-west-2.amazonaws.com/docuvault-uploads/check-mark-transparent-pink-27.png"/> \
            </div>'
          } else {
            page += ' <div \
            style="width: 15px; \
            height: 15px; \
            background: white; \
            border: 2px solid #aaa; \
            border-radius: 2px; \
            top: '+(parseInt(field.position.y) * ratio)+'px; \
            left: '+(parseInt(field.position.x) * ratio)+'px; \
            position: absolute;"> \
            </div>'
          }
        }
        else if(field.type == 'signature'){
          page += '<p style="position: absolute; \
          top: '+(parseInt(field.position.y) * ratio)+'px; \
          left: '+(parseInt(field.position.x) * ratio)+'px; \
          width: '+(parseInt(field.position.w) * ratio)+'px; \
          height: '+(parseInt(field.position.h) * ratio)+'px; \
          text-align: '+field.textalignment+'; \
          font-size: '+(((parseInt(field.position.h) -2) * ratio))+'px; \
          white-space: nowrap; \
          font-size: 24px; \
          background: white; \
          font-family: \'La Belle Aurore\', cursive;">\
          '+convertToString(field.value).replace(/_/g, ' ')+'\
          </p>'
        }
        else if(field.type == 'initials'){
          page += '<p style="position: absolute; \
          top: '+(parseInt(field.position.y) * ratio)+'px; \
          left: '+(parseInt(field.position.x) * ratio)+'px; \
          width: '+(parseInt(field.position.w) * ratio)+'px; \
          height: '+(parseInt(field.position.h) * ratio)+'px; \
          text-align: '+field.textalignment+'; \
          font-size: 16px; \
          white-space: nowrap; \
          background: white; \
          font-family: \'La Belle Aurore\', cursive;">\
          '+convertToString(field.value).replace(/_/g, ' ')+'\
          </p>'
        }
        else if(field.type == 'date'){
          page += '<p style="position: absolute; \
          top: '+(parseInt(field.position.y) * ratio)+'px; \
          left: '+(parseInt(field.position.x) * ratio)+'px; \
          width: '+(parseInt(field.position.w) * ratio)+'px; \
          height: '+(parseInt(field.position.h) * ratio)+'px; \
          text-align: '+field.textalignment+'; \
          font-size: 10px; \
          white-space: nowrap; \
          background: white; \
          font-family: Open Sans,sans-serif;">\
          '+convertToString(field.value).replace(/_/g, ' ')+'\
          </p>'
        }
      }
    }

    page = page + '</div></div>'
    html = html + page
  }

  if(agreement.auditTrail){
    var styles = 'overflow: hidden; position: relative;'
    if(agreement.details.size){
      styles += 'width: '+ (agreement.details.size.width*72) +'px; height: '+ (agreement.details.size.height*72) +'px;'
    } else {
      styles += 'width: 612px; height: 792px;'
    }

    var page = '<div class="audit-page" style="'+styles+'"><div style="padding: 30px;">'

    page+= `
      <style>
        .audit-page { font-weight: 400; font-family: 'Open Sans', sans-serif !important; color: #555555; }
        .inline { display: inline-block; vertical-align: top; }
        .space-between { text-align: justify; }
        .right { float: right; }
        .bold { font-weight: 700; }
        .audit-page h1 { font-size: 18px; padding-top: 8px; }
        .header { padding: 6px 0; margin:15px 0;  border-top: 1px solid #bbb; border-bottom: 1px solid #bbb; }
        .right-align { text-align: right; }
        .audit-page p { line-height: 1.5em; font-size: 9px; }
        .pad-left { padding-left: 20px; }
        .spaced { padding: 10px 0; }
        .center { text-align: center; position: absolute; bottom: 0px; left: 0px; width: 100%; padding: 10px; margin: 0; }
        .event-wrap { padding: 3px; background: #ddd; margin: 2px 0; border-radius: 2px; }
        .event { font-size: 9px !important; font-weight: 700; margin:0 !important; padding: 0 !important; line-height: 1em !important; }
        .value { display: inline-block !important; font-size: 8px !important; margin: 0 !important; padding: 0 !important; line-height: 1em !important; }
      </style>
    `

    page+= `
      <div class="space-between">
        <h1 class="bold inline"> DOCUMENT AUDIT LOG </h1>
        <img class="inline right" style="max-width: 150px; height: 33px;" src="https://docuvault-uploads.s3-us-west-2.amazonaws.com/Logo.png" />
      </div>

      <div class="header">
        <div class="right-align inline">
          <p class="bold"> TITLE </p>
          <p class="bold"> AGREEMENT ID </p>
          <p class="bold"> STATUS </p>
        </div>
        <div class="pad-left inline">
          <p>${agreement.details.name}</p>
          <p>${agreement.id}</p>
          <p>Completed</p>
        </div>
      </div>
    `

    if(agreement.auditTrail.history){
      agreement.auditTrail.history.forEach(event => {
        page += `
          <div class="event-wrap">
            <p class="event">${event.ipAddress} (${moment(event.timestamp).format('MM/DD/YYYY h:mm:ss a')}) - ${event.action} </p>
            ${event.value ? '<span class="value">Value: ' + event.value + '</span>' : ''}
            ${event.filler ? '<span class="value">Role: ' + event.filler + '</span>' : ''}
            ${event.fieldId ? '<span class="value">Field Id: ' + event.fieldId + '</span>' : ''}
            ${event.poa ? '<span class="value">Power of Attorney: ' + event.poa + '</span>' : ''}
          </div>
        `
      })
    }

    page += '<div class="center"> <p class="header">This document audit log is automatically provided in compliance with United States electronic signature  laws.</p> </div>'

    page = page + '</div></div>'
    html = html + page
  }

  html = html + '</body></html>'
  return html
}

function generatePhsyicianFormsHtml(agreements){
  var html = '<!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.0 Transitional//EN" "http://www.w3.org/TR/REC-html40/loose.dtd"><html><head><META http-equiv="Content-Type" content="text/html; charset=iso-8859-1"> <META http-equiv="Content-Style-Type" content="text/css"><link href="https://fonts.googleapis.com/css?family=Homemade+Apple" rel="stylesheet"><link href="https://fonts.googleapis.com/css?family=Open+Sans" rel="stylesheet"></head><body style="margin:0; padding:0;">'
  html += "<style>html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; font-size: 100%; font: inherit; vertical-align: baseline; text-rendering: geometricPrecision; } /* HTML5 display-role reset for older browsers */ article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section { display: block; } body { line-height: 1; } ol, ul { list-style: none; } blockquote, q { quotes: none; } table { border-collapse: collapse; border-spacing: 0; } a, a:hover, a:focus, button:focus, button:active { color: inherit; text-decoration: none; outline: none; } * { -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; outline: none; font-family: Century Gothic; } </style>"

  for(var p in agreements){
    const agreement = agreements[p]
    console.log(agreement)
    for(var i in agreement.pages){
      var styles = 'overflow: hidden; position: relative;'
      var ratio = 0.75
      if(agreement.details.size){
        styles += 'width: '+ (agreement.details.size.width*72) +'px; height: '+ (agreement.details.size.height*72) +'px;'
        ratio = 1
      } else {
        styles += 'width: 612px; height: 792px;'
      }
      var page = '<div style="'+styles+' background: url('+agreement.pages[i].background+'); background-size: cover;"><div>'
      page = page + '</div></div>'
      html = html + page
    }
  }


  html = html + '</body></html>'
  return html
}

function cleanValue(val) {
  if (Number.isInteger(val)) val = val + "";
  try {
    val = val.replace(/_/g, " ");
  } catch {

  }
  return val;
};

function convertToString(val) {
  if (Number.isInteger(val)) val = val + "";
  return val;
};

function allUploadsReceived(application){
  let complete = true
  for(var i in application.myDocuments.required_documents){
    if(!application.myDocuments.required_documents[i].received && (application.myDocuments.required_documents[i].files ? !application.myDocuments.required_documents[i].files.length > 0 : true) && !application.myDocuments.required_documents[i].object_name ){
      complete = false
    }
  }
  return complete
}

function getProgress(application){
  const object = {
    agreements: {
      progress: {
        agreements: {
          total: application.agreements.resident.filter(agreement => agreement.details ? !agreement.details.read_only : false).length,
          complete: application.agreements.resident.filter(agreement => agreement.details ? (!agreement.details.read_only && allSignaturesComplete(agreement.signatures)) : false).length,
          percent: application.agreements.resident.filter(agreement => agreement.details ? (!agreement.details.read_only && allSignaturesComplete(agreement.signatures)) : false).length > 0 ? ((application.agreements.resident.filter(agreement => agreement.details ? (!agreement.details.read_only && allSignaturesComplete(agreement.signatures)) : false).length / application.agreements.resident.filter(agreement => agreement.details ? !agreement.details.read_only : false).length) * 100 ).toFixed(0) : 0
        },
        attachments: {
          total: application.myDocuments.required_documents.length,
          complete: application.myDocuments.required_documents.filter(doc => (doc.files && doc.files.length) || doc.object_name || doc.received).length,
          percent: application.myDocuments.required_documents.filter(doc => (doc.files && doc.files.length) || doc.object_name || doc.received).length > 0 ? ((application.myDocuments.required_documents.filter(doc => (doc.files && doc.files.length) || doc.object_name || doc.received).length / application.myDocuments.required_documents.length) * 100 ).toFixed(0) : 0
        },

      },
      compliance: {
        agreements: {
          total: application.agreements.resident.filter(agreement => agreement.details ? !agreement.details.read_only && agreement.details.required : false).length,
          complete: application.agreements.resident.filter(agreement => agreement.details ? (!agreement.details.read_only && agreement.details.required && allSignaturesComplete(agreement.signatures)) : false).length,
          percent: application.agreements.resident.filter(agreement => agreement.details ? !agreement.details.read_only && agreement.details.required : false).length == 0
            ? 100
            : application.agreements.resident.filter(agreement => agreement.details ? (!agreement.details.read_only && agreement.details.required && allSignaturesComplete(agreement.signatures)) : false).length > 0
              ? ((application.agreements.resident.filter(agreement => agreement.details ? (!agreement.details.read_only && agreement.details.required && allSignaturesComplete(agreement.signatures)) : false).length / application.agreements.resident.filter(agreement => agreement.details ? !agreement.details.read_only && agreement.details.required : false).length) * 100).toFixed(0)
              : 0
        },
        attachments: {
          total: application.myDocuments.required_documents.filter(doc => doc.required).length,
          complete: application.myDocuments.required_documents.filter(doc => (doc.required && ((doc.files && doc.files.length) || doc.object_name || doc.received))).length,
          percent:  application.myDocuments.required_documents.filter(doc => doc.required).length == 0
            ? 100
            : application.myDocuments.required_documents.filter(doc => (doc.required && ((doc.files && doc.files.length) || doc.object_name || doc.received))).length > 0
              ? ((application.myDocuments.required_documents.filter(doc => doc.required).length / application.myDocuments.required_documents.filter(doc => (doc.required && ((doc.files && doc.files.length) || doc.object_name || doc.received))).length) * 100).toFixed(0)
              : 0
        }
      }
    }
  }

  if(application.agreements.physician){
    object.agreements.progress.physician = {
      total: application.agreements.physician.filter(agreement => agreement.details ? !agreement.details.read_only : false).length,
      complete: application.agreements.physician.filter(agreement => agreement.details ? (!agreement.details.read_only && (agreement.received || agreement.files)) : false).length,
      percent: application.agreements.physician.filter(agreement => agreement.details ? (!agreement.details.read_only && (agreement.received || agreement.files)) : false).length > 0 ?((application.agreements.physician.filter(agreement => agreement.details ? (!agreement.details.read_only && (agreement.received || agreement.files)) : false).length / application.agreements.physician.filter(agreement => agreement.details ? !agreement.details.read_only : false).length) * 100).toFixed(0) : 0
    }

    object.agreements.compliance.physician = {
      total: application.agreements.physician.filter(agreement => agreement.details ? !agreement.details.read_only && agreement.details.required : false).length,
      complete: application.agreements.physician.filter(agreement => agreement.details ? (!agreement.details.read_only && agreement.details.required && (agreement.received || agreement.files)) : false).length,
      percent: application.agreements.physician.filter(agreement => agreement.details ? !agreement.details.read_only && agreement.details.required : false).length == 0
        ? 100
        : application.agreements.physician.filter(agreement => agreement.details ? (!agreement.details.read_only && agreement.details.required && (agreement.received || agreement.files)) : false).length > 0
          ? ((application.agreements.physician.filter(agreement => agreement.details ? (!agreement.details.read_only && agreement.details.required && allSignaturesComplete(agreement.signatures)) : false).length / application.agreements.physician.filter(agreement => agreement.details ? !agreement.details.read_only && agreement.details.required : false).length) *  100).toFixed(0)
          : 0
    }
  }

  object.total_required = object.agreements.progress.agreements.total + object.agreements.progress.attachments.total + (object.agreements.progress.physician ? object.agreements.progress.physician.total : 0)
  object.total_complete = object.agreements.progress.agreements.complete + object.agreements.progress.attachments.complete + (object.agreements.progress.physician ? object.agreements.progress.physician.complete : 0)
  object.total_compliance_required = object.agreements.compliance.agreements.total + object.agreements.compliance.attachments.total + (object.agreements.compliance.physician ? object.agreements.compliance.physician.total : 0)
  object.total_compliance = object.agreements.compliance.agreements.total + object.agreements.compliance.attachments.total + (object.agreements.compliance.physician ? object.agreements.compliance.physician.total : 0)
  object.total_compliance_complete = object.agreements.compliance.agreements.complete + object.agreements.compliance.attachments.complete + (object.agreements.compliance.physician ? object.agreements.compliance.physician.complete : 0)
  object.compliance_percentage = object.total_compliance_required == 0 ? 100 : (object.agreements.compliance.agreements.complete + object.agreements.compliance.attachments.complete + (object.agreements.compliance.physician ? object.agreements.compliance.physician.complete : 0)) > 0 ? (((object.agreements.compliance.agreements.complete + object.agreements.compliance.attachments.complete + (object.agreements.compliance.physician ? object.agreements.compliance.physician.complete : 0)) / object.total_compliance_required) * 100).toFixed(0) : 0
  object.required_percentage = (object.agreements.progress.agreements.complete + object.agreements.progress.attachments.complete + (object.agreements.progress.physician ? object.agreements.progress.physician.complete : 0)) > 0 ? (((object.agreements.progress.agreements.complete + object.agreements.progress.attachments.complete + (object.agreements.progress.physician ? object.agreements.progress.physician.complete : 0)) / object.total_required) * 100).toFixed(0) : 0
  return object
}

function generatePrintAll(pages, includeAuditTrail){
  console.log(pages)

  var html = `
    <!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.0 Transitional//EN" "http://www.w3.org/TR/REC-html40/loose.dtd">
    <html>
      <head>
        <META http-equiv="Content-Type" content="text/html; charset=iso-8859-1">
        <META http-equiv="Content-Style-Type" content="text/css">
        <link href="https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800&display=swap" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css?family=La+Belle+Aurore&display=swap" rel="stylesheet" />
      </head>
      <body style="margin:0; padding:0;">
        <style>
          html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; font-size: 100%; font: inherit; vertical-align: baseline; text-rendering: geometricPrecision; }
          /* HTML5 display-role reset for older browsers */
          article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section { display: block; }
          body { line-height: 1; }
          ol, ul { list-style: none; }
          blockquote, q { quotes: none; }
          table { border-collapse: collapse; border-spacing: 0; }
          a, a:hover, a:focus, button:focus, button:active { color: inherit; text-decoration: none; outline: none; }
          * { -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; outline: none; font-family: Century Gothic; }
        </style>
  `

  for(var j in pages){
    const agreement = pages[j]
    for(var i in agreement.pages.pages){
      var styles = 'overflow: hidden; position: relative;'
      var ratio = 0.75
      if(agreement.details.size){
        styles += 'width: '+ (agreement.details.size.width*72) +'px; height: '+ (agreement.details.size.height*72) +'px;'
        ratio = 1
      } else {
        styles += 'width: 612px; height: 792px;'
      }

      var page = '<div style="'+styles+' background: url('+agreement.pages.pages[i].background+'); background-size: cover;"><div>'

      for(var j in agreement.pages.pages[i].fields){
        var field = agreement.pages.pages[i].fields[j]
        if(field.value && field.value !== 'null'){
          if(field.type == 'textbox'){
            page += '<p style="position: absolute; \
            top: '+(parseInt(field.position.y) * ratio)+'px; \
            left: '+(parseInt(field.position.x) * ratio)+'px; \
            width: '+(parseInt(field.position.w) * ratio)+'px; \
            height: '+(parseInt(field.position.h) * ratio)+'px; \
            text-align: '+field.textalignment+'; \
            font-size: 11px; \
            padding-left: 5px; \
            background: white; \
            font-family: Open Sans,sans-serif;">\
            '+convertToString(field.value).replace(/_/g, ' ')+'\
            </p>'
          }
          else if(field.type == 'checkbox'){
            if(field.value == "true" || field.value == true){
              page += ' <div style="width: 15px; height: 15px; background: white; border: 2px solid #aaa; border-radius: 2px; top: '+(parseInt(field.position.y) * ratio)+'px; left: '+(parseInt(field.position.x) * ratio)+'px; position: absolute;"> \
              <img \
              style="position: relative; \
              top: -2px; \
              width: 15px; \
              height: auto;" \
              src="https://s3-us-west-2.amazonaws.com/docuvault-uploads/check-mark-transparent-pink-27.png"/> \
              </div>'
            } else {
              page += ' <div \
              style="width: 15px; \
              height: 15px; \
              background: white; \
              border: 2px solid #aaa; \
              border-radius: 2px; \
              top: '+(parseInt(field.position.y) * ratio)+'px; \
              left: '+(parseInt(field.position.x) * ratio)+'px; \
              position: absolute;"> \
              </div>'
            }
          }
          else if(field.type == 'signature'){
            page += '<p style="position: absolute; \
            top: '+(parseInt(field.position.y) * ratio)+'px; \
            left: '+(parseInt(field.position.x) * ratio)+'px; \
            width: '+(parseInt(field.position.w) * ratio)+'px; \
            height: '+(parseInt(field.position.h) * ratio)+'px; \
            text-align: '+field.textalignment+'; \
            font-size: '+(((parseInt(field.position.h) -2) * ratio))+'px; \
            white-space: nowrap; \
            font-size: 24px; \
            background: white; \
            font-family: \'La Belle Aurore\', cursive;">\
            '+convertToString(field.value).replace(/_/g, ' ')+'\
            </p>'
          }
          else if(field.type == 'initials'){
            page += '<p style="position: absolute; \
            top: '+(parseInt(field.position.y) * ratio)+'px; \
            left: '+(parseInt(field.position.x) * ratio)+'px; \
            width: '+(parseInt(field.position.w) * ratio)+'px; \
            height: '+(parseInt(field.position.h) * ratio)+'px; \
            text-align: '+field.textalignment+'; \
            font-size: 16px; \
            white-space: nowrap; \
            background: white; \
            font-family: \'La Belle Aurore\', cursive;">\
            '+convertToString(field.value).replace(/_/g, ' ')+'\
            </p>'
          }
          else if(field.type == 'date'){
            page += '<p style="position: absolute; \
            top: '+(parseInt(field.position.y) * ratio)+'px; \
            left: '+(parseInt(field.position.x) * ratio)+'px; \
            width: '+(parseInt(field.position.w) * ratio)+'px; \
            height: '+(parseInt(field.position.h) * ratio)+'px; \
            text-align: '+field.textalignment+'; \
            font-size: 10px; \
            white-space: nowrap; \
            background: white; \
            font-family: Open Sans,sans-serif;">\
            '+convertToString(field.value).replace(/_/g, ' ')+'\
            </p>'
          }
        }
      }

      page = page + '</div></div>'
      html = html + page
    }

    if(agreement.auditTrail && includeAuditTrail){
      var styles = 'overflow: hidden; position: relative;'
      if(agreement.details.size){
        styles += 'width: '+ (agreement.details.size.width*72) +'px; height: '+ (agreement.details.size.height*72) +'px;'
      } else {
        styles += 'width: 612px; height: 792px;'
      }

      var page = '<div class="audit-page" style="'+styles+'"><div style="padding: 30px;">'

      page+= `
        <style>
          .audit-page { font-weight: 400; font-family: 'Open Sans', sans-serif !important; color: #555555; }
          .inline { display: inline-block; vertical-align: top; }
          .space-between { text-align: justify; }
          .right { float: right; }
          .bold { font-weight: 700; }
          .audit-page h1 { font-size: 18px; padding-top: 8px; }
          .header { padding: 6px 0; margin:15px 0;  border-top: 1px solid #bbb; border-bottom: 1px solid #bbb; }
          .right-align { text-align: right; }
          .audit-page p { line-height: 1.5em; font-size: 9px; }
          .pad-left { padding-left: 20px; }
          .spaced { padding: 10px 0; }
          .center { text-align: center; position: absolute; bottom: 0px; left: 0px; width: 100%; padding: 10px; margin: 0; }
          .event-wrap { padding: 3px; background: #ddd; margin: 2px 0; border-radius: 2px; }
          .event { font-size: 9px !important; font-weight: 700; margin:0 !important; padding: 0 !important; line-height: 1em !important; }
          .value { display: inline-block !important; font-size: 8px !important; margin: 0 !important; padding: 0 !important; line-height: 1em !important; }
        </style>
      `

      page+= `
        <div class="space-between">
          <h1 class="bold inline"> DOCUMENT AUDIT LOG </h1>
          <img class="inline right" style="max-width: 150px; height: 33px;" src="https://docuvault-uploads.s3-us-west-2.amazonaws.com/Logo.png" />
        </div>

        <div class="header">
          <div class="right-align inline">
            <p class="bold"> TITLE </p>
            <p class="bold"> AGREEMENT ID </p>
            <p class="bold"> STATUS </p>
          </div>
          <div class="pad-left inline">
            <p>${agreement.details.name}</p>
            <p>${agreement.id}</p>
            <p>Completed</p>
          </div>
        </div>
      `

      if(agreement.auditTrail.history){
        agreement.auditTrail.history.forEach(event => {
          page += `
            <div class="event-wrap">
              <p class="event">${event.ipAddress} (${moment(event.timestamp).format('MM/DD/YYYY h:mm:ss a')}) - ${event.action} </p>
              ${event.value ? '<span class="value">value: ' + event.value + '</span>' : ''}
              ${event.filler ? '<span class="value">filler: ' + event.filler + '</span>' : ''}
              ${event.fieldId ? '<span class="value">fieldId: ' + event.fieldId + '</span>' : ''}
              ${event.poa ? '<span class="value">Power of Attorney: ' + event.poa + '</span>' : ''}
            </div>
          `
        })
      }

      page += '<div class="center"> <p class="header">This document audit log is automatically provided in compliance with United States electronic signature  laws.</p> </div>'

      page = page + '</div></div>'
      html = html + page
    }
  }

  html = html + '</body></html>'
  return html
}

function search(id, term){
  return axios.post(urls.api + 'facilitySearchApplications/' + id, {search: term}, {'headers':{
    'X-Auth-Token': localStorage.token
  }})
}

function hasPhysicianDocs(application){
  if(application.agreements.physician && application.agreements.physician.length > 0) {
    return true
  }
  else {
    return false
  }
}

function myInformationComplete(myInfo){
  let complete = true
  if(!myInfo.contact_info.first_name || !myInfo.contact_info.last_name || !myInfo.contact_info.phone_number || !myInfo.contact_info.email) complete = false
  return complete
}

function allRequiredUploadsComplete(uploads){
  let complete = true
  for(var i in uploads){
    // console.log('uploads', uploads[i])
    if(uploads[i].required && (!(uploads[i].files && uploads[i].files.length > 0) && !(uploads[i].object_name) && !uploads[i].received)){
      complete = false
    }
  }
  return complete
}

function generateResidentSteps(application){
  console.log(application)
  const array = [{step: 'My Information', complete: myInformationComplete(application.myInfo) }]
  if(hasPhysicianDocs(application)) array.push({step: 'Physician Forms', complete: application.myInfo.application_info.physician_forms_printed ? true : false})
  array.push({step: 'Sign Agreements', complete: allResidentDocsComplete(application) })
  if(application.myDocuments.required_documents && application.myDocuments.required_documents.length) array.push({step: 'Attach Documents', complete: allRequiredUploadsComplete(application.myDocuments.required_documents)})
  array.push({step: 'Summary', complete: application.progress.current == 'complete' || application.progress.current == 'In Review'})
  return array
}

function residentSignaturesComplete(signatures){
  let complete = true

  for(var i in signatures)
    if(i == 'Resident' && signatures[i].signed == false) complete = false

  return complete
}

function prefillComplete(signatures){
  let prefilled = true

  for(var i in signatures)
    if(i == 'Community' && signatures[i].order == 0 && signatures[i].signed == false) prefilled = false

  return prefilled
}

const functions = {
  list,
  listAll,
  get,
  update,
  remove,
  create,
  communityTurn,
  managerTurn,
  residentTurn,
  allSignaturesComplete,
  prepareAgreement,
  removeAgreement,
  generateApplication,
  prepAgreements,
  generateHtml,
  listResidentApplications,
  generatePhsyicianFormsHtml,
  listByDepartment,
  countByDepartment,
  allRequiredDocsComplete,
  allUploadsReceived,
  allPhysicianDocsComplete,
  getProgress,
  allAgreementsPrepared,
  physicianDocsSent,
  allAgreementsComplete,
  countApplications,
  generatePrintAll,
  search,
  hasPhysicianDocs,
  generateResidentSteps,
  residentSignaturesComplete,
  prefillComplete
}

export default functions
