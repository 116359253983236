import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Logo from './logo'
import Wrapper from './wrapper'
import Row from './row'
import Text from './text'
import User from './user'
import Alerts from './alerts'
import Select from './select'
import Contentful from '../util/contentful'
import user from '../util/user'
import communities from '../util/community'

const Header = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 80px;
  box-shadow: 0 0 8px rgba(0,0,0,0.1);
  background: white;
  z-index: 10;
  @media (prefers-color-scheme: dark) {
    background: #2e2f2f;
    p {
      color: white;
    }
  }
`

const Spacer = styled.div`
  width: 100%;
  height: 80px;
`

const LogoWrap = styled.div`
  padding-right: 48px;
  margin-right: 48px;
  border-right: 1px solid #ddd;
  @media (prefers-color-scheme: dark) {
    border-right: 1px solid #555;
  }
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  & img {
    margin-left: 16px;
    position: relative;
    top: 4px;
    cursor: pointer;
    width: 40px;
  }
`

const Heart = styled.img`
  margin: 0 8px;
`

export default props => {
  const [community, setCommunity] = useState()
  const [options, setOptions] = useState([ {label: 'Logout', action: () => logout()}])

  function logout(){
    localStorage.removeItem('token')
    localStorage.removeItem('pass')
    localStorage.removeItem('selected_department')
    if(window.drift) window.drift.reset()
    if(window.FS) window.FS.shutdown()
    window.location.href = '/login'
  }

  useEffect(() => {
    async function getCommunity(){
      try{
        const me = await user.get()
        const _community = await communities.get(me.data.facilityId)
        setCommunity(_community.data)
      } catch (err){
        console.log(err)
        window.location.href = '/login'
      }
    }

    getCommunity()
  }, [])

  return (
    <div>
      <Header {...props}>
        <Wrapper>
          <Row alignItems="center" height="80px">
            <LogoWrap>
              <Logo />
            </LogoWrap>

            <Row justify="space-between" alignItems="center">
              <Text nowrap weight={800} uppercase size={'14px'} kind="p">We <img src="/icon-heart.svg" /> {community && community.details.name}</Text>

              {props.children ? props.children : (
                <Actions>
                  <User options={options} />
                </Actions>
              )}
            </Row>
          </Row>
        </Wrapper>
      </Header>
      <Spacer />
    </div>
  )
}
