import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import esign from "../util/esign";
import AutosizeInput from "react-input-autosize";
import { Required, Modal, Row, Button, Text, Checkbox } from "./index";
import InputMask from "react-input-mask";
import moment from "moment";
import { set } from "lodash";

const TextWrap = styled.div`
  &.required {
    &:after {
      content: "";
      width: 8px;
      height: 8px;
      background: url("/icon-required.svg");
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      top: 0px;
      left: -10px;
    }
  }
`;

const Textfield = styled(InputMask)`
  border: ${(props) =>
    props.unassigned ? "1px solid #ddd" : "1px solid #F7912F"};
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 11px;
  color: #4d5864;
  background: ${(props) => (props.unassigned ? "#E9F3EA" : "#FFF4EB")};
  cursor: ${(props) => (props.unassigned ? "not-allowed" : "pointer")};
  &:focus {
    box-shadow: 0px 0px 8px rgba(247, 145, 47, 0.5);
  }
  ${window.screen.orientation.type === "portrait-primary"
    ? `@media (max-width: 950px) {
    font-size: 0.5rem;
  }`
    : ""}
`;

const TextArea = styled.textarea`
  border: ${(props) =>
    props.unassigned ? "1px solid #ddd" : "1px solid #F7912F"};
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 11px;
  color: #4d5864;
  resize: none;
  background: ${(props) => (props.unassigned ? "#E9F3EA" : "#FFF4EB")};
  cursor: ${(props) => (props.unassigned ? "not-allowed" : "pointer")};
  &:focus {
    box-shadow: 0px 0px 8px rgba(247, 145, 47, 0.5);
  }
  ${window.screen.orientation.type === "portrait-primary"
    ? `@media (max-width: 950px) {
    font-size: 1rem;
  }`
    : ""}
`;

const Signature = styled.div`
  border: ${(props) =>
    props.unassigned ? "1px solid #ddd" : "1px solid #F7912F"};
  border-radius: 6px;
  color: #4d5864;
  font-size: 2em;
  background: ${(props) => (props.unassigned ? "#E9F3EA" : "#FFF4EB")};
  cursor: ${(props) => (props.unassigned ? "not-allowed" : "pointer")};
  font-family: "La Belle Aurore", cursive;
  &.required {
    &:after {
      content: "";
      width: 8px;
      height: 8px;
      background: url("/icon-required.svg");
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      top: 0px;
      left: -10px;
    }
  }
  ${window.screen.orientation.type === "portrait-primary"
    ? `@media (max-width: 950px) {
    font-size: 1rem;
  }`
    : ""}
`;

const InsertSignature = styled.input`
  padding: 16px 32px 4px;
  width: 100%;
  font-size: 45px;
  border: ${(props) =>
    props.unassigned ? "1px solid #ddd" : "1px solid #F7912F"};
  border-radius: 6px;
  color: #4d5864;
  background: ${(props) => (props.unassigned ? "#E9F3EA" : "#FFF4EB")};
  cursor: ${(props) => (props.unassigned ? "not-allowed" : "pointer")};
  font-family: "La Belle Aurore", cursive;
  text-align: center;
  @media (max-width: 950px) {
  ${window.screen.orientation.type === "portrait-primary"
  ? "font-size: 1rem;": ""}
  font-size: 2rem;
  };
  
`;

const Initials = styled.div`
  border: ${(props) =>
    props.unassigned ? "1px solid #ddd" : "1px solid #F7912F"};
  border-radius: 6px;
  color: #4d5864;
  font-size: 22px;
  background: ${(props) => (props.unassigned ? "#E9F3EA" : "#FFF4EB")};
  cursor: ${(props) => (props.unassigned ? "not-allowed" : "pointer")};
  font-family: "La Belle Aurore", cursive;
  &.required {
    &:after {
      content: "";
      width: 8px;
      height: 8px;
      background: url("/icon-required.svg");
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      top: 0px;
      left: -10px;
    }
  }
  ${window.screen.orientation
    ? `@media (max-width: 950px) {
    font-size: 1rem;
  }`
    : ""}
`;

const CheckboxSign = styled.div`
  background-color: ${(props) => (props.unassigned ? "#E9F3EA" : "#FFF4EB")};
  border: ${(props) =>
    props.unassigned ? "1px solid #ddd" : "1px solid #F7912F"};
  border-radius: 6px;
  cursor: ${(props) => (props.unassigned ? "not-allowed" : "pointer")};
  background-image: ${(props) =>
    props.checked ? "url('/icon-checkmark-blue.svg')" : "none"};
  background-size: 76% auto !important;
  background-repeat: no-repeat;
  background-position: center center;
  &.required {
    &:after {
      content: "";
      width: 8px;
      height: 8px;
      background: url("/icon-required.svg");
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      top: 0px;
      left: -10px;
    }
  }
`;

const Actions = styled(Row)`
  button {
    margin: 0 8px;
  }
`;

const PowerOfAttorney = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 11px;
  font-weight: 700;
`;

const Dated = styled.div`
  border: ${(props) =>
    props.unassigned ? "1px solid #ddd" : "1px solid #F7912F"};
  border-radius: 6px;
  color: #4d5864;
  font-size: 11px;
  padding: 2px;
  min-height: 22px;
  background: ${(props) => (props.unassigned ? "#E9F3EA" : "#FFF4EB")};
  cursor: ${(props) => (props.unassigned ? "not-allowed" : "pointer")};
  &.required {
    &:after {
      content: "";
      width: 8px;
      height: 8px;
      background: url("/icon-required.svg");
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      top: 0px;
      left: -10px;
    }
  }
`;

const Field = (props) => {
  const [value, setValue] = useState(props.field.value);
  const [resPartyValue, setResPartyValue] = useState(props.field.value);
  const [resPartySigned, setResPartySigned] = useState(
    props.field.prefill === "signature_rp" && props.field.value !== null
      ? true
      : false
  );
  const [signature, setSignature] = useState(props.field.value);
  const [resPartySignature, setResPartySignature] = useState(props.field.value);
  const [initials, setInitials] = useState(props.field.value);
  const [checked, setChecked] = useState(props.field.value);
  const [showSignatureModal, setShowSignatureModal] = useState(false);
  const [showInitialsModal, setShowInitialsModal] = useState(false);

  const filler = props.match.params.filler;
  const field = props.field;

  const openSignatureModal = () => {
    setShowSignatureModal(true);
    // const viewportmeta = document.querySelector('meta[name=viewport]');
// viewportmeta.setAttribute('content', "initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no");
  };

  const closeSignatureModal = () => {
    setShowSignatureModal(false);
//     const viewportmeta = document.querySelector('meta[name=viewport]');
// viewportmeta.setAttribute('content', "initial-scale=1.0, maximum-scale=1.0, user-scalable=no");
  }

  function isNotGeneratedEmail(email) {
    let isNot = true;
    if (email && email.indexOf("resident+") !== -1) isNot = false;
    return isNot;
  }

  function updateField(e) {
    setValue(e.target.value);
    props.updatePage(e.target.value, props.field.fieldid);
  }

  function insertSignature() {
    setValue(signature);
    setShowSignatureModal(false);

    props.updateAuditTrail({
      action: "Digitially Signed Signature",
      timestamp: new Date(),
      value: signature,
      filler: props.field.filler,
      fieldId: props.field.fieldid,
      poa: field.power_of_attorney,
      ipAddress: props.ipaddress,
    });
    // props.updatePage(signature, props.field.fieldid);
    props.updatePage(
      field.power_of_attorney && signature.indexOf("POA") === -1
        ? signature + " POA"
        : signature,
      field.fieldid
    );
  }

  function insertResPartySignature() {
    setResPartySigned(true);
    setResPartyValue(resPartySignature);
    setValue(resPartySignature);
    setShowSignatureModal(false);

    props.updateAuditTrail({
      action: "Digitially Signed Signature",
      timestamp: new Date(),
      value: resPartySignature,
      filler: props.field.filler,
      fieldId: props.field.fieldid,
      poa: field.power_of_attorney,
      ipAddress: props.ipaddress,
    });
    // props.updatePage(resPartySignature, props.field.fieldid);
    props.updatePage(
      field.power_of_attorney && signature.indexOf("POA") === -1
        ? resPartySignature + " POA"
        : resPartySignature,
      field.fieldid
    );
  }

  function insertDate() {
    setValue(moment().format("MM/DD/YYYY"));
    props.updateAuditTrail({
      action: "Digitially Inserted Date",
      timestamp: new Date(),
      value: moment().format("MM/DD/YYYY"),
      filler: props.field.filler,
      fieldId: props.field.fieldid,
      ipAddress: props.ipaddress,
    });
    props.updatePage(moment().format("MM/DD/YYYY"), props.field.fieldid);
  }

  function checkInitials() {
    if (value || !initials) {
      setShowInitialsModal(true);
    } else {
      if (field.prefill === "rp_initials") {
        setValue(initials);
        props.updatePage(initials, field.fieldid);
      } else {
        setValue(
          field.type === "initials" && props.localInitials
            ? props.localInitials
            : initials
        );
        props.updatePage(
          field.type === "initials" && props.localInitials
            ? props.localInitials
            : initials,
          field.fieldid
        );
      }
    }
  }

  function insertInitials() {
    setValue(initials);
    setInitials(initials);
    props.updateAuditTrail({
      action: "Digitially Signed Initials",
      timestamp: new Date(),
      value: initials,
      filler: props.field.filler,
      fieldId: props.field.fieldid,
      ipAddress: props.ipaddress,
    });
    setShowInitialsModal(false);
    props.updatePage(initials, props.field.fieldid);
  }

  function clear() {
    setValue(null);
    setShowSignatureModal(false);
    setShowInitialsModal(false);
    if (field.prefill === "signature_rp") {
      setResPartySigned(false);
      setResPartyValue(null);
    }

    props.updatePage(null, props.field.fieldid);
  }

  function check() {
    setChecked(!checked);

    if (props.field.grouprequirements === "Only_One") {
      props.validateCheckboxs(props.field.fieldid, props.field.groupname);
    } else {
      props.updatePage(!checked, props.field.fieldid);
    }
  }

  function keypressHandler(e) {
    if (e.key === "Enter") {
      document.getElementById(field.fieldid).blur();
    }
  }

  function setPowerOfAttorney() {
    field.power_of_attorney = !field.power_of_attorney;
    props.updatePage(
      field.power_of_attorney,
      props.field.fieldid,
      "power_of_attorney"
    );
  }

  const styles = {
    position: "absolute",
    width: parseInt(field.position.w) + "px",
    height: parseInt(field.position.h) + "px",
    left: parseInt(field.position.x) + "px",
    top: parseInt(field.position.y) + "px",
  };

  const size = {
    width: parseInt(field.position.w) + "px",
    height: parseInt(field.position.h) + "px",
  };

  useEffect(() => {
    const prefill = props.prefills[props.field.prefill];

    prefill &&
      props.field.type !== "Signature" &&
      props.field.type !== "signature" &&
      props.field.type !== "Initials" &&
      props.field.type !== "initials" &&
      setValue(prefill);

    props.field.value &&
      props.field.value !== "undefined" &&
      props.field.value !== "null" &&
      setValue(props.field.value);

    setChecked(props.field.value);

    if (
      field.prefill === "email_address" &&
      !isNotGeneratedEmail(field.value)
    ) {
      setValue("");
    }

    if (
      props.field.filler === "Resident" &&
      props.field.prefill !== "rp_initials"
    )
      setInitials(
        props.field.value ||
          props.prefills.initials ||
          props.prefills.name_full.match(/\b(\w)/g).join("")
      );
    if (
      props.field.filler === "Resident" &&
      props.field.prefill === "rp_initials"
    )
      setInitials(props.field.value || props.prefills.rp_initials);

    if (props.field.filler === "Resident")
      setSignature(props.field.value || props.prefills.name_full);

    if (
      props.field.filler === "Resident" &&
      props.field.prefill === "signature_rp"
    ) {
      setResPartySignature(props.field.value || props.prefills.signature_rp);
    }

    if (field.filler === "Resident" && field.prefill === "signature_user_1")
      setResPartySignature(field.value || props.prefills.signature_user_1);

    if (props.field.filler === "Community")
      setInitials(
        props.field.value ||
          (props.communitySigner.details &&
            props.communitySigner.details.initials) ||
          props.communitySigner.name.match(/\b(\w)/g).join("")
      );
    if (props.field.filler === "Community")
      setSignature(
        props.field.value ||
          (props.communitySigner.details &&
            props.communitySigner.details.signature) ||
          props.communitySigner.name
      );

    if (props.field.filler === "Manager")
      setInitials(
        props.field.value ||
          (props.communitySigner.details &&
            props.communitySigner.details.initials) ||
          props.communitySigner.name.match(/\b(\w)/g).join("")
      );
    if (props.field.filler === "Manager")
      setSignature(
        props.field.value ||
          (props.communitySigner.details &&
            props.communitySigner.details.signature) ||
          props.communitySigner.name
      );
  }, [props.listen, props.prefills]);

  if (filler === field.filler || filler === "All") {
    if (field.type === "textbox") {
      if (field.multiline && field.multiline === "true") {
        return (
          <TextWrap
            style={styles}
            className={
              field.isrequired &&
              field.isrequired !== "null" &&
              field.isrequired !== "false" &&
              field.isrequired !== "undefined"
                ? "required"
                : null
            }
          >
            <TextArea
              onKeyPress={(e) => keypressHandler(e)}
              mask={
                field.format &&
                field.format !== "undefined" &&
                field.format !== "null"
                  ? field.format
                  : null
              }
              formatChars={
                field.format &&
                field.format !== "undefined" &&
                field.format !== "null"
                  ? { 0: "[0-9]", S: "[A-Za-z]" }
                  : null
              }
              placeholder={
                field.placeholder &&
                field.placeholder !== "undefined" &&
                field.placeholder !== "null"
                  ? field.placeholder
                  : null
              }
              id={field.fieldid}
              type="text"
              onBlur={updateField}
              onChange={(e) => setValue(e.target.value)}
              value={value}
              style={size}
            />
          </TextWrap>
        );
      } else {
        return (
          <TextWrap
            style={styles}
            className={
              field.isrequired &&
              field.isrequired !== "null" &&
              field.isrequired !== "false" &&
              field.isrequired !== "undefined"
                ? "required"
                : null
            }
          >
            <Textfield
              onKeyPress={(e) => keypressHandler(e)}
              mask={
                field.format &&
                field.format !== "undefined" &&
                field.format !== "null"
                  ? field.format
                  : null
              }
              formatChars={
                field.format &&
                field.format !== "undefined" &&
                field.format !== "null"
                  ? { 0: "[0-9]", S: "[A-Za-z]" }
                  : null
              }
              placeholder={
                field.placeholder &&
                field.placeholder !== "undefined" &&
                field.placeholder !== "null"
                  ? field.placeholder
                  : null
              }
              id={field.fieldid}
              type="text"
              onBlur={updateField}
              onChange={(e) => setValue(e.target.value)}
              value={value}
              style={size}
            />
          </TextWrap>
        );
      }
    }

    if (
      field.type === "signature" &&
      (field.prefill === "signature_rp" || field.prefill === "signature_user_1")
    ) {
      return (
        <div>
          <Signature
            id={field.fieldid}
            onClick={() => openSignatureModal()}
            style={styles}
            className={
              field.isrequired &&
              field.isrequired !== "null" &&
              field.isrequired !== "false" &&
              field.isrequired !== "undefined"
                ? "required"
                : null
            }
          >
            {resPartySigned ? resPartyValue : ""}{" "}
            {field.power_of_attorney && <PowerOfAttorney>POA</PowerOfAttorney>}
          </Signature>

          <Modal
            show={showSignatureModal}
            close={() => closeSignatureModal()}
            title="Insert Signature"
          >
            <Row padding="24px 24px 0" direction="column">
              <InsertSignature
                type="text"
                value={resPartySignature}
                onChange={(e) => setResPartySignature(e.target.value)}
                autoFocus={true}
              />
            </Row>
            <Row
              padding="0 24px 24px"
              alignItems="center"
              justify="center"
              direction="column"
            >
              <Row padding="0 0 32px" justify="center" alignItems="center">
                {" "}
                <Text kind="p" size="12px">
                  I understand this is a legal representation of my signature
                </Text>
              </Row>
              {props.field.filler === "Resident" &&
                field.type === "signature" &&
                (field.prefill === "signature_rp" ||
                  field.prefill === "signature_user_1") && (
                  <Row padding="0 0 32px" justify="center" alignItems="center">
                    <Checkbox
                      selected={field.power_of_attorney}
                      onClick={() => setPowerOfAttorney()}
                    />{" "}
                    <Text kind="p" size="14px">
                      Signing through Power of Attorney
                    </Text>
                  </Row>
                )}
              <Actions justify="center">
                <Button onClick={insertResPartySignature}>Sign</Button>
                {resPartySignature ? (
                  <Button secondary onClick={clear}>
                    Clear
                  </Button>
                ) : null}
              </Actions>
            </Row>
          </Modal>
        </div>
      );
    }

    if (
      field.type === "signature" &&
      (field.prefill !== "signature_rp" || field.prefill !== "signature_user_1") 
    ) {
      return (
        <div>
          <Signature
            id={field.fieldid}
            onClick={() => setShowSignatureModal(true)}
            style={styles}
            className={
              field.isrequired &&
              field.isrequired !== "null" &&
              field.isrequired !== "false" &&
              field.isrequired !== "undefined"
                ? "required"
                : null
            }
          >
            {value}{" "}
            {field.power_of_attorney && <PowerOfAttorney>POA</PowerOfAttorney>}
          </Signature>

          <Modal
            show={showSignatureModal}
            close={() => setShowSignatureModal(false)}
            title="Insert Signature"
          >
            <Row padding="24px 24px 0" direction="column">
              <InsertSignature
                type="text"
                value={signature}
                onChange={(e) => setSignature(e.target.value)}
                autoFocus={true}
              />
            </Row>
            <Row
              padding="0 24px 24px"
              alignItems="center"
              justify="center"
              direction="column"
            >
              <Row padding="0 0 32px" justify="center" alignItems="center">
                {" "}
                <Text kind="p" size="12px">
                  I understand this is a legal representation of my signature
                </Text>
              </Row>
              {props.field.filler === "Resident" && (
                <Row padding="0 0 32px" justify="center" alignItems="center">
                  <Checkbox
                    selected={field.power_of_attorney}
                    onClick={() => setPowerOfAttorney()}
                  />{" "}
                  <Text kind="p" size="14px">
                    Signing through Power of Attorney
                  </Text>
                </Row>
              )}
              <Actions justify="center">
                <Button onClick={insertSignature}>Sign</Button>
                {value ? (
                  <Button secondary onClick={clear}>
                    Clear
                  </Button>
                ) : null}
              </Actions>
            </Row>
          </Modal>
        </div>
      );
    }

    if (field.type === "initials") {
      return (
        <div>
          <Initials
            id={field.fieldid}
            onClick={checkInitials}
            style={styles}
            className={
              field.isrequired &&
              field.isrequired !== "null" &&
              field.isrequired !== "false" &&
              field.isrequired !== "undefined"
                ? "required"
                : null
            }
          >
            {value}
          </Initials>

          <Modal
            show={showInitialsModal}
            close={() => setShowInitialsModal(false)}
            title="Insert Initials"
          >
            <Row padding="24px" direction="column">
              <InsertSignature
                type="text"
                value={initials}
                onChange={(e) => setInitials(e.target.value)}
                autoFocus={true}
              />
            </Row>
            <Row
              padding="0 24px 24px"
              alignItems="center"
              justify="center"
              direction="column"
            >
              <Text kind="p" margin="0 0 16px" size="14px">
                I understand this is a legal representation of my initials
              </Text>
              <Button onClick={insertInitials}>Insert Initials</Button>
            </Row>
          </Modal>
        </div>
      );
    }

    if (field.type === "initials" && field.prefill === "rp_initials") {
      return (
        <div>
          <Initials
            id={field.fieldid}
            onClick={checkInitials}
            style={styles}
            className={
              field.isrequired &&
              field.isrequired !== "null" &&
              field.isrequired !== "false" &&
              field.isrequired !== "undefined"
                ? "required"
                : null
            }
          >
            {value}
          </Initials>

          <Modal
            show={showInitialsModal}
            close={() => setShowInitialsModal(false)}
            title="Insert Initials"
          >
            <Row padding="24px" direction="column">
              <InsertSignature
                type="text"
                value={initials}
                onChange={(e) => setInitials(e.target.value)}
                autoFocus={true}
              />
            </Row>
            <Row
              padding="0 24px 24px"
              alignItems="center"
              justify="center"
              direction="column"
            >
              <Text kind="p" margin="0 0 16px" size="14px">
                I understand this is a legal representation of my initials
              </Text>
              <Button onClick={insertInitials}>Insert Initials</Button>
            </Row>
          </Modal>
        </div>
      );
    }

    if (field.type === "checkbox") {
      return (
        <CheckboxSign
          id={field.fieldid}
          className={
            field.isrequired &&
            field.isrequired !== "null" &&
            field.isrequired !== "false" &&
            field.isrequired !== "undefined"
              ? "required"
              : null
          }
          checked={checked}
          onClick={check}
          style={styles}
        />
      );
    }

    if (field.type === "date") {
      return (
        <Dated
          id={field.fieldid}
          className={
            field.isrequired &&
            field.isrequired !== "null" &&
            field.isrequired !== "false" &&
            field.isrequired !== "undefined"
              ? "required"
              : null
          }
          onClick={insertDate}
          style={styles}
        >
          {value}
        </Dated>
      );
    }
  }
  //Unassigned fields
  else {
    if (field.type === "textbox") {
      if (field.multiline === "true") {
        return (
          <TextArea
            unassigned
            id={field.fieldid}
            type="text"
            value={value}
            style={styles}
            disabled
          />
        );
      } else {
        return (
          <Textfield
            unassigned
            id={field.fieldid}
            type="text"
            value={value}
            style={styles}
            disabled
          />
        );
      }
    }

    if (field.type === "signature") {
      return (
        <Signature unassigned id={field.fieldid} style={styles}>
          {" "}
          {value}{" "}
        </Signature>
      );
    }

    if (field.type === "initials") {
      return (
        <Initials unassigned id={field.fieldid} style={styles}>
          {" "}
          {value}{" "}
        </Initials>
      );
    }

    if (field.type === "checkbox") {
      return (
        <CheckboxSign
          unassigned
          id={field.fieldid}
          checked={checked}
          style={styles}
        />
      );
    }

    if (field.type === "date") {
      return (
        <Dated unassigned id={field.fieldid} style={styles}>
          {value}
        </Dated>
      );
    }
  }
  return null;
};

export default Field;
