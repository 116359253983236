import React from 'react';
import styled from "styled-components"
import { ReactComponent as AnimatedMenu } from './animated_dots.svg';

const Animated = styled(AnimatedMenu)`
  width: 17px;
  position: relative;
  z-index: 9;
`

const Dots = styled.img`
  width: 6px;
  position: relative;
  z-index: 9;
  cursor: pointer;
`

function MyComponent(props) {
  if(props.downloading) return <Animated />
  return <Dots src="/icon-dots.svg" />
}

export default MyComponent;
