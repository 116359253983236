import React from 'react'
import styled from "styled-components"
import Logo from './logo'
import Wrapper from './wrapper'
import Row from './row'
import Text from './text'
import Link from './link'
import media from '../util/media'

const Main = styled.div`
  display: none;
  ${media.tabletPlus`
    display: block;
  `}
`

const Sidebar = styled.div`
  background: white;
  width: 257px;
  box-shadow: 0 0 8px rgba(0,0,0,0.1);
  height: 100%;
  padding: 128px 0 0;
  position: fixed;
  z-index: 1;
  top:0;
  left: 0;
  @media (prefers-color-scheme: dark) {
    background: #3f4040;
    p {
      color: white;
    }
  }
`

const Spacer = styled.div`
  width: 257px;
  height: 100vh;
`

const Item = styled(Row)`
  margin-bottom: 24px;
  & img {
    width: 24px;
    margin-right: 16px;
  }
`

export default props => (
  <Main>
    <Spacer />
    <Sidebar {...props}>
      <Wrapper>
        <Text kind="p" weight={800} margin={"0 0 32px"} size={"14px"}>MENU</Text>
        <Row direction="column">
          <Link to="/dashboard">
            <Item alignItems="center">
              <img src="/icon-home.svg" />
              <Text kind="p" size={'14px'} weight={600}>Dashboard</Text>
            </Item>
          </Link>

          <Link to="/add-resident">
            <Item alignItems="center">
              <img src="/icon-person.svg" />
              <Text kind="p" size={'14px'} weight={600}>Add Resident</Text>
            </Item>
          </Link>

          <Link to="/list">
            <Item alignItems="center">
              <img src="/icon-persons.svg" />
              <Text kind="p" size={'14px'} weight={600}>Resident List</Text>
            </Item>
          </Link>


          <Link to="/help-center">
            <Item alignItems="center">
              <img src="/icon-question.svg" />
              <Text kind="p" size={'14px'} weight={600}>Help Center</Text>
            </Item>
          </Link>
        </Row>
      </Wrapper>
    </Sidebar>
  </Main>
)
