import React from "react";
import Header from "../components/header";
import { useHistory } from "react-router-dom";
import { Button } from "../components";

import styled from "styled-components";

const PageWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 32px;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: start;
  }
`;

const PageNotFoundImage = styled.img`
  max-width: 535px;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const ButtonContainer = styled.div`
  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    justify-content: center;
    & > button {
      width: 100%;
    }
  }
`;

const MainText = styled.div`
  font-size: 48px;
  margin-bottom: 8px;
  font-weight: 600;
  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const SubText = styled.div`
  font-size: 24px;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const PageNotFound = () => {
  return (
    <>
      {/* <Header /> */}
      <PageWrapper>
        <div>
          <PageNotFoundImage src="/404.svg" alt="Page Not Found" />
        </div>
        <div
          style={{
            maxWidth: 600,
          }}
        >
          <div>
            <MainText>
              We can’t seem to find the page you’re looking for.
            </MainText>
          </div>
          <SubText>
            Don’t worry, you didn’t break anything — technology just likes to
            keep us on our toes. Try heading back to get
            back on track.
          </SubText>
          
          <ButtonContainer>
            <Button onClick={() => window.location.replace("/")}>
              <div>Take Me Back</div>
            </Button>
          </ButtonContainer>
        </div>
      </PageWrapper>
    </>
  );
};

export default PageNotFound;
