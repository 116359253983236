import React, { useEffect, useState } from 'react'
import styled from "styled-components"
import colors from '../util/colors'
import applications from '../util/application'
import aws from '../util/aws'
import pdf from '../util/pdf'
import agreements from '../util/agreements'
import { Modal, Row, Button, Text, Checkbox, Label, Loading } from './index'
import PDFViewer from 'pdf-viewer-reactjs'

const Preview = styled.div`

`

export default props => {
  const [show, setShow] = useState(props.show)
  const [buffer, setBuffer] = useState()
  const [loaded, setLoaded] = useState()
  const [pageNumber, setPageNumber] = useState(1)

  function arrayBufferToBase64( buffer ) {
      var binary = '';
      var bytes = new Uint8Array( buffer );
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
      }
      return window.btoa( binary );
  }

  useEffect(() => {
    if(props.agreement){
      async function init(){
        if(props.agreement.agreement_filename){
          
          const url = await aws.getObject(props.agreement.agreement_filename)
          setBuffer(arrayBufferToBase64(url.data))
          setLoaded(true)
        } else {
          if(props.agreement.agreement_id){
            const agreement = await agreements.get(props.agreement.agreement_id)
            const html = applications.generateHtml(agreement.data)
            const generated = await pdf.generate(html, null, 'pdf')
            const url = await aws.getObject(generated.data.Key)
            setBuffer(arrayBufferToBase64(url.data))
            setLoaded(true)
          }
        }
      }

      setShow(props.show)
      init()
    }
  }, [props.show, props.agreement])

  return (
    <Modal width={800} title={"Preview"} show={show} close={props.close}>

        {loaded ? (
          <Row>
            {buffer && <PDFViewer document={{base64:buffer}} />}
          </Row>
        ) : <Loading />}


    </Modal>
  )
}
