import React, { useEffect, useState } from 'react'
import styled from "styled-components"
import { Modal, Text, Row, Markdown } from '../components'
import colors from '../util/colors'
import Contentful from '../util/contentful'

const HelpButton = styled.img`
  position: fixed;
  bottom: 24px;
  right: 24px;
  margin: ${props => props.margin ? props.margin : 0};
  width: ${props => props.full ? '100%' : 'auto'};
  cursor: pointer;
  z-index: 10;
`

const Back = styled(Text)`
  cursor: pointer;
`

const Link = styled(Text)`
  color: #52a0ce;
  display: inline-block;
  position: relative;
  padding-right: 22px;
  cursor: pointer;
  &:after {
    content: '';
    background: url(/icon_arrow_right.svg);
    width: 8px;
    height: 10px;
    background-size: contain !important;
    background-repeat: no-repeat;
    position: absolute;
    top: 7px;
    right: 0;
    opacity: 0.7;
  }
`

export default props => {
  const [articles, setArticles] = useState()
  const [article, setArticle] = useState()
  const [videos, setVideos] = useState()
  const [show, setShow] = useState()

  useEffect(() => {
    async function getHelp(){
      let page
      if(window.location.pathname.includes('application')){
        page = await Contentful.query({content_type: 'page', 'fields.url': '/application'})
      }
      else if(window.location.pathname.includes('/sign')){
        page = await Contentful.query({content_type: 'page', 'fields.url': '/sign'})
      }
      else {
        page = await Contentful.query({content_type: 'page', 'fields.url': window.location.pathname})
      }
      if(page.total > 0){
        const _articles = await Contentful.query({content_type: 'helpArticle', 'fields.pages.sys.id': page.items[0].sys.id, order: '-fields.order'})
        const _videos = await Contentful.query({content_type: 'tutorialVideo', 'fields.urls.sys.id': page.items[0].sys.id, order: '-fields.order'})
        if(_articles.total > 0){
          setArticles(_articles.items)
          setVideos(_videos.items)
        }
      }
    }

    getHelp()
  }, [])

  if(!articles) return null
  return (
    <div>
      <HelpButton onClick={() => setShow(true)} src="/icon-help.svg"/>

      <Modal width={800} title={"Help Articles"} show={show} close={() => setShow(false)}>
        {article ? (
          <Row padding="24px 48px" direction="column">
            <Back onClick={() => setArticle(undefined)} size={"12px"} weight={700} margin={"0 0 16px"}>{`< Back`}</Back>

            <Markdown source={article.fields.article} />
          </Row>
        ) : (
          <Row padding="24px" direction="column">
            {articles && articles.map((item, index) => (
              <Link onClick={() => setArticle(item)} margin="0 0 8px" weight="700" key={index}>{item.fields.tItle}</Link>
            ))}
          </Row>
        )}

      </Modal>
    </div>
  )
}
