import React, { useState, useEffect } from "react"
import styled from "styled-components"
import application from '../util/application'
import community from '../util/community'
import user from '../util/user'
import queryString from 'query-string'
import emailer from '../util/emails'
import moment from 'moment'
import { HelpButton, Header, Sidebar, Row, Text, Box, Wrapper, Link, Button, Input, BoxRow, Cells, Cell, Dashboard, Dots, Label, Select, Add, Checkbox, Pill, Loading, Modal, Close, MessageBar, Required } from '../components'

const Dash = styled(Dashboard)`
  padding: 48px;
`

const Summary = (props) => {
  const [location, setLocation] = useState([])
  const [loaded, setLoaded] = useState()
  const [refreshed, refresh] = useState()
  const [showDepartmentModal, toggleDepartmentModal] = useState(false)
  const [showAddAdminModal, toggleAddAdminModal] = useState(false)
  const [newDepartment, setNewDepartment] = useState()
  const [admins, setAdmins] = useState([])
  const [changed, change] = useState()
  const [message, setMessage] = useState(null)
  const [newAdmin, setNewAdmin] = useState({})
  const [selectedDepartments, setSelectedDepartments] = useState([])
  const [showEditAdminModal, toggleEditAdminModal] = useState(false)
  const [editAdmin, setEditAdmin] = useState()
  const [showDeleteDepartmentModal, toggleDeleteDepartment] = useState(false)
  const [removeDepartment, setRemovedDepartment] = useState({})

  function setNewDepartmentName(e){
    setNewDepartment(e.target.value)
  }

  async function addDepartment(){
    const me = await user.get()
    const locationData = await community.get(me.data.facilityId)
    if(!locationData.data.details.departments) locationData.data.details.departments = []
    const department = {
      name: newDepartment,
      archived: false
    }
    locationData.data.details.departments.push(department)
    await community.update(locationData.data)
    toggleDepartmentModal(false)
    refresh(!refreshed)
  }

  async function toggleSigner(admin){
    admin.details.signer = !admin.details.signer

    await user.update(admin)
  }

  function updateField(e){
    const obj = location.details
    obj[e.target.name] = e.target.value
    location.details = obj
    setLocation(location)
  }

  async function updateLocationDetails(){
    const me = await user.get()
    const locationData = await community.get(me.data.facilityId)
    locationData.data.details = location.details
    await community.update(locationData.data)
    setMessage('Community info updated successfully!')
    change(!changed)
  }

  async function inviteAdmin(){
    if(!newAdmin.name || !newAdmin.email || !newAdmin.job_title) {
      setMessage('Please fill out all required fields')
      change(!changed)
    }
    else if(!selectedDepartments.length) {
      setMessage('please select at least one department')
      change(!changed)
    }
    else {
      try{
        const obj = {
          name: newAdmin.name,
          email: newAdmin.email,
          facilityId: location.id,
          access: "facility",
          locked: false,
          facilityName: location.details.name,
          password: "5Kd39adfe11",
          details: {
            signer: true,
            job_title: newAdmin.job_title,
            departments: selectedDepartments
          }
        }
        const invited = await user.inviteAdmin(obj)

        toggleAddAdminModal(false)
        setNewAdmin({})
        setSelectedDepartments([])
        setMessage('Admin successfully invited')
        change(!changed)
        refresh(!refreshed)
      } catch (error) {
        setMessage('Error inviting admin')
        change(!changed)
      }
    }
  }

  function updateAdminField(e){
    newAdmin[e.target.name] = e.target.value
    setNewAdmin(newAdmin)
  }

  function toggleDepartment(department){
    if(selectedDepartments.includes(department.name)) {
      setSelectedDepartments(selectedDepartments.filter(iteration => iteration !== department.name))
    } else {
      selectedDepartments.push(department.name)
      setSelectedDepartments(selectedDepartments)
    }

  }

  async function resendInvite(admin){
    if(admin.invitationCode){
      const email = emailer.generateAdminInviteEmail(admin.email, location.details.name, 'http://app.seniorsign.io', admin.invitationCode, admin.name)

      try {
        await emailer.send(email)
        setMessage('Invite Email sent')
        change(!changed)
      } catch (error){
        setMessage('Error Sending Email')
        change(!changed)
      }
    } else {
      setMessage('User already accepted the invite. If they can\'t remember their password, delete the user then add them again')
      change(!changed)
    }
  }

  async function deleteAdmin(admin){
    const check = window.confirm('Are you sure you want to delete this admin?')
    if(check){
      try {
        await user.remove(admin.id)
        setMessage('Admin deleted successfully')
        change(!changed)
      } catch (error){
        setMessage('Error deleting admin')
        change(!changed)
      }
    }
    refresh(!refreshed)
  }

  async function updateAdmin(){
    try{
      await user.update(editAdmin)
      toggleEditAdminModal(false)
      setMessage('Admin updated successfully')
      refresh(!refreshed)
      change(!changed)
    } catch (error){
      setMessage('Error updating admin')
      refresh(!refreshed)
      change(!changed)
    }
  }

  function toggleEditAdminDepartment(department){
    if(!editAdmin.details) editAdmin.details = {}
    if(!editAdmin.details.departments) editAdmin.details.departments = []
    if(editAdmin.details.departments.includes(department.name)) {
      editAdmin.details.departments = editAdmin.details.departments.filter(iteration => iteration !== department.name)
      setEditAdmin(editAdmin)
    } else {
      editAdmin.details.departments.push(department.name)
      setEditAdmin(editAdmin)
    }
  }

  function editAdminModal(admin){
    setEditAdmin(admin)
    toggleEditAdminModal(true)
  }

  function updateEditAdminField(e){
    if(e.target.name !== 'job_title') {
      editAdmin[e.target.name] = e.target.value
    } else {
      if(!editAdmin.details) editAdmin.details = {}
      editAdmin.details[e.target.name] = e.target.value
    }
    setEditAdmin(editAdmin)
  }

  function generateOptions(admin){
    return [{label: 'Edit', action: () => editAdminModal(admin)}, {label: 'Resend Invite', action: () => resendInvite(admin)}, {label: 'Delete', action: () => deleteAdmin(admin)}]
  }

  function generateRemoveDepartments(){
    const array = []
    for(var i in location.details.departments){
      if(location.details.departments[i].name !== removeDepartment.name){
        const obj = {
          label: location.details.departments[i].name,
          value: location.details.departments[i].name
        }
        array.push(obj)
      }
    }
    return array
  }

  function startRemoveDepartment(department){
    setRemovedDepartment(department)

    toggleDeleteDepartment(true)
  }

  useEffect(() => {
    async function getApplications(){
      try{
        const me = await user.get()
        const communityResponse = await community.get(me.data.facilityId)
        const communityAdmins = await user.listCommunityAdmins(me.data.facilityId, 0)
        setAdmins(communityAdmins.data)
        setLocation(communityResponse.data)
        setLoaded(true)
      } catch {
        props.history.push('/login')
      }
    }

    getApplications()
  }, [refreshed])

  return (
    <main>
      <Header />
      <Row>
        <Sidebar/>

        {loaded ? (
          <Dash>

            <Row alignItems="center" margin={"0 0 16px"}>
              <Text kind="p" weight={800} uppercase>Community Information</Text>
            </Row>

            <Row justify="space-between">
              <Row padding={"0 16px 0 0"}>
                <Box height={"455px"}>
                  <BoxRow padding={"16px 24px"} justify="space-between" alignItems="center">
                    <Text kind="p" weight={800} size={"14px"} uppercase>General Information</Text>
                  </BoxRow>

                  <Row padding={"24px"} direction="column">
                    <Row padding={"0 0 16px"} direction="column">
                      <Input name="name" initialvalue={location.details.name} change={updateField}/>
                      <Label>Community Name</Label>
                    </Row>

                    <Row padding={"0 0 16px"} direction="column">
                      <Input name="business_name" initialvalue={location.details.business_name} change={updateField}/>
                      <Label>Community Business Name</Label>
                    </Row>
                  </Row>

                  <Row padding={"0 24px 24px"} justify="flex-end">
                    <Button onClick={updateLocationDetails}>Save</Button>
                  </Row>
                </Box>
              </Row>

              <Row padding={"0 0 0 16px"}>
                <Box height={"455px"}>
                  <BoxRow padding={"16px 24px"} justify="space-between" alignItems="center">
                    <Text kind="p" weight={800} size={"14px"} uppercase>Contact Information</Text>
                  </BoxRow>

                  <Row padding={"24px"} direction="column">
                    <Row padding={"0 0 16px 0"} direction="column">
                      <Input name="street1" initialvalue={location.details.street1} change={updateField}/>
                      <Label>Street Address</Label>
                    </Row>

                    <Row padding={"0 0 16px"}>
                      <Row minwidth="50%" padding={"0 16px 0 0"} direction="column">
                        <Input name="city" initialvalue={location.details.city} change={updateField}/>
                        <Label>City</Label>
                      </Row>

                      <Row padding={"0 16px 0 0"} direction="column">
                        <Input name="state" initialvalue={location.details.state} change={updateField}/>
                        <Label>State</Label>
                      </Row>

                      <Row direction="column">
                        <Input name="zip" initialvalue={location.details.zip} change={updateField}/>
                        <Label>Zip</Label>
                      </Row>
                    </Row>

                    <Row padding={"0 0 16px"}>
                      <Row padding={"0 16px 0 0"} direction="column">
                        <Input name="phone" mask={"(999) 999-9999"} initialvalue={location.details.phone} change={updateField}/>
                        <Label>Phone Number</Label>
                      </Row>

                      <Row direction="column">
                        <Input name="fax" mask={"(999) 999-9999"} initialvalue={location.details.fax} change={updateField}/>
                        <Label>Fax Number</Label>
                      </Row>
                    </Row>

                    <Row direction="column">
                      <Input name="website" initialvalue={location.details.website} change={updateField}/>
                      <Label>Website</Label>
                    </Row>
                  </Row>

                  <Row padding={"0 24px 24px"} justify="flex-end">
                    <Button onClick={updateLocationDetails}>Save</Button>
                  </Row>
                </Box>
              </Row>
            </Row>

            <Row alignItems="center" margin={"48px 0 16px"}>
              <Text kind="p" weight={800} uppercase>Community Users</Text>
            </Row>

            <Box>
              <BoxRow padding={"16px 24px"} justify="space-between" alignItems="center">
                <Text kind="p" weight={800} size={"14px"} uppercase>Name</Text>

                <Cells>
                  <Cell width={205}>
                    <Text kind="p" center weight={800} size={"14px"} uppercase>Email</Text>
                  </Cell>

                  <Cell width={135}>
                    <Text kind="p" center weight={800} size={"14px"} uppercase>Last Login</Text>
                  </Cell>

                  {/*<Cell width={105}>
                    <Text kind="p" center weight={800} size={"14px"} uppercase>Signer</Text>
                  </Cell>*/}

                  <Cell width={195}>
                    <Text kind="p" center weight={800} size={"14px"} uppercase>Status</Text>
                  </Cell>

                  <Cell width={35}>
                    <div onClick={() => toggleAddAdminModal(true)}>
                      <Add src="icon-add.svg" />
                    </div>
                  </Cell>
                </Cells>
              </BoxRow>

              {admins ? admins.map((admin, index) => (
                <BoxRow padding={"16px 24px"} justify="space-between" alignItems="center">
                  <Link to={"/resident/"}><Text kind="p">{admin.name}</Text></Link>

                  <Cells>
                    <Cell width={205}>
                      <Text kind="p" center color={"#aaa"} size={"12px"}>{(admin.email)}</Text>
                    </Cell>

                    <Cell width={135}>
                      <Text kind="p" center color={"#aaa"} size={"12px"}>{(admin.activity.last_login ? moment(admin.activity.last_login).format('MMM DD, YYYY') : 'N/A')}</Text>
                    </Cell>

                    {/*<Cell width={105}>
                      <Checkbox detect={changed} selected={admin.details ? admin.details.signer : false} onClick={() => toggleSigner(admin)}/>
                    </Cell>*/}

                    <Cell width={195}>
                      <Pill gray>{admin.progress ? admin.progress : 'Active'}</Pill>
                    </Cell>

                    <Cell width={35}>
                      <Dots src="icon-dots.svg" options={generateOptions(admin)} />
                    </Cell>
                  </Cells>
                </BoxRow>
              )) : null}
            </Box>

            <Row alignItems="center" margin={"48px 0 16px"}>
              <Text kind="p" weight={800} uppercase>Departments</Text>
            </Row>

            <Box>
              <BoxRow padding={"16px 24px"} justify="space-between" alignItems="center">
                <Text kind="p" weight={800} size={"14px"} uppercase>Name</Text>

                <div onClick={() => toggleDepartmentModal(true)}>
                  <Add src="icon-add.svg" />
                </div>
              </BoxRow>

              {location.details.departments ? location.details.departments.map((department, index) => (
                <BoxRow padding={"16px 24px"} justify="space-between" alignItems="center">
                  <Text kind="p">{department.name}</Text>

                  <Button onClick={() => startRemoveDepartment(department)} red>Delete</Button>
                </BoxRow>
              )) : null}
            </Box>

            <Modal title={'Add User'} width={700} show={showAddAdminModal} close={() => toggleAddAdminModal(false)}>
              <Row direction="column" padding="48px">
                <Row padding={"0 0 16px 0"} direction="column">
                  <Input name="name" change={updateAdminField}/>
                  <Label color="#4D5864"><Required />Full Name</Label>
                </Row>

                <Row padding={"0 0 16px 0"} direction="column">
                  <Input name="email" change={updateAdminField}/>
                  <Label color="#4D5864"><Required />Email Address</Label>
                </Row>

                <Row padding={"0 0 32px 0"} direction="column">
                  <Input name="job_title" change={updateAdminField}/>
                  <Label color="#4D5864"><Required />Job Title</Label>
                </Row>

                <Row padding={"0 0 32px 0"} direction="column">
                  <Text kind="p" color="#4D5864" weight={700} uppercase>Assigned Departments</Text>

                  {location.details.departments && location.details.departments.map((department, index) => (
                    <Row key={index} padding={'24px 0 0'} alignItems="center">
                      <Checkbox onClick={() => toggleDepartment(department)}/>
                      <Text kind="p" size={'14px'} color="#4D5864">{department.name}</Text>
                    </Row>
                  ))}
                </Row>

                <Row justify="flex-end">
                  <Button onClick={inviteAdmin}>Send Invite</Button>
                </Row>
              </Row>
            </Modal>

            <Modal title={'Edit Admin'} width={700} show={showEditAdminModal} close={() => toggleEditAdminModal(false)}>
              {editAdmin && (
                <Row direction="column" padding="48px">
                  <Row padding={"0 0 16px 0"} direction="column">
                    <Input name="name" initialvalue={editAdmin.name} name="name" change={updateEditAdminField}/>
                    <Label color="#4D5864"><Required />Full Name</Label>
                  </Row>

                  <Row padding={"0 0 16px 0"} direction="column">
                    <Input name="email" initialvalue={editAdmin.email} name="email" change={updateEditAdminField}/>
                    <Label color="#4D5864"><Required />Email Address</Label>
                  </Row>

                  <Row padding={"0 0 32px 0"} direction="column">
                    <Input name="job_title" initialvalue={editAdmin.details && editAdmin.details.job_title} name="job_title" change={updateEditAdminField}/>
                    <Label color="#4D5864"><Required />Job Title</Label>
                  </Row>

                  <Row padding={"0 0 32px 0"} direction="column">
                    <Text kind="p" color="#4D5864" weight={700} uppercase>Assigned Departments</Text>

                    {location.details.departments && location.details.departments.map((department, index) => (
                      <Row key={index} padding={'24px 0 0'} alignItems="center">
                        <Checkbox selected={(editAdmin.details && editAdmin.details.departments && editAdmin.details.departments.includes(department.name))} onClick={() => toggleEditAdminDepartment(department)}/>
                        <Text kind="p" size={'14px'} color="#4D5864">{department.name}</Text>
                      </Row>
                    ))}
                  </Row>

                  <Row justify="flex-end">
                    <Button onClick={updateAdmin}>Update</Button>
                  </Row>
                </Row>
              )}
            </Modal>

            <Modal title={"Add Department"} show={showDepartmentModal} close={() => toggleDepartmentModal(false)}>
              <Row direction="column" padding="24px">
                <Row padding={"32px 0"} direction="column">
                  <Input change={setNewDepartmentName}/>
                  <Label color="#4D5864">Name</Label>
                </Row>

                <Row justify="flex-end">
                  <Button onClick={addDepartment}>Add</Button>
                </Row>
              </Row>
            </Modal>

            <Modal title={"Delete Department"} width={700} show={showDeleteDepartmentModal} close={() => toggleDeleteDepartment(false)}>
              {generateRemoveDepartments().length > 0 ? (
                <Row direction="column" padding="32px 24px 24px">
                  <Text kind="p" color="#4D5864">Select a Department to move all residents assigned to {removeDepartment.name} to</Text>
                  <Row padding={"16px 0 32px"} direction="column">
                    <Select options={generateRemoveDepartments()} />
                    <Label color="#4D5864">Migrate to Department</Label>
                  </Row>

                  <Row justify="flex-end">
                    <Button red onClick={addDepartment}>Migrate and Delete</Button>
                  </Row>
                </Row>
              ) : (
                <Row direction="column" padding="48px">
                  <Text kind="p" color="#4D5864" weight={700} center>You need to have more than one Department before deleting this one</Text>
                </Row>
              )}

            </Modal>

            <MessageBar listen={changed} message={message}/>
            <HelpButton />
          </Dash>
        ) : <Loading />}
      </Row>

    </main>
  )
}

export default Summary
