import React, { useState, useEffect } from "react"
import styled from "styled-components"
import application from '../util/application'
import communities from '../util/community'
import user from '../util/user'
import queryString from 'query-string'
import { HelpButton, Header, Sidebar, Row, Text, Box, Wrapper, Link, Button, Input, BoxRow, Cells, Cell, Dashboard, Dots, Label, Select, Loading, MessageBar } from '../components'

const Signature = styled(Input)`
  border: 1px solid #ddd;
  background: white;
  font-size: 32px;
  padding: 24px 16px 16px;
  font-family: 'La Belle Aurore', cursive;
  border-radius: 8px;
  color: #8A96A1;
  margin-right: 16px;
  width: 100%;
  height: 85px;
  line-height: 1em;
`

const Initials = styled(Input)`
  border: 1px solid #ddd;
  background: white;
  font-size: 32px;
  padding: 24px 16px 16px;
  font-family: 'La Belle Aurore', cursive;
  border-radius: 8px;
  letter-spacing: 16px;
  color: #8A96A1;
  width: 100%;
  height: 85px;
`

const Summary = (props) => {
  const [location, setLocation] = useState([])
  const [loaded, setLoaded] = useState()
  const [admin, setAdmin] = useState({})
  const [community, setCommunity] = useState({})
  const [message, setMessage] = useState()
  const [changed, change] = useState(false)

  function updateField(e){
    if(e.target.name == 'job_title' || e.target.name == 'signature' || e.target.name == 'initials'){
      admin.details[e.target.name] = e.target.value
    } else {
      admin[e.target.name] = e.target.value
    }

    setAdmin(admin)
  }

  async function updateUser(){
    if(admin.password && admin.password.length < 6) {
      alert('please enter a password longer than 6 characters')
    } else {
      try{
        await user.update(admin)
        if(admin.password) {
          setMessage('Password Changed, logging out now')
          change(!changed)
          window.setTimeout(() => props.history.push('/login'), 2000)
        } else {
          setMessage('User updated successfully')
          change(!changed)
        }
      } catch {
        setMessage('Error updating user')
        change(!changed)
      }
    }
  }

  function getDepartmentSelectList(){
    const array = []
    if(admin.details && admin.details.departments){
      for(var i in admin.details.departments){
        const obj = {
          label: admin.details.departments[i],
          value: admin.details.departments[i]
        }
        array.push(obj)
      }
    }
    return array
  }

  function setDefaultDepartment(department){
    admin.details.default_department = department
    setAdmin(admin)
  }

  function getInitials(){
    return admin.name.match(/\b(\w)/g).join('')
  }

  useEffect(() => {
    async function getApplications(){
      try{
        const me = await user.get()
        const location = await communities.get(me.data.facilityId)
        setAdmin(me.data)
        setCommunity(location.data)
        setLoaded(true)
      } catch {
        props.history.push('/login')
      }
    }

    getApplications()
  }, [])

  return (
    <main>
      <Header />
      <Row>
        <Sidebar/>

        {loaded ? (
          <Dashboard padding={"48px"}>

            <Row alignItems="center" margin={"0 0 32px"}>
              <Text kind="p" weight={800} uppercase>My Settings</Text>
            </Row>

            <Row justify="space-between">
              <Row padding={"0 16px 0 0"}>
                <Box height={"330px"}>
                  <BoxRow padding={"16px 24px"} justify="space-between" alignItems="center">
                    <Text kind="p" weight={800} size={"14px"} uppercase>Contact Information</Text>
                  </BoxRow>

                  <Row padding={"24px"} direction="column">
                    <Row justify="space-between">
                      <Row padding={"0 8px 0 0"} direction="column">
                        <Input name="name" initialvalue={admin.name} change={updateField}/>
                        <Label>Full Name</Label>
                      </Row>
                    </Row>

                    <Row justify="space-between" direction="column" padding={"16px 0"}>
                      <Input name="job_title" initialvalue={admin.details && admin.details.job_title} change={updateField}/>
                      <Label>Job Title</Label>
                    </Row>
                  </Row>

                  <Row padding={"0 24px 24px"} justify="flex-end">
                    <Button onClick={updateUser}>Save</Button>
                  </Row>
                </Box>
              </Row>

              <Row padding={"0 0 0 16px"}>
                <Box height={"330px"}>
                  <BoxRow padding={"16px 24px"} justify="space-between" alignItems="center">
                    <Text kind="p" weight={800} size={"14px"} uppercase>Login Information</Text>
                  </BoxRow>

                  <Row padding={"24px"} direction="column">
                    <Row padding={"0 0 16px 0"} direction="column">
                      <Input name="email" initialvalue={admin.email} change={updateField} />
                      <Label>Email</Label>
                    </Row>

                    <Row padding={"0 0 16px 0"} direction="column">
                      <Input name="password" initialvalue={null} change={updateField}/>
                      <Label>New Password</Label>
                    </Row>
                  </Row>

                  <Row padding={"0 24px 24px"} justify="flex-end">
                    <Button onClick={updateUser}>Save</Button>
                  </Row>
                </Box>
              </Row>
            </Row>

            <Row justify="space-between" padding={"32px 0 0"}>
              <Row padding={"0 16px 0 0"}>
                <Box>
                  <BoxRow padding={"16px 24px"} justify="space-between" alignItems="center">
                    <Text kind="p" weight={800} size={"14px"} uppercase>User Information</Text>
                  </BoxRow>

                  <Row padding={"24px"} direction="column">
                    <Row direction="column">
                      <Row direction="column" padding={"0 0 16px"}>
                        <Select options={getDepartmentSelectList()} initialvalue={admin.details.default_department ? admin.details.default_department : null} change={e => setDefaultDepartment(e)}/>
                        <Label>Default Department</Label>
                      </Row>
                    </Row>
                  </Row>

                  <Row padding={"0 24px 24px"} justify="flex-end">
                    <Button onClick={updateUser}>Save</Button>
                  </Row>
                </Box>
              </Row>

              <Row padding={"0 0 0 16px"}>
                <Box>
                  <BoxRow padding={"16px 24px"} justify="space-between" alignItems="center">
                    <Text kind="p" weight={800} size={"14px"} uppercase>Signature</Text>
                  </BoxRow>

                  <Row padding={"24px"} justify="space-between">
                    <Row direction="column" width={"65%"}>
                      <Signature initialvalue={admin.details.signature ? admin.details.signature : admin.name} name="signature" change={updateField}></Signature>
                      <Label>Signature</Label>
                    </Row>

                    <Row direction="column" width={"30%"}>
                      <Initials initialvalue={admin.details.initials ? admin.details.initials : getInitials()} name="initials" change={updateField}></Initials>
                      <Label>Initials</Label>
                    </Row>
                  </Row>

                  <Row padding={"0 24px 24px"} justify="flex-end">
                    <Button onClick={updateUser}>Save</Button>
                  </Row>
                </Box>
              </Row>
            </Row>

            <MessageBar listen={changed} message={message}/>
            <HelpButton />
          </Dashboard>
        ) : <Loading />}
      </Row>

    </main>
  )
}

export default Summary
