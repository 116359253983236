import React, { useState, useEffect } from 'react'
import { FinishedAgreements, Row, Button } from './index'
import applications from '../util/application'
import emails from '../util/emails'
import user from '../util/user'

const Summary = (props) => {
  const [application, setApplication] = useState()

  async function submitForReview(){
    try{
      const _latest = await applications.get(application.id)
      _latest.data.progress.current = 'In Review'
      await applications.update(_latest.data)

      if(application.myInfo.application_info.manager_name) {
        const notify = await user.notifyAssignee(application.myInfo.application_info.manager_name, application)
        window.location.href = '/dashboard'
      } else {
        window.location.href = '/dashboard'
      }
    } catch(error){
      console.log(error)
      alert('Error submitting for review')
    }
  }

  useEffect(() => {
    console.log(props.application)
    setApplication(props.application)
  }, [props.application])

  if(!application) return null
  return (
    <Row direction="column">
      <FinishedAgreements application={application} downloadAll={props.downloadAll}/>

      {application.progress.current !== 'complete' && (
        <Row width="980px" margin="24px auto" justify="flex-end">
          <Button onClick={props.prevStep}>Back</Button>
          &nbsp;&nbsp;
          {application.progress.current == 'In Review'
          ? <Button disabled>Application in Reivew</Button>
          : <Button onClick={submitForReview}>Submit for Review</Button>}
        </Row>
      )}
    </Row>
  )
}

export default Summary
