import React from 'react'
import Dropzone from 'react-dropzone'
import styled from "styled-components"
import { Text, Button } from './index'

const Attachments = styled.div`
  border: 2px dashed #bbb;
  border-radius: 8px;
  width: 100%;
  padding: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
`

const UploadIcon = styled.img`
  width: 75px;
`

 export default props => {
   return (
     <Dropzone onDrop={acceptedFiles => props.onDrop(acceptedFiles)}>
       {({getRootProps, getInputProps}) => (
         <Attachments {...getRootProps()}>
            <input {...getInputProps()} />
           <UploadIcon src="/icon-upload.svg" />
           <Text margin={"16px 0"} kind="p" size="18px" weight="700">Drag &amp; Drop Files</Text>
           <Button type="button">Browse Computer</Button>
         </Attachments>
       )}
     </Dropzone>
   )
 }
