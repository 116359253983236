import axios from "axios"
import urls from './urls'

function get(id){
  return axios.get(urls.api + 'facility/' + id, {'headers':{
    'X-Auth-Token': localStorage.token,
    'X-Password': localStorage.pass
  }})
}

function update(community){
  return axios.patch(urls.api + 'facility/', community, {'headers':{
    'X-Auth-Token': localStorage.token,
    'X-Password': localStorage.pass
  }})
}

const functions = {
  get,
  update
}

export default functions
