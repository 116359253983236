import React from 'react'
import styled from "styled-components"

const Required = styled.img`
  width: 8px;
  margin-right: 4px;
  margin-bottom: 2px;
`

export default props => <Required src="/icon-required.svg" />
