import React, { useEffect, useState } from 'react'
import { Row, Text, Box, BoxRow, Button, MessageBar, Input, Required, Label } from './index'
import applications from '../util/application'

const MyInformation = (props) => {
  const [application, setApplication] = useState()
  const [validated, setValidated] = useState()

  function updateField(e, area){
    application.myInfo[area][e.target.name] = e.target.value
    setApplication(application)
    validate()
  }

  function validate(app){
    if(application) app = application
    let valid = false
    if(
      app.myInfo.contact_info.first_name &&
      app.myInfo.contact_info.last_name &&
      app.myInfo.contact_info.email &&
      app.myInfo.contact_info.phone_number
    ) {
      valid = true
    }

    setValidated(valid)
  }

  async function updateAndNext(){
    const latest = await applications.get(application.id)
    latest.data.myInfo = application.myInfo
    await applications.update(latest.data)
    props.nextStep()
  }

  useEffect(() => {
    setApplication(props.application)
    validate(props.application)
  }, [props.application])

  if(!application) return null
  return(
    <Row direction="column">
      <Row>
        <Box width="600">
          <BoxRow padding="24px">
            <Text kind="p" weight="800" uppercase>Resident Information</Text>
          </BoxRow>
          <BoxRow padding="24px" direction="column">
            <Row justify="space-between" margin="0 0 16px">
              <Row direction="column" margin="0 16px 0 0">
                <Input name="first_name" initialvalue={application.myInfo.contact_info.first_name} change={e => updateField(e, 'contact_info')} />
                <Label><Required /> First Name</Label>
              </Row>
              <Row width="20%" direction="column" margin="0 16px 0 0">
                <Input name="middle_name" initialvalue={application.myInfo.contact_info.middle_name} change={e => updateField(e, 'contact_info')} />
                <Label>Middle Initial</Label>
              </Row>
              <Row direction="column">
                <Input name="last_name" initialvalue={application.myInfo.contact_info.last_name} change={e => updateField(e, 'contact_info')} />
                <Label><Required /> Last Name</Label>
              </Row>
            </Row>

            <Row justify="space-between" margin="0 0 16px">
              <Row width="25%" direction="column" margin="0 16px 0 0">
                <Input mask="(999) 999-9999" name="phone_number" initialvalue={application.myInfo.contact_info.phone_number} change={e => updateField(e, 'contact_info')} />
                <Label><Required /> Phone Number</Label>
              </Row>
              <Row width="25%" direction="column" margin="0 16px 0 0">
                <Input mask="(999) 999-9999" name="mobile_number" initialvalue={application.myInfo.contact_info.mobile_number} change={e => updateField(e, 'contact_info')} />
                <Label>Mobile Number</Label>
              </Row>
              <Row direction="column">
                <Input name="email" initialvalue={application.myInfo.contact_info.email} change={e => updateField(e, 'contact_info')} />
                <Label><Required /> Email Address</Label>
              </Row>
            </Row>

            <Row margin="16px 0">
              <Text kind="p" weight="800" uppercase>Personal Info</Text>
            </Row>

            <Row justify="space-between" margin="0 0 16px">
              <Row width="25%" direction="column" margin="0 16px 0 0">
                <Input mask="99/99/9999" name="date_of_birth" initialvalue={application.myInfo.personal_info.date_of_birth} change={e => updateField(e, 'personal_info')} />
                <Label>Date of Birth</Label>
              </Row>
              <Row width="25%" direction="column" margin="0 16px 0 0">
                <Input mask="999-99-9999" name="ssn" initialvalue={application.myInfo.personal_info.ssn} change={e => updateField(e, 'personal_info')} />
                <Label>SSN</Label>
              </Row>
              <Row direction="column">
                <Input disabled name="due_date" initialvalue={application.myInfo.application_info.unit_number} change={e => updateField(e, 'application_info')} />
                <Label> Unit Number</Label>
              </Row>
            </Row>

            <Row margin="16px 0">
              <Text kind="p" weight="800" uppercase>Home Address</Text>
            </Row>

            <Row justify="space-between" margin="0 0 16px">
              <Row direction="column">
                <Input name="street_address" initialvalue={application.myInfo.contact_info.street_address} change={e => updateField(e, 'contact_info')}/>
                <Label> Street Address</Label>
              </Row>
            </Row>

            <Row justify="space-between" margin="0 0 16px">
              <Row direction="column" margin="0 16px 0 0">
                <Input  name="city" initialvalue={application.myInfo.contact_info.city} change={e => updateField(e, 'contact_info')} />
                <Label>City</Label>
              </Row>
              <Row width="20%" direction="column" margin="0 16px 0 0">
                <Input name="state" initialvalue={application.myInfo.contact_info.state} change={e => updateField(e, 'contact_info')} />
                <Label>State</Label>
              </Row>
              <Row width="20%" direction="column">
                <Input name="zip" initialvalue={application.myInfo.contact_info.zip} change={e => updateField(e, 'contact_info')} />
                <Label>Zip Code</Label>
              </Row>
            </Row>
          </BoxRow>
        </Box>
      </Row>

      <Row width="600px" margin="32px auto 0" justify="flex-end">
        {validated ? <Button onClick={updateAndNext}>Next</Button> : <Button disabled>Next</Button>}
      </Row>
    </Row>
  )
}

export default MyInformation
