import React from 'react'
import styled from "styled-components"

const Close = styled.img`
  width: 30px;
  margin-bottom: -7px;
  cursor: pointer;
`

export default props => <Close {...props} src="/icon-close.svg" />
