import React, { useEffect, useState, useRef} from 'react'
import styled from "styled-components"
import {Row, Text} from './index'

const Menu = styled.img`
  width: 30px;
  margin-bottom: -7px;
  cursor: pointer;
  position: relative;
  z-index: 6;
`

const Wrapper = styled.div`
  position: relative;
  z-index: ${props => props.show ? 9 : 8};
`

const List = styled.div`
  background: white;
  position: absolute;
  box-shadow: 0px 0px 8px rgba(0,0,0,0.2);
  border-radius: 2px;
  width: 200px;
  top: -7px;
  right: -5px;
  z-index: 5;
  overflow: hidden;
  transition-duration: height 0.3s;
  height: ${props => props.show ? 'auto' : '0px'};
`

const Item = styled.div`
  padding: 12px 16px;
  cursor: pointer;
  font-size: 13px;
  background: white;
  transition-duration: 0.3s;
  font-weight: 700;
  &:hover {
    background: #eee;
  }
`

const Title = styled(Row)`
  border-bottom: 1px solid #ddd;
`

export default props => {
  const [showMenu, setShowMenu] = useState(false)
  const node = useRef();

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      return;
    }

    setShowMenu(false)
  };

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  return (
    <Wrapper ref={node} show={showMenu}>
      <Menu src="/icon-menu.svg" onClick={() => setShowMenu(!showMenu)} />
      {props.options && showMenu && (
        <List show={showMenu}>
          <Title padding={"13px 16px"}>
            <Text kind="p" size={"14px"} weight={700} color={"black"}>Actions</Text>
          </Title>
          {props.options.map((item, index) => (
            <Item key={index} onClick={() => {item.action(); setShowMenu(false)}}>{item.label}</Item>
          ))}
        </List>
      )}
    </Wrapper>
  )
}
