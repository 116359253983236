import React, { useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";
import agreements from "../util/agreements";
import applications from "../util/application";
import esign from "../util/esign";
import users from "../util/user";
import {
  Header,
  Sidebar,
  Row,
  Text,
  Box,
  DocWrapper,
  Link,
  Button,
  Input,
  BoxRow,
  Cells,
  Cell,
  Dashboard,
  Dots,
  Add,
  Loading,
  Page,
  Modal,
  MobileModal,
} from "../components";
import scrollTo from "animated-scroll-to";
import PinchZoom from "../util/pinch-zoom";

const Actions = styled(Row)`
  button {
    margin-left: 16px;
  }
`;

const Marker = styled.div`
  width: 60px;
  height: 40px;
  position: absolute;
  transition-duration: 0.5s;
  top: ${(props) => (props.position ? props.position : "0")}
  left: -34px;
  background-size: cover !important;
  background: url('/icon-marker.svg');
  z-index: 2;
  display: flex;
  padding: 5px 0 0 18px;
  align-items: center;
  color: white;
  font-weight: 700;
`;

const FindNext = styled(Button)`
  @media (max-width: 950px) {
    visibility: hidden;
    display: none;
  }
  position: fixed;
  top: 110px;
  left: 50px;
  z-index: 9;
`;

const WorkArea = styled.div`
  position: relative;
  margin: 10px auto;
  max-width: ${({ size }) =>
    size && size.width ? size.width * 72 + "px" : "816px"};
`;

const MobileButtons = styled.div`
  @media (min-width: 768px) {
    visibility: hidden;
  }
  position: fixed;
  top: 110px;
  left: 50px;
  z-index: 9;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1em;
  * {
    margin-right: 1em;
  }
`;

// async function getZoomLevel() {
//   const screenDetails = await getScreenDetails();
//   const zoomLevel =  window.devicePixelRatio / screenDetails.currentScreen.devicePixelRatio;
//   console.log(`Zoom level: ${zoomLevel * 100}%`);
//   return zoomLevel;
// }

const MobileBanner = styled.div`
  @media (min-width: 950px) {
    display: none;
  }
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background: #00bcd7;
  z-index: 9;
  display: flex;
  justify-content: space-around;
  align-items: center;
  touch-action: manipulation;
  user-select: none;
`;

const MobileLockedButton = styled(Button)`
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff008c;
  background: #ffcce8;
  outline: 1px solid #ff008c;
  height: 48px;
  width: 104px;
  margin-right: 16px;
  border-raduis: 8px;
`;

const MobileSaveAndExitButton = styled(Button)`
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: transparent;
  outline: 1px solid #ffffff;
  height: 48px;
  width: 104px;
  margin-left: 16px;
  border-raduis: 8px;
`;

const MobileFinishButton = styled(Button)`
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: #94e301;
  height: 48px;
  width: 104px;
  margin-right: 16px;
  border-raduis: 8px;
`;

const MobileFieldCounter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  width: Hug (33px);
  height: Fixed (16px);

  & > .fieldContainerTop {
    font-size: 12px;
    font-weight: 600;
    line-height: 16.37px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
    width: 33px;
    height: 16px;
    top: 3px;

  }
  & > .fieldContainerBottom {
    font-size: 16px;
    font-weight: 800;
    line-height: 22px;
    letter-spacing: 2px;
    text-align: center;
    color: #ffffff;
  }
  
}
`;

const MobileFieldCounterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  width: 100%;
`;

const MobileBannerArrowForward = styled.div`
  background: url("/icon-arrow-white.svg");
  background-size: contain;
  transform: rotate(-90deg);
  width: 16px;
  height: 10px;
  margin-left: 16px;
  border-radius: 4px;
`;

const MobileBannerArrowBackward = styled.div`
  background: url("/icon-arrow-white.svg");
  background-size: contain;
  transform: rotate(90deg);
  width: 16px;
  height: 10px;
  margin-right: 16px;
  border-radius: 4px;
`;

const Signer = (props) => {
  const [agreement, setAgreement] = useState();
  const [update, setUpdate] = useState(false);
  const [prefills, setPrefills] = useState();
  const [communitySigner, setCommunitySigner] = useState();
  const [requiredCount, setRequiredCount] = useState();
  const [markerPosition, setMarkerPosition] = useState();
  const [nextAgreement, setNextAgreement] = useState();
  const [showRemainingRequiredModal, setShowRemainingRequiredModal] =
    useState();
  const [showMobileSignModal, setShowMobileSignModal] = useState(false);
  const [ipaddress, setipaddress] = useState();

  const [fields, setFields] = useState([]);

  useEffect(() => {}, [agreement]);

  async function saveAndExit() {
    if (!agreement.auditTrail) agreement.auditTrail = { history: [] };
    if (!agreement.auditTrail.history) agreement.auditTrail.history = [];
    agreement.auditTrail.history.push({
      action: "Fields updated and saved",
      timestamp: new Date(),
      ipAddress: ipaddress,
    });
    await agreements.update(agreement);
    await updatePrefills();
    props.history.push(`/application/${props.match.params.application}`);
  }

  const [isFinishing, setIsFinishing] = useState(false)

  async function finish() {
    setIsFinishing(true)
    if (requiredCount > 0) {
      alert("Fill out all required fields");
    } else {
      if (!agreement.auditTrail) agreement.auditTrail = { history: [] };
      if (!agreement.auditTrail.history) agreement.auditTrail.history = [];
      agreement.auditTrail.history.push({
        action: "Finished and locked",
        timestamp: new Date(),
        ipAddress: ipaddress,
      });
      await agreements.update(agreement);

      const latestApplication = await applications.get(
        props.match.params.application
      );
      const currentAgreement =
        latestApplication.data.agreements.resident.filter(
          (item) => item.agreement_id == props.match.params.id
        );
      if (props.match.params.filler == "All") {
        for (var i in currentAgreement[0].signatures) {
          currentAgreement[0].signatures[i].signed = true;
        }
      }
      if (props.match.params.filler == "Community")
        currentAgreement[0].signatures.Community.signed = true;
      if (props.match.params.filler == "Resident")
        currentAgreement[0].signatures.Resident.signed = true;
      if (props.match.params.filler == "Manager")
        currentAgreement[0].signatures.Manager.signed = true;

      for (var i in latestApplication.data.agreements.resident) {
        if (
          latestApplication.data.agreements.resident[i].agreement_id ==
          props.match.params.id
        )
          latestApplication.data.agreements.resident[i] = currentAgreement[0];
      }
      latestApplication.data.progress.current = "In Progress";

      await applications.update(latestApplication.data);
      props.history.push(`/application/${props.match.params.application}`);
      setIsFinishing(true)
    }
  }

  function addSecondaryPrefills() {}

  async function next() {
    if (requiredCount > 0) {
      if (!agreement.auditTrail) agreement.auditTrail = {};
      await agreements.update(agreement);
      setShowRemainingRequiredModal(true);
    } else {
      const latestApplication = await applications.get(
        props.match.params.application
      );
      const currentAgreement =
        latestApplication.data.agreements.resident.filter(
          (item) => item.agreement_id == props.match.params.id
        );
      if (props.match.params.filler == "All") {
        for (var i in currentAgreement[0].signatures) {
          currentAgreement[0].signatures[i].signed = true;
        }
      }
      if (props.match.params.filler == "Community")
        currentAgreement[0].signatures.Community.signed = true;
      if (props.match.params.filler == "Resident")
        currentAgreement[0].signatures.Resident.signed = true;
      if (props.match.params.filler == "Manger")
        currentAgreement[0].signatures.Manager.signed = true;

      for (var i in latestApplication.data.agreements.resident) {
        if (
          latestApplication.data.agreements.resident[i].agreement_id ==
          props.match.params.id
        )
          latestApplication.data.agreements.resident[i] = currentAgreement[0];
      }

      await applications.update(latestApplication.data);

      window.location.href =
        "/sign/" +
        nextAgreement +
        "/" +
        props.match.params.application +
        "/" +
        props.match.params.filler;
    }
  }

  function updateAgreement(page, pageNumber) {
    agreement.pages.pages[pageNumber] = page;
    setAgreement(agreement);
    setMarker();
    updateFields();
  }

  function checkGroups(_field) {
    let complete = true;
    if (
      _field.groupname &&
      _field.grouprequirements &&
      _field.filler == props.match.params.filler
    ) {
      let _fields = [];
      agreement.pages.pages.filter((newPage) => {
        const _groupFields = newPage.fields.filter(
          (newfield) => newfield.groupname == _field.groupname
        );
        _fields = [..._groupFields, ..._fields];
      });

      if (_field.grouprequirements == "Only_One") {
        const count = _fields.filter((check) => check.value);
        if (count.length === 0) complete = false;
      }

      if (_field.grouprequirements == "At_least_one") {
        const count = _fields.filter((check) => check.value);
        if (count.length < 1) complete = false;
      }

      if (_field.grouprequirements == "More_than_one") {
        const count = _fields.filter((check) => check.value);
        if (count.length < 2) complete = false;
      }
    }
    return complete;
  }

  function setMarker(scroll) {
    let count = 0;
    let firstMarker = false;
    let groups = [];
    
    for (var i in agreement.pages.pages) {
      for (var j in agreement.pages.pages[i].fields) {//.filter((field) => { return field.filler == props.match.params.filler || props.match.params.filler == "All" })) {
        const field = agreement.pages.pages[i].fields[j];
        // if (!checkGroups(field)) {
        //   if (!(groups.indexOf(field.groupname) !== -1)) {
        //     groups.push(field.groupname);
        //     count++;
        //     if (!firstMarker) {
        //       firstMarker = true;
        //       findPosition(field.fieldid, scroll);
        //     }
        //   }
        // } else 
        if (
          (field.isrequired || field.isrequired == "true") &&
          field.isrequired !== "false" &&
          field.isrequired !== "undefined" &&
          field.isrequired !== "null" &&
          !field.value &&
          (field.filler == props.match.params.filler || props.match.params.filler == "All")
          // (field.prefill ? prefills && !prefills[field.prefill] : true)
        ) {
          console.log(field)
          count++;

          if (!firstMarker) {
            firstMarker = true;
            findPosition(field.fieldid, scroll);
          }
        }
      }
      setRequiredCount(count);
    }
  }

  function findPosition(id, scroll) {
    const field = document.getElementById(id);
    setMarkerPosition(getCoords(field).top - 130 + "px");
    if (scroll) {
      scrollTo(getCoords(field).top - 130 - 300, {
        cancelOnUserAction: true,
        speed: 1500,
      });
    }
  }

  function getCoords(elem) {
    // crossbrowser version
    var box = elem.getBoundingClientRect();

    var body = document.body;
    var docEl = document.documentElement;

    var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    var clientTop = docEl.clientTop || body.clientTop || 0;
    var clientLeft = docEl.clientLeft || body.clientLeft || 0;

    var top = box.top + scrollTop - clientTop;
    var left = box.left + scrollLeft - clientLeft;

    return { top: Math.round(top), left: Math.round(left) };
  }

  function validateCheckboxs(fieldId, groupname) {
    let latest = { ...agreement };
    for (var i in latest.pages.pages) {
      for (var j in latest.pages.pages[i].fields) {
        if (latest.pages.pages[i].fields[j].type == "checkbox") {
          if (
            latest.pages.pages[i].fields[j].groupname == groupname &&
            latest.pages.pages[i].fields[j].fieldid !== fieldId
          ) {
            latest.pages.pages[i].fields[j].value = false;
          }
          if (latest.pages.pages[i].fields[j].fieldid == fieldId) {
            latest.pages.pages[i].fields[j].value = true;
          }
        }
      }
    }
    setAgreement(latest);
    setUpdate(!update);
  }

  async function updatePrefills() {
    const _prefills = agreement.pages.pages
      .map((_page) => {
        return _page.fields;
      })
      .flat()
      .filter(
        (_field) =>
          _field.prefill &&
          _field.prefill !== "null" &&
          _field.prefill !== "undefined"
      );
    await esign.addPrefills(_prefills, props.match.params.application);
  }

  function getNextAgreement(application) {
    let unFinishedAgreements;
    if (props.match.params.filler == "All")
      unFinishedAgreements = application.agreements.resident.filter(
        (item) => !applications.allSignaturesComplete(item.signatures)
      );
    if (props.match.params.filler == "Community")
      unFinishedAgreements = application.agreements.resident.filter((item) =>
        applications.communityTurn(item.signatures)
      );
    if (props.match.params.filler == "Resident")
      unFinishedAgreements = application.agreements.resident.filter((item) =>
        applications.residentTurn(item.signatures)
      );
    for (var i in unFinishedAgreements) {
      if (unFinishedAgreements[i].agreement_id == props.match.params.id) {
        if (
          unFinishedAgreements.length > i &&
          unFinishedAgreements[parseInt(i) + 1]
        )
          setNextAgreement(unFinishedAgreements[parseInt(i) + 1].agreement_id);
      }
    }
  }

  async function updateAuditTrail(object) {
    if (!agreement.auditTrail) agreement.auditTrail = { history: [] };
    if (!agreement.auditTrail.history) agreement.auditTrail.history = [];
    agreement.auditTrail.history.push(object);
    await agreements.update(agreement);
  }

  function assignAgreementAndPrefills(_prefills, _agreement) {
    _agreement.pages.pages = _agreement.pages.pages.filter((page) =>
      page.fields.filter((field) => {
        if (field.prefill in _prefills) {
          //Added signature check and initials check for bug that wouldn't allow clear on save and exit 10/2/2023
          if (
            !field.value &&
            field.type !== "signature" &&
            field.type !== "initials"
          )
            field.value = _prefills[field.prefill];
        }
      })
    );

    setAgreement(_agreement);
  }

  useEffect(() => {
    async function init() {
      try {
        const me = await users.get();
        const _ip = await users.getIpAddress();
        const agreementLatest = await agreements.get(props.match.params.id);
        const application = await applications.get(
          props.match.params.application
        );
        const _prefills = esign.parsePrefills(application.data.myInfo);

        // console.log(agreementLatest.data);

        setipaddress(_ip.data.ipaddress);
        getNextAgreement(application.data);
        setCommunitySigner(me.data);
        setPrefills(_prefills);
        assignAgreementAndPrefills(_prefills, agreementLatest.data);
      } catch (error) {
        console.log(error);
        //props.history.push('/login')
      }
    }

    init();
  }, [props.match.params.id]);

  const updateFields = () => {
    const _fields = [];

    agreement.pages.pages.filter((newPage) => {
      newPage.fields.forEach((field) => {
        if (field.filler === "Resident") {
          _fields.push({
            id: field.fieldid,
            required:
              field.isrequired && field.isrequired === "true" ? true : false,
            filled: field.value ? true : false,
          });
        }
      });

      setFields(_fields);
    });
  };

  useEffect(() => {
    //If the user screen is smaller than 950px then show the mobile modal
    if (window.innerWidth < 950) {
      setShowMobileSignModal(true);
    }
  }, []);

  useEffect(() => {
    if (agreement) setMarker();
    //If the fields have not been set then set them. This is contingent on having an agreement set
    if (agreement && fields.length === 0) {
      updateFields();
    }
  }, [agreement]);

  return (
    <main
      style={{
        overflowX: "scroll",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ display: "inline-block" }}>
        <Header>
          <Actions justify="flex-end">
            {(fields
              .filter((field) => field.required)
              .every((field) => field.filled) ||
              fields.filter((field) => field.required).length === 0) ? (
              <>
                <Button secondary onClick={saveAndExit}>
                  Save & Exit
                </Button>
                <Button onClick={finish} disabled={isFinishing}>Finish</Button>
              </>
            ) : (
              <Button secondary onClick={saveAndExit}>
                Save & Exit
              </Button>
            )}
          </Actions>

          <MobileBanner>
            <MobileSaveAndExitButton onClick={saveAndExit}>
              Save & exit
            </MobileSaveAndExitButton>

            <MobileFieldCounterContainer>
              {/* <MobileBannerArrowBackward /> */}
              <MobileFieldCounter>
                <Text className="fieldContainerTop">FILLED</Text>
                <Text className="fieldContainerBottom">
                  {
                    fields.filter((field) => {
                      return field.filled;
                    }).length
                  }
                  /{fields.length}{" "}
                </Text>
              </MobileFieldCounter>
              {/* <MobileBannerArrowForward /> */}
            </MobileFieldCounterContainer>

            {fields.filter((field) => field.required && !field.filled).length >
              0 && (
              <MobileLockedButton>
                {
                  fields.filter((field) => field.required && !field.filled)
                    .length
                }{" "}
                required
              </MobileLockedButton>
            )}

            {(fields
              .filter((field) => field.required)
              .every((field) => field.filled) ||
              fields.filter((field) => field.required).length === 0) && (
              <MobileFinishButton onClick={finish} disabled={isFinishing}>Finish</MobileFinishButton>
            )}
          </MobileBanner>
        </Header>

        {agreement && (
          <div>
            <ButtonGroup>
              {requiredCount !== 0 && (
                <FindNext secondary onClick={setMarker}>
                  Next
                </FindNext>
              )}

              {/* <MobileButtons> */}
                {/* <Button secondary onClick={setMarker}>
                  Next
                </Button> */}
                {/* {requiredCount === 0 ? (
                  <>
                    <Button secondary onClick={saveAndExit}>
                      Save & Exit
                    </Button>
                    <Button onClick={finish}>Finish</Button>
                  </>
                ) : (
                  <Button secondary onClick={saveAndExit}>
                    Save & Exit
                  </Button>
                )} */}
              {/* </MobileButtons> */}
            </ButtonGroup>
            <DocWrapper>
              <div id="esign-doc" className="esign-doc-wrapper">
                <WorkArea size={agreement.details.size} id="zoom-wrapper">
                  {requiredCount && requiredCount > 0 ? (
                    <Marker position={markerPosition}>{requiredCount}</Marker>
                  ) : null}

                  {agreement.pages &&
                    agreement.pages.pages.map((item, index) => {
                      return (
                        <Page
                          key={index}
                          pageNumber={index}
                          listen={update}
                          page={item}
                          size={agreement.details.size}
                          prefills={prefills}
                          updateAgreement={updateAgreement}
                          validateCheckboxs={validateCheckboxs}
                          communitySigner={communitySigner}
                          updateAuditTrail={updateAuditTrail}
                          ipaddress={ipaddress}
                          {...props}
                        />
                      );
                    })}
                </WorkArea>
              </div>
            </DocWrapper>

            <Modal
              title="Agreement Incomplete"
              show={showRemainingRequiredModal}
              close={() => setShowRemainingRequiredModal(false)}
            >
              <Row padding="24px" justify="center">
                <Text kind="p" color="#4D5864" center>
                  There are still required fields to fill out, are you sure you
                  want to go to the next agreement?
                </Text>
              </Row>
              <Actions
                padding="0 24px 24px"
                alignItems="center"
                justify="flex-end"
              >
                <Button
                  secondary
                  onClick={() => setShowRemainingRequiredModal(false)}
                >
                  Cancel
                </Button>
                <a
                  href={`/sign/${nextAgreement}/${props.match.params.application}/${props.match.params.filler}/${props.match.params.source}`}
                >
                  <Button>Save & Next</Button>
                </a>
              </Actions>
            </Modal>

            <MobileModal
              saveAndExit={saveAndExit}
              show={showMobileSignModal}
              close={() => setShowMobileSignModal(false)}
            ></MobileModal>
          </div>
        )}
      </div>
    </main>
  );
};

export default Signer;
