import React, { useState, useEffect } from "react"
import styled from "styled-components"
import user from '../util/user'
import communities from '../util/community'
import colors from '../util/colors'
import { Label, Input, Row, Text, Link, Button, Logo } from '../components'
import { Redirect, BrowserRouter as Router } from 'react-router-dom';

const FormWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  @media (prefers-color-scheme: dark) {
    p, label, h1 {
      color: white;
    }
  }
`

const Form = styled.form`
  max-width: 400px;
  width: 100%;
`

const Name = (props) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)
  const [redirect, setRedirect] = useState()

    // const BACKENDURL =
    // process.env.REACT_APP_BACKEND_URL ||
    // window.location.href.indexOf("localhost:8000")
    //   ? "http://localhost:8000"
    //   : "https://api.docuvaultapp.com";
  const BACKENDURL = process.env.REACT_APP_BACKEND_URL || "https://api.docuvaultapp.com";

  const login = (e) => {
    e.preventDefault();
    fetch(BACKENDURL + "/session", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email, password: password }),
      mode: "cors",
    })
      .then((response) => {
        if (!response.ok) {
          console.log("Heres our error", response.statusText);
        }
        return response.json();
      })
      .then((returnedData) => {
        if (returnedData.token) {
          if (returnedData.access == 'resident') {
            localStorage.setItem("token", returnedData.token);
            localStorage.setItem("pass", password);
            props.history.push("/dashboard");
          }
        }
        if (returnedData.statusCode === 403) {
          setError("Please type your email or password again!");
        }
      });
  };

  useEffect(() => {
    async function checkTokenStatus(){
      try{
        const verified = await user.get()

        props.history.push('/dashboard')
      } catch(error) {

        localStorage.token = undefined
        localStorage.pass = undefined
      }
    }

    if(localStorage.token) checkTokenStatus()
  }, [])

  return (
    <div>
      <Logo margin={"24px"} absolute/>
      <FormWrapper>
        <Form onSubmit={login}>
          <div>
            <Text kind="h1" size={"36px"} weight={"900"} center>LOGIN</Text>
            <Text kind="p" size={"16px"} margin={"8px 0 48px"} center>Welcome back! It's good to see you again.</Text>
          </div>

          <Row direction="column" padding={"0 0 24px"}>
            <Input name="email" type="email" change={e => setEmail(e.target.value)} />
            <Label>Email Address</Label>
          </Row>

          <Row direction="column" padding={"0 0 24px"}>
            <Input name="password" type="password" change={e => setPassword(e.target.value)} />
            <Row justify="space-between">
              <Label>Password</Label>
              <Link to="/forgot-password" underline="true"><Label color={colors.blue}>Forgot Password</Label></Link>
            </Row>
          </Row>

          {error && <Label color={"red"}>{error}</Label>}

          <Button primary full onClick={login}>Login</Button>
        </Form>
      </FormWrapper>
    </div>
  )
}

export default Name
