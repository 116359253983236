import React, { useState, useEffect } from "react"
import styled from "styled-components"
import application from '../util/application'
import users from '../util/user'
import queryString from 'query-string'
import Moment from 'react-moment';
import { Header, Sidebar, Row, Text, Box, Wrapper, Link, Button, Input, BoxRow, Cells, Cell, Dashboard, Dots, Add, Loading, Pill, Folder, MessageBar } from '../components'
import copy from 'copy-to-clipboard';

const Invite = styled.div`
  background: #ddd;
  padding: 8px 16px;
  border-radius: 50px;
  font-size: 12px;
  cursor: pointer;
`

const Resident = (props) => {
  const [resident, setResident] = useState([])
  const [applications, setApplications] = useState([])
  const [loaded, setLoaded] = useState()
  const [message, setMessage] = useState()
  const [changed, change] = useState(false)

  function copyLink(url){
    copy(url)
    setMessage('Link copied to clipboard')
    change(!changed)
  }

  useEffect(() => {
    async function getApplications(){
      try{
        const queries = queryString.parse(props.location.search)
        //Get Resident
        const residentData = await users.getResident(props.match.params.id)
        const applicationsData = await application.listResidentApplications(residentData.data.id, queries.page ? queries.page : 0)
        setApplications(applicationsData.data)
        setResident(residentData.data)
        console.log(residentData.data)
        setLoaded(true)
      } catch (err) {

        props.history.push('/login')
      }
    }

    getApplications()
  }, [])

  return (
    <main>
      <Header />
      <Row>
        <Sidebar/>

        {loaded ? (
          <Dashboard padding={"48px"}>

            <Row alignItems="center" justify="space-between" margin={"0 0 16px"} padding={"0 0 0 4px"}>
              <Text kind="p" weight={800} uppercase>{resident.name} / Packet List</Text>
            </Row>

            <Box>
              <BoxRow padding={"16px 24px"} justify="space-between" alignItems="center">
                <Text kind="p" weight={800} size={"14px"} uppercase>Packet Name</Text>

                <Cells>
                <Cell width={105}>
                  <Text kind="p" center weight={800} size={"14px"} uppercase>Created</Text>
                </Cell>

                  <Cell width={125}>
                    <Text kind="p" center weight={800} size={"14px"} uppercase>Due Date</Text>
                  </Cell>

                  <Cell width={125}>
                    <Text kind="p" center weight={800} size={"14px"} uppercase>Status</Text>
                  </Cell>

                  <Cell width={35}>
                    <Link to={"/add-resident?email=" + resident.email}><Add /></Link>
                  </Cell>
                </Cells>
              </BoxRow>


              {applications.length > 0 ? applications.map((item, index) => (
                <BoxRow padding={"16px 24px"} justify="space-between" alignItems="center">
                  <Link to={"/application/" + item.id}>
                    <Row alignItems="center">
                      <Folder />
                      <Text kind="p" weight={600} size={"14px"} underline>{item.myInfo.application_info.packet_name ? item.myInfo.contact_info.name + ': ' + item.myInfo.application_info.packet_name : <Moment format="MMM DD, YYYY">{item.createdAt}</Moment>}</Text>
                    </Row>
                  </Link>

                  <Cells>
                    <Cell width={105}>
                      <Text kind="p" center weight={600} size={"14px"}><Moment format="MMM DD, YYYY">{item.createdAt}</Moment></Text>
                    </Cell>

                    <Cell width={125}>
                      <Text kind="p" center weight={600} size={"14px"}>{item.myInfo.application_info.due_date ? <Moment format="MMM DD, YYYY">{item.myInfo.application_info.due_date}</Moment> : 'N/A'}</Text>
                    </Cell>

                    <Cell width={125}>
                      <Text kind="p" center weight={600} size={"14px"}>{item.progress.status ? item.progress.status : 'N/A'}</Text>
                    </Cell>

                    <Cell width={35}>
                      <Dots />
                    </Cell>
                  </Cells>
                </BoxRow>
              )) : <Row padding={"16px 24px"}><Text kind="p">No Results</Text></Row>}
            </Box>

            {resident.invitationCode ? (
              <Row justify="center" padding={"24px 0"}>
                <Invite onClick={() => copyLink('https://user.seniorsign.io/invite?email='+ resident.email.trim() + '&invite_code='+ resident.invitationCode)}>https://user.seniorsign.io/invite?email={resident.email.trim()}&invite_code={resident.invitationCode}</Invite>
              </Row>
            ) : null}

          </Dashboard>
        ) : <Loading />}
      </Row>
      <MessageBar message={message} listen={changed} />
    </main>
  )
}

export default Resident
