import React, { useEffect, useState } from 'react';
import {
  Box,
  BoxRow,
  Cells,
  Cell,
  Text,
  Required,
  Check,
  Pill,
  Row,
  Button
} from './index';
import applications from '../util/application';

const ResidentAgreements = props => {
  const [application, setApplication] = useState();

  function openSigner(agreement) {
    applications.prepareAgreement(agreement, application.id, props);
    //window.location.href = `/sign/${agreement.id}/${application.id}/Resident`
  }

  function saveAndNext() {
    props.nextStep();
  }

  async function saveAndPrev(){
    props.prevStep()
  }

  useEffect(() => {
    setApplication(props.application);
  }, []);

  if (!application) return null;
  return (
    <>
      <Box width={980}>
        <BoxRow
          padding={'16px 24px'}
          justify="space-between"
          alignItems="center"
        >
          <Text kind="p" weight={800} size={'14px'} uppercase>
            Document Name
          </Text>

          <Cells>
            <Cell width={155}>
              <Text kind="p" center weight={800} size={'14px'} uppercase>
                Action
              </Text>
            </Cell>
          </Cells>
        </BoxRow>

        {application.agreements.resident.length &&
          application.agreements.resident.map((agreement, index) => {
            {
              /* If the agreement hasn't been prefilled */
            }
            if (!applications.prefillComplete(agreement.signatures))
              return null;

            {
              /* If it's not the residents turn */
            }
            if (!applications.residentTurn(agreement.signatures)) {
              return (
                <BoxRow
                  padding={'16px 24px'}
                  justify="space-between"
                  alignItems="center"
                  key={index}
                >
                  <Row alignItems="center">
                    {!applications.allSignaturesComplete(
                      agreement.signatures
                    ) && <Check />}
                    {applications.allSignaturesComplete(
                      agreement.signatures
                    ) && <Check checked />}
                    <Text kind="p" size={'15px'}>
                      {agreement.details.required && <Required />}
                      {agreement.name}
                    </Text>
                  </Row>

                  <Cells>
                    <Cell width={155}>
                      {!applications.allSignaturesComplete(
                        agreement.signatures
                      ) && <Pill gray>In Review</Pill>}
                      {applications.allSignaturesComplete(
                        agreement.signatures
                      ) && <Pill green>Complete</Pill>}
                    </Cell>
                  </Cells>
                </BoxRow>
              );
            }

            {
              /* If it's the residents turn */
            }
            return (
              <BoxRow
                padding={'16px 24px'}
                justify="space-between"
                alignItems="center"
                key={index}
              >
                <Row alignItems="center">
                  <Check />
                  <Text kind="p" size={'15px'}>
                    {agreement.details.required && <Required />}
                    {agreement.name}
                  </Text>
                </Row>

                <Cells>
                  <Cell width={155}>
                    <Button onClick={() => openSigner(agreement)}>
                      Sign Now
                    </Button>
                  </Cell>
                </Cells>
              </BoxRow>
            );
          })}
      </Box>

      {props.nextStep && (
        <Row width={'980px'} justify="flex-end" margin="32px auto 0">
            <Button onClick={saveAndPrev}>Back</Button>
           &nbsp;&nbsp;
          <Button onClick={saveAndNext}>Next</Button>
        </Row>
      )}
    </>
  );
};

export default ResidentAgreements;
