import axios from "axios"
import urls from './urls'

function login(user){
  return axios.post(urls.api + 'session', user)
}

function get(){
  return axios.get(urls.api + 'user', {'headers':{
    'X-Auth-Token': localStorage.token,
  }})
}

function list(id, offset){
  return axios.get(urls.api + 'facilityResidents/' + id+ '/' + (offset * 20) + '/20', {'headers':{
    'X-Auth-Token': localStorage.token,
  }})
}

function listByDepartment(id, department, offset){
  return axios.get(urls.api + 'facilityDepartmentResidents/' + id+ '/' + department + '/'+ (offset * 20) + '/20', {'headers':{
    'X-Auth-Token': localStorage.token,
  }})
}

function countByDepartment(id, department){
  return axios.get(urls.api + 'countFacilityDepartmentResidents/' + id+ '/' + department, {'headers':{
    'X-Auth-Token': localStorage.token,
  }})
}

function listCommunityAdmins(id, offset){
  return axios.get(urls.api + 'facilityAdmins/' + id+ '/' + (offset * 20), {'headers':{
    'X-Auth-Token': localStorage.token,
  }})
}

function getResident(id){
  return axios.get(urls.api + 'user/' + id, {'headers':{
    'X-Auth-Token': localStorage.token,
  }})
}

function search(facilityId, searchTerm){
  return axios.post(urls.api + 'facilitySearchResidents/' + facilityId, {search: searchTerm},{'headers':{
    'X-Auth-Token': localStorage.token,
  }})
}

function checkTokenStatus() {
  return axios.post(urls.api + 'users/verifyToken', {},{'headers':{
    'X-Auth-Token': localStorage.token,
  }})
}

function addResident(resident) {
  return axios.post(urls.api + 'addResident', resident, {'headers':{
    'X-Auth-Token': localStorage.token
  }})
}

function inviteResident(resident) {
  return axios.post(urls.api + 'inviteResident', resident, {'headers':{
    'X-Auth-Token': localStorage.token
  }})
}

function inviteAdmin(admin) {
  return axios.post(urls.api + 'inviteFacility', admin, {'headers':{
    'X-Auth-Token': localStorage.token,
  }})
}

function getByEmail(email) {
  return axios.get(urls.api + 'userbyemail/' + email, {'headers':{
    'X-Auth-Token': localStorage.token,
  }})
}

function remove(id){
  return axios.delete(urls.api + 'user/' + id, {'headers':{
    'X-Auth-Token': localStorage.token,
  }})
}

function update(user){
  return axios.patch(urls.api + 'users/' + user.id, user,{'headers':{
    'X-Auth-Token': localStorage.token,
  }})
}

function getIpAddress(){
  return axios.get(urls.api + 'iplocation', {'headers':{
    'X-Auth-Token': localStorage.token,
  }})
}

function requestReset(email){
  return axios.post(urls.api + 'reset-resident-password', {email: email})
}

function acceptInvitation(user){
  user.new = true
  return axios.post(urls.api + 'acceptFacilityInvitation', user)
}

function resetPassword(password, reset_code){
  return axios.post(urls.api + 'resetPassword', {new_password: password, reset_code: reset_code})
}

function notifyAssignee(assignee, application){
  return axios.post(urls.api + 'notify-assignee', {assignee: assignee, application: application})
}

const functions = {
  login,
  get,
  checkTokenStatus,
  list,
  listByDepartment,
  countByDepartment,
  getResident,
  search,
  listCommunityAdmins,
  inviteResident,
  inviteAdmin,
  getByEmail,
  remove,
  update,
  getIpAddress,
  requestReset,
  acceptInvitation,
  resetPassword,
  notifyAssignee
}

export default functions
