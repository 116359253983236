import React, { useState, useEffect } from 'react'
import { Row, Text, Box, BoxRow, Cells, Cell, Pill, Check, Required, Button } from './index'
import applications from '../util/application'

const FinishedAgreements = (props) => {
  const [application, setApplication] = useState()

  useEffect(() => {
    console.log('application', props)
    setApplication(props.application)
  }, [props.application])

  if(!application) return null
  return (
    <Row direction="column" width="980px" margin="0 auto">
      <Box>
        <BoxRow padding={"16px 24px"} justify="space-between" alignItems="center">
          <Text kind="p" uppercase weight="800">Document Name</Text>

          <Cells>
            <Cell width={155}>
              <Button outline onClick={() => props.downloadAll(application)}>Download All</Button>
            </Cell>
          </Cells>
        </BoxRow>

        <BoxRow direction="column">
          {application.agreements.resident.length && application.agreements.resident.map((agreement, index) => {
            if(!applications.prefillComplete(agreement.signatures)) return null
            if(applications.residentTurn(agreement.signatures)) return null
            return (
              <BoxRow padding={"16px 24px"} justify="space-between" alignItems="center" key={index}>
                <Row alignItems="center">
                  {!applications.allSignaturesComplete(agreement.signatures) && <Check />}
                  {applications.allSignaturesComplete(agreement.signatures) && <Check checked />}
                  <Text kind="p" size={"15px"}>{agreement.details.required && <Required />}{agreement.name}</Text>
                </Row>

                <Cells>
                  <Cell width={155}>
                    {!applications.allSignaturesComplete(agreement.signatures) && <Pill gray>In Review</Pill>}
                    {applications.allSignaturesComplete(agreement.signatures) && <Pill green>Complete</Pill>}
                  </Cell>
                </Cells>
              </BoxRow>
            )
          })}
        </BoxRow>
      </Box>
    </Row>
  )
}

export default FinishedAgreements
