import React, { useState, useEffect } from "react";
import styled from "styled-components";
import colors from "../util/colors";
import Close from "./close";
import Row from "./row";
import Text from "./text";
import Button from "./button";

const Header = styled(Row)`
  border-bottom: 1px solid #ddd;
`;

const Modal = styled.div`
  background: white;
  border-radius: 8px;
  position: relative;
  z-index: 9;
  width: 324px;
  align-self: flex-start;
`;

const Wrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  display: flex;
  justify-content: center;
  overflow: scroll;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
`;

const MobileModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  width: 324px;
  background: white;
  border-radius: 8px;
  position: relative;
  z-index: 9;
  align-self: flex-start;
  margin-top: 15vh;
`;

const MobileModalTitle = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  color: var(--grayscale-900-grayscale, #475867);

  width: 100%;
  margin-bottom: 16px;
`;

const MobileModalSubTitle = styled.div`
  color: var(--grayscale-900-grayscale, #475867);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10px;
  border-top: 1px solid var(--grayscale-300-grayscale, #e7eaec);
  padding-top: 16px;
  width: 100%;
`;

const MobileModalText = styled.div`
  color: var(--grayscale-900-grayscale, #475867);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 16px;
  width: 100%;
  text-align: left;
`;

const MobileModalButtons = styled.div`
  display: flex;
  justify-content: space-between;
    margin-top: 20px;
    width: 100%;
`;

const MobileIconText = styled.div`
  color: var(--grayscale-900-grayscale, #475867);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
`;

const MobilePrimary = styled(Button)`
  background: ${colors.blue};
  color: white;
  border-radius: 8px;
  width: 130px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  
`;

const MobileSecondary = styled(Button)`
  background: white;
  color: ${colors.blue};
  border-radius: 8px;
  width: 130px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.blue};
`;

export default (props) => {
  useEffect(() => {}, [props.show]);

  if (!props.show) return null;
  return (
    <Wrap>
      <Overlay />
      <div>
        <MobileModal {...props}>
          <MobileModalTitle>Signing on a small screen?</MobileModalTitle>
          <MobileModalText>
            Not ideal, we know. For a better experience, switch to a larger
            device if possible.
          </MobileModalText>
          <MobileModalSubTitle>
            If you're stuck with mobile, try these tips:
          </MobileModalSubTitle>
          <MobileIconText>
            <div style={{background: "url('/icon_rotate_mobile.svg')", width: 16, height: 16}}></div>
            Rotate to landscape
            </MobileIconText>
          <MobileIconText>
          <div style={{background: "url('/icon_zoom_out.svg')", width: 16, height: 16}}></div>Zoom out to see all fillable fields
          </MobileIconText>
          <MobileModalButtons>
            <MobileSecondary onClick={props.saveAndExit}>I'll Switch</MobileSecondary>
            <MobilePrimary onClick={props.close}>I Understand</MobilePrimary>
          </MobileModalButtons>
        </MobileModal>
      </div>
    </Wrap>
  );
};
