import React, { useState, useEffect } from "react"
import styled from "styled-components"
import user from '../util/user'
import colors from '../util/colors'
import { Label, Input, Row, Text, Link, Button, Logo } from '../components'
import { Redirect, BrowserRouter as Router } from 'react-router-dom';
import QueryString from 'query-string'

const FormWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  @media (prefers-color-scheme: dark) {
    p, label, h1 {
      color: white;
    }
  }
`

const Form = styled.form`
  max-width: 400px;
  width: 100%;
`

const Name = (props) => {
  const [password, setPassword] = useState('')
  const [repeat, setRepeat] = useState('')
  const [error, setError] = useState('')
  const [code, setCode] = useState()

  async function reset(e){
    e.preventDefault()
    setError(undefined)

    if(password !== repeat) setError(`Passwords don't match`)
    if(!password || password.length < 6) setError(`Please enter a password (min 6 characters)`)

    if(password && !error){
      try{
        const _request = await user.resetPassword(password, code)
        window.location.href = '/login'
      } catch(err){
        alert('Error resetting password')
      }
    }
  }

  useEffect(() => {
    const parsed = QueryString.parse(window.location.search)
    setCode(parsed.reset_code)

  }, [])

  return (
    <div>

      <a href="/login"><Logo margin={"24px"} absolute/></a>
      <FormWrapper>
        <Form onSubmit={reset}>
          <div>
            <Text kind="h1" size={"36px"} weight={"900"} center>Reset Password</Text>
            <Text kind="p" size={"16px"} margin={"8px 0 48px"} center>Enter your new password</Text>
          </div>

          <Row direction="column" padding={"0 0 24px"}>
            <Input name="password" type="password" change={e => setPassword(e.target.value)} />
            <Label>New Password</Label>
          </Row>

          <Row direction="column" padding={"0 0 24px"}>
            <Input name="repeat" type="password" change={e => setRepeat(e.target.value)} />
            <Label>Repeat New Password</Label>
          </Row>

          {error && <Label color={"red"}>{error}</Label>}

          <Button primary full onClick={reset}>Reset Password</Button>
        </Form>
      </FormWrapper>
    </div>
  )
}

export default Name
