import React, { useState, useEffect } from "react"
import styled from "styled-components"
import aws from '../util/aws'
import { arrayBufferToBlob } from 'blob-util'
import pdf from '../util/pdf'
import colors from '../util/colors'
import agreements from '../util/agreements'
import applications from '../util/application'
import adBlocker from 'just-detect-adblock'

const Message = styled.div`
  width: 100%;
  padding: 16px 32px;
  position: fixed;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;
  bottom: ${props => props.show ? '0px' : '-100px'};
  left: 0;
  background: ${colors.blue};
  color: white;
  font-weight: 700;
  z-index: 11;
  text-align: center;
`

export default props => {
  const [message, setMessage] = useState()
  const [showMessage, setShowMessage] = useState(false)

  useEffect(() => {
    async function print(){
      if(adBlocker.isDetected()){
        alert('Disable adblocker on this domain to allow print tab to open')
      } else {
        try {
          setShowMessage(true)
          setMessage('Preparing Document')

          let url
          if(props.agreement.agreement_filename){
            const buffer = await aws.getObject(props.agreement.agreement_filename)
            const blob = arrayBufferToBlob(buffer.data, 'application/pdf')
            url = window.URL.createObjectURL(blob)
          } else {
            if(props.agreement.agreement_id){
              const agreement = await agreements.get(props.agreement.agreement_id)
              const html = applications.generateHtml(agreement.data)
              const generated = await pdf.generate(html, null, 'pdf')
              const buffer = await aws.getObject(generated.data.Key)
              const blob = arrayBufferToBlob(buffer.data, 'application/pdf')
              url = window.URL.createObjectURL(blob)
            } else {
              const html = applications.generateHtml({pages: {pages: props.agreement.pages}, details: {}})
              const generated = await pdf.generate(html, null, 'pdf')
              const buffer = await aws.getObject(generated.data.Key)
              const blob = arrayBufferToBlob(buffer.data, 'application/pdf')
              url = window.URL.createObjectURL(blob)
            }
          }

          try{
            window.open(url)
          } catch {
            alert('Error opening tab to print document, visit the help center for more information')
          }

          setMessage(undefined)
        } catch (error) {

        }
      }
    }

    if(props.agreement) print()
  }, [props.agreement])

  return (
    <Message show={showMessage && message}>
      {message}
    </Message>
  )
}
