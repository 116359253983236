import React, { useState, useEffect } from 'react'
import { PrintAll, Checkbox, Modal, Row, Box, BoxRow, Text, Button, Required, Cells, Cell, UploadPhysician, Dots } from './index'
import styled from 'styled-components'
import applications from '../util/application'

const Mark = styled.div``

const PhysicianForms = (props) => {
  const [application, setApplication] = useState()
  const [showPrintAllModal, setShowPrintAllModal] = useState()
  const [printAgreements, setPrintAllAgreements] = useState()

  function print(agreement){
    setPrintAllAgreements({agreements: {resident: [agreement]}})
  }

  function printAll(){
    const printPacket = {
      agreements: {
        resident: application.agreements.physician.filter(agreement => agreement.selected)
      }
    }
    if(application.agreements.physician.filter(agreement => agreement.selected).length > 0) {
      setPrintAllAgreements(printPacket)
      setShowPrintAllModal(false)
    } else {
      alert('Please select at least one document to print')
    }
  }

  function toggleAgreement(agreement){
    for(var i in application.agreements.physician){
      if(application.agreements.physician[i].template_id == agreement.template_id) application.agreements.physician[i].selected = !application.agreements.physician[i].selected
    }
  }

  async function saveAndNext(){
    const latest = await applications.get(application.id)
    latest.data.myInfo.application_info.physician_forms_printed = true
    await applications.update(latest.data)
    props.nextStep()
  }

  async function saveAndPrev(){
    const latest = await applications.get(application.id)
    latest.data.myInfo.application_info.physician_forms_printed = true
    await applications.update(latest.data)
    props.prevStep()
  }

  useEffect(() => {
    setApplication(props.application)
  }, [props.application])

  if(!application) return null
  return (
    <Row direction="column">
      <Box width="1180">
        <BoxRow justify="space-between" padding="24px">
          <Text kind="p" uppercase weight="800">Document Name</Text>
        </BoxRow>
        <BoxRow justify="space-between" padding="12px 24px" alignItems="center">
          <Text kind="p" size="12px" padding="0 16px 0 0">Please print the following packet of forms and review with your physician prior to the due date. Contact your new community if you have any questions.</Text>
          <Button outline onClick={() => setShowPrintAllModal(true)}>Print All</Button>
        </BoxRow>

        {application.agreements.physician && application.agreements.physician.length ? application.agreements.physician.map((agreement, index) => (
          <BoxRow padding={"16px 24px"} justify="space-between" alignItems="center" key={index}>
            <Row alignItems="center">
              <Mark checked={agreement.received || (agreement.files && agreement.files.length > 0)} />
              <Text kind="p" size={"15px"}>{agreement.name} {agreement.details.required && <Required />}</Text>
            </Row>

            <Cells>
              <Cell width={35}>
                <Dots src="icon-dots.svg" options={[{label: 'Print', action: () => print(agreement)}]} />
              </Cell>
            </Cells>
          </BoxRow>
        )) : (
          <BoxRow padding={"16px 24px"} justify="space-between" alignItems="center">
            <Text kind="p" size={"15px"} weight={600}>No Physician Documents to send</Text>
          </BoxRow>
        )}
      </Box>

      <Modal title={"Print All"} show={showPrintAllModal} close={() => setShowPrintAllModal(false)}>
        <Row padding="24px" direction="column">
            <Text weight="700" padding="0 0 16px">Select Documents</Text>

            {application.agreements.physician.length && application.agreements.physician.map((agreement, index) => {
              return (
                <Row key={index} alignItems="center" padding="8px 0">
                  <Checkbox onClick={() => toggleAgreement(agreement)} selected={agreement.selected}/>
                  <Text>{agreement.name}</Text>
                </Row>
              )
            })}

            <Row justify="flex-end">
              <Button onClick={printAll}>Print</Button>
            </Row>
        </Row>
      </Modal>

      <PrintAll application={printAgreements} auditTrail={false}/>

      <Row width="1180px" justify="flex-end" margin="32px auto 0">
        <Button onClick={saveAndPrev}>Back</Button>
        &nbsp;&nbsp;
        <Button onClick={saveAndNext}>Next</Button>
      </Row>
    </Row>
  )
}

export default PhysicianForms
