import React, { useEffect, useState, useRef } from 'react'
import styled from "styled-components"
import Text from './text'

const Select = styled.div`
  background: ${props => props.textonly ? 'none' : 'white'};
  border: ${props => props.textonly ? 'none' : '1px solid #C4CAD0'};
  width: 100%;
  border-radius: 6px;
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  padding: ${props => props.textonly ? '0' : '0 32px 0 16px'};
  cursor: pointer;
  margin: ${props => props.margin ? props.margin : '0'};
  max-width: ${props => props.width ? props.width : 'auto'};
  p {
    color: ${props => props.textonly ? 'inherit' : '#4D5864 !important'};
    font-weight: ${props => props.textonly ? '800' : 'inherit'};
    font-size: ${props => props.textonly ? '16px' : '14px'};
    text-transform: ${props => props.textonly ? 'uppercase' : 'inherit'};
    padding-right: ${props => props.textonly ? '40px' : '0'};
    position: relative;
    white-space: nowrap;
    @media (prefers-color-scheme: dark) {
      color: ${props => props.textonly ? 'white' : '#4D5864 !important'};
    }
    &:after {
      content: '';
      background: ${props => props.textonly ? "url('/icon-downarrow.svg')" : "none"};
      background-size: contain;
      width: 16px;
      height: 10px;
      position: absolute;
      top: 8px;
      right: 0;
      @media (prefers-color-scheme: dark) {
        background: ${props => props.textonly ? "url('/icon-arrow-white.svg')" : "none"};
        background-size: contain;
      }
    }
  }
  &:after {
    content: '';
    background: ${props => props.textonly ? "none" : "url('/icon-downarrow.svg')"};
    background-size: contain;
    width: 12px;
    height: 7px;
    position: absolute;
    top: 45%;
    right: 8px;
  }
  &:focus {
    box-shadow: 0 0px 8px rgba(0,0,0,0.25);
    outline: none;
  }
`

const List = styled.div`
  position: absolute;
  top: 95%;
  left: 0;
  background: white;
  border: 1px solid #ddd;
  width: 100%;
  z-index: 3;
`

const Item = styled.div`
  padding: ${props => props.textonly ? '12px 16px' : '8px 16px'};
  background: white;
  transition-duration: 0.3s;
  cursor: pointer;
  color: #4D5864 !important;
  font-size: ${props => props.textonly ? '14px' : '13px'};
  &:hover {
    background: #eee;
  }
`

export default props => {
  const [selected, setSelected] = useState()
  const [showMenu, setShowMenu] = useState(false)

  const node = useRef();

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      return;
    }

    setShowMenu(false)
  };

  function select(item){
    setSelected(item)
    if(props.update) props.update(item.value)
    if(props.change) props.change(item)
  }

  useEffect(() => {
    try {
      setSelected(props.initialvalue ? props.initialvalue : props.options[0])
      // add when mounted
      document.addEventListener("mousedown", handleClick);
      // return function to be called when unmounted
      return () => {
        document.removeEventListener("mousedown", handleClick);
      };
    } catch(err) {
      console.log(err)
    }

  }, [props.listen]);

  return (
    <Select ref={node} {...props} onClick={() => setShowMenu(!showMenu)}>
      {selected && <Text kind="p" size={'13px'}>{selected.label}</Text>}

      {showMenu && (
        <List>
          {props.options && props.options.map((item, index) => (
            <Item textonly={props.textonly} key={index} onClick={() => select(item)}>{item.label}</Item>
          ))}
        </List>
      )}
    </Select>
  )
}
