import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import * as serviceWorker from './serviceWorker';

if (!!process.env.REACT_APP_LOG_ROCKET_KEY) {
  LogRocket.init(process.env.REACT_APP_LOG_ROCKET_KEY || "");
  setupLogRocketReact(LogRocket);
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
