import React, { useState, useEffect } from "react"
import styled from "styled-components"
import applications from '../util/application'
import { DownloadAll, Close, InPersonAttachments, ResidentAgreements, Header, Row, Text, Box, Button, MessageBar, Summary, MyInformation, PhysicianForms } from '../components'
import colors from '../util/colors'

const Exit = styled(Close)`
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
  z-index: 5;
`

const CheckMark = styled.img`
  width: 18px;
`

const Steps = styled(Row)`
  position: relative;
  margin-bottom: 48px;
`

const Line = styled.div`
  position: absolute;
  width: 100%;
  background: #C3CAD1;
  height: 2px;
  z-index: 1;
  top: 15px;
  width: ${({length}) => ((length-1) * 165) + 'px'};
`

const Step = styled(Row)`
  width: 165px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition-duration: 0.3s;
`

const Number = styled.div`
  background: ${({complete, active}) => complete ? colors.green : (active ? colors.blue : colors.gray_100)};
  width: 30px;
  height: 30px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  color: white;
  font-size: 12px;
  font-weight: 800;
  line-height: 1;
  transition-duration: 0.3s;
`

const Name = styled.div`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  color: ${({complete, active}) => complete ? colors.green : (active ? colors.blue : '#4D5864')};
  transition-duration: 0.3s;
  opacity: ${({active}) => active ? 1 : 0.5};
  @media (prefers-color-scheme: dark) {
    color: ${({complete, active}) => complete ? colors.green : (active ? colors.blue : 'white')};
  }
`

const Application = (props) => {
  const [application, setApplication] = useState()
  const [steps, setSteps] = useState()
  const [step, setStep] = useState()
  const [refreshed, refresh] = useState()
  const [downloads, setDownloads] = useState()

  function downloadAll(e){
    setDownloads(e)
  }

  function findStep(_steps){
    let found = false
    for(var i in _steps){
      if(!found && (_steps[i].complete === false || _steps[i].step === 'Summary')){
        found = true
        console.log('step', _steps[i].step)
        setStep(_steps[i].step)
      }
    }
  }

  function nextStep(){
    for(var i in steps){
      if(steps[i].step == step) {
        setStep(steps[(parseInt(i) + 1)].step)
      }
    }
  }

  function prevStep(){
    for(var i in steps){
      if(steps[i].step == step) {
        setStep(steps[(parseInt(i) -1 )].step)
      }
    }
  }

  useEffect(() => {
    async function getApplication(){
      const _application = await applications.get(props.match.params.id)
      const _steps = applications.generateResidentSteps(_application.data)
      setApplication(_application.data)
      setSteps(_steps)
    }

    getApplication()
  }, [step, refreshed])

  useEffect(() => {
    async function getStep(){
      const _application = await applications.get(props.match.params.id)
      const _steps = applications.generateResidentSteps(_application.data)
      findStep(_steps)
    }

    getStep()
  }, [])

  return (
    <main>
      <Header />
      {application ? (
        <Row relative padding="48px" direction="column">
          <a href="/dashboard"><Exit /></a>
          <Steps alignItems="center" justify="center">
            {steps && steps.map((item, index) => (
              <Step active={step == item.step} key={index} onClick={() => {}}>
                <Number complete={item.complete} active={step == item.step}>{item.complete ? <CheckMark src="/icon-checkmark-white.svg"/> : index + 1}</Number>
                <Name complete={item.complete} active={step == item.step}>{item.step}</Name>
              </Step>
            ))}
            <Line length={steps && steps.length}/>
          </Steps>

          {step == 'My Information' && <MyInformation application={application} nextStep={nextStep}/>}
          {step == 'Physician Forms' && <PhysicianForms application={application} nextStep={nextStep} prevStep={prevStep} />}
          {step == 'Sign Agreements' && <ResidentAgreements application={application} nextStep={nextStep} prevStep={prevStep} {...props}/>}
          {step == 'Attach Documents' && <InPersonAttachments application={application} refresh={e => refresh(e)} nextStep={nextStep} prevStep={prevStep} />}
          {step == 'Summary' && <Summary application={application} downloadAll={downloadAll} prevStep={prevStep}/>}
        </Row>
      ) : null}

      <DownloadAll downloads={downloads} complete={() => setDownloads(undefined)}/>
    </main>
  )
}

export default Application
