import React, { useState, useEffect } from "react"
import styled from "styled-components"
import application from '../util/application'
import user from '../util/user'
import queryString from 'query-string'
import { Header, Row, Box, Text, Pill } from '../components'
import moment from 'moment'
import LogRocket from 'logrocket'

const Steps = styled.div``
const Step = styled.div``
const Folder = styled.img`
  width: 130px;
  margin: 32px;
`
const Link = styled.a`
  display: flex;
`

const Dashboard = (props) => {
  const [steps, setSteps] = useState()
  const [applications, setApplications] = useState()

  useEffect(() => {
    async function getApplication(){
      const _me = await user.get()

      //Set user on LogRocket
      LogRocket.identify(_me.data.id, {
        name: _me.data.name,
        email: _me.data.email,

        // Add your own custom user variables here, ie:
        access: _me.data.access,
      });

      const _application = await application.listAll(_me.data.id, 0)
      setApplications(_application.data)
    }

    getApplication()
  }, [])

  return (
    <main>
      <Header />

      <Row padding="48px" direction="column">
        <Row padding="0 16px">
          <Text kind="p" weight="700" uppercase>Packets</Text>
        </Row>
        <Row wrap>
          {applications && applications.map((app, index) => (
            <>
              <Link href={`/application/${app.id}`}>
                <Row margin="16px">
                  <Box key={index}>
                    <Row padding="16px" direction="column" alignItems="center" justify="center">
                      <Text kind="p" weight="700">{app.myInfo.contact_info.name}</Text>
                      <Folder src="/icon-folder.svg"/>
                      <Pill primary={app.progress.current == 'New!'} green={app.progress.current == 'complete'} gray={app.progress.current !== 'complete' && app.progress.current !== 'New!'}>{app.progress.current}</Pill>
                      <Text kind="p" weight="700" margin="8px 0 0">{app.myInfo.application_info.packet_name ? app.myInfo.contact_info.name + ': ' + app.myInfo.application_info.packet_name : moment(app.createdAt).format('MMM DD, YYYY')}</Text>
                    </Row>
                  </Box>
                </Row>
              </Link>
            </>
          ))}
        </Row>
      </Row>
    </main>
  )
}

export default Dashboard
