import React from 'react'
import styled from "styled-components"
import media from '../util/media'

const Dashboard = styled.div`
  width: 100%;
  background: #F9F9FA;
  min-height: 100vh;
  padding: ${props => props.mobilepadding ? props.mobilepadding : '0'};
  position: relative;
  @media (prefers-color-scheme: dark) {
    background: #565656;
    p, label {
      color: white;
    }
  }
  ${media.tabletPlus`
    padding: ${props => props.padding ? props.padding : '0'};
  `}
`

export default props => <Dashboard {...props} />
