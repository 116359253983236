import React, { useState, useEffect } from "react";
import { DownloadAll } from "./components";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  Reset,
  Forgot,
  Invite,
  Login,
  Dashboard,
  Sign,
  Application,
  PageNotFound,
} from "./pages";
import ScrollToTop from "./components/scroll-top";
import Helmet from "react-helmet";

const App = (props) => {
  const [downloads, setDownloads] = useState();

  useEffect(() => {
    if (window.MSCompatibleInfo != null) {
      alert("IE is not allowed please use Chrome!");
      throw new Error("IE is not allowed please use Chrome!");
    }
  }, []);

  function downloadAll(e) {
    setDownloads(e);
  }

  return (
    <div>
      <Helmet title="Senior Sign">
        <script>
          {`window['_fs_debug'] = false;
          window['_fs_host'] = 'fullstory.com';
          window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
          window['_fs_org'] = '7R8JX';
          window['_fs_namespace'] = 'FS';
          (function(m,n,e,t,l,o,g,y){
              if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
              g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
              o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script;
              y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
              g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
              g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
              g.log = function(a,b) { g("log", [a,b]) };
              g.consent=function(a){g("consent",!arguments.length||a)};
              g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
              g.clearUserCookie=function(){};
          })(window,document,window['_fs_namespace'],'script','user');`}
        </script>

        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-93904408-2"
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'UA-93904408-2');`}
        </script>

        <link rel="shortcut icon" type="image/png" href="/favicon.png" />
      </Helmet>

      <link
        href="https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css?family=La+Belle+Aurore&display=swap"
        rel="stylesheet"
      />
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={Login} />
          <Route path="/invite" component={Invite} />
          <Route path="/login" component={Login} />
          <Route path="/reset-password" component={Reset} />
          <Route path="/forgot-password" component={Forgot} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/application/:id" component={Application} />
          <Route path="/sign/:id/:application/:filler" component={Sign} />
          <Route path="*" exact={true} component={PageNotFound} />
        </Switch>
      </Router>

      <DownloadAll
        downloads={downloads}
        complete={() => setDownloads(undefined)}
      />
    </div>
  );
};

export default App;
