import React, { useEffect, useState, useRef} from 'react'
import styled from "styled-components"
import applications from '../util/application'
import aws from '../util/aws'
import {MessageBar, Row, Text, BoxRow, Box, Cells, Cell, Check, Checkbox, Upload, Dots, Menu, Label, Button, AddDocuments, SelectPacket, Required, UploadPhysician} from './index'
import $ from 'jquery'
import randomString from 'randomstring'
import JSZip from 'jszip';
import saveAs from 'save-as'

const Attached = styled(Label)`
  margin-right: 32px;
`

const Mark = styled(Checkbox)`
  cursor: pointer
`

export default props => {
  const [app, setApp] = useState(props.application)
  const [showAddDocuments, setShowAddDocuments] = useState(false)

  async function download(files, name){
    const zip = new JSZip();

    for(var i in files){
      const buffer = await aws.getObject(files[i])
      zip.file(files[i], buffer.data);
    }

    const zipped = await zip.generateAsync({ type: "blob" })
    saveAs(zipped, name.replace(/ /g, '_') + "_attachments.zip")
  }

  async function downloadAll(documents){
    const zip = new JSZip();

    for(var i in documents){
      const files = documents[i].files
      for(var j in files){
        const buffer = await aws.getObject(files[j])
        zip.file(files[j], buffer.data);
      }
    }

    const zipped = await zip.generateAsync({ type: "blob" })
    saveAs(zipped, props.application.myInfo.contact_info.name.replace(/ /g, '_') + "_attachments.zip")
  }

  async function clearAttached(doc){
    const check = window.confirm('Are you sure you want to remove attached documents?')
    if(check){
      doc.files = undefined
      const application = await applications.get(app.id)
      for(var i in application.data.myDocuments.required_documents){
        if(application.data.myDocuments.required_documents[i].title == doc.title){
          application.data.myDocuments.required_documents[i] = doc
        }
      }
      await applications.update(application.data)
      props.refresh()
    }
  }

  async function remove(doc){
    const check = window.confirm('Are you sure you want to remove required document from application?')
    if(check){
      const application = await applications.get(app.id)
      application.data.myDocuments.required_documents = application.data.myDocuments.required_documents.filter(item => item.title !== doc.title)
      await applications.update(application.data)
      props.refresh()
    }
  }

  async function checkReceived(doc){
    const application = await applications.get(app.id)
    for(var i in application.data.agreements.physician){
      if(application.data.agreements.physician[i].template_id == doc.template_id){
        if(doc.received) {
          application.data.agreements.physician[i].received = false
        } else {
          application.data.agreements.physician[i].received = true
        }

      }
    }
    await applications.update(application.data)
    props.refresh(randomString.generate())
  }

  useEffect(() => {
    setApp(props.application)
  }, [props.application]);

  return (
    <Row direction="column">
      <Box width={980}>
        <BoxRow padding={"16px 24px"} justify="space-between" alignItems="center">
          <Text kind="p" weight={800} size={"14px"} uppercase>Documents</Text>

          <Cells>
            <Cell width={105}>
              <Text kind="p" weight={800} size={"14px"} uppercase>Received?</Text>
            </Cell>
          </Cells>
        </BoxRow>

        {app.agreements.physician && app.agreements.physician.length ? app.agreements.physician.map((agreement, index) => (
          <BoxRow padding={"16px 24px"} justify="space-between" alignItems="center" key={index}>
            <Text kind="p" size={"15px"}>{agreement.name}</Text>

            <Cells>
              <Cell width={105}>
                {agreement.files && <Text kind="p" size="12px">{agreement.files.length} file(s) attached</Text>}
                {agreement.received && !agreement.files && <Text kind="p" size="12px">Received</Text>}
              </Cell>

              <Cell width={105}>
                <UploadPhysician application={app} agreement={agreement} complete={e => props.refresh(e)}>
                  <Button outline>Attach</Button>
                </UploadPhysician>
              </Cell>

              <Cell width={105}>
                <Mark onClick={() => checkReceived(agreement)} selected={agreement.received || (agreement.files && agreement.files.length > 0)} />
              </Cell>
            </Cells>
          </BoxRow>
        )) : (
          <BoxRow padding={"16px 24px"} justify="space-between" alignItems="center">
            <Text kind="p" size={"15px"} weight={600}>No Physician Documents to send</Text>
          </BoxRow>
        )}

      </Box>

      <AddDocuments application={app} show={showAddDocuments} close={() => setShowAddDocuments(false)} complete={() => props.refresh()} />
    </Row>
  )
}
