import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import applications from '../util/application';
import agreements from '../util/agreements';
import aws from '../util/aws';
import {
  ForwardAgreement,
  Checkbox,
  Modal,
  MessageBar,
  Row,
  Text,
  BoxRow,
  Box,
  Cells,
  Cell,
  Check,
  Upload,
  Dots,
  Menu,
  Label,
  Button,
  AddAgreements,
  Preview,
  Select,
  Pill,
  Print,
  PrintAll,
  SelectPacket,
  Required
} from './index';
import $ from 'jquery';
import randomString from 'randomstring';
import moment from 'moment';
import emailer from '../util/emails';
import templates from '../util/templates';
import user from '../util/user';
import communities from '../util/community';
import colors from '../util/colors';

const Actions = styled.div`
  display: flex;
  & button {
    margin-left: 16px;
  }
`;

const Indicator = styled.img`
  margin-right: 16px;
  max-width: 24px;
`;

const Message = styled.div`
  width: 100%;
  padding: 16px 32px;
  position: fixed;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;
  bottom: ${props => (props.show ? '0px' : '-100px')};
  left: 0;
  background: ${colors.blue};
  color: white;
  font-weight: 700;
  z-index: 11;
  text-align: center;
`;

export default props => {
  const [app, setApplication] = useState(props.application);
  const [showAddAgreements, setShowAddAgreements] = useState(false);
  const [showPreview, setshowPreview] = useState(false);
  const [previewDoc, setPreviewDoc] = useState();
  const [downloads, setDownloads] = useState([]);
  const [message, setMessage] = useState();
  const [longMessage, setLongMessage] = useState();
  const [changed, change] = useState();
  const [printAgreement, setPrintAgreement] = useState();
  const [printAgreements, setPrintAllAgreement] = useState();
  const [resident, setResident] = useState();
  const [showPrintAllModal, setShowPrintAllModal] = useState(false);
  const [includeAuditTrail, setIncludeAuditTrail] = useState(false);
  const [forwardAgreement, setForwardAgreement] = useState(false);

  function generateOptions(agreement) {
    const array = [
      {
        label: 'Reset',
        disabled: false,
        action: () =>
          agreements
            .reset(agreement, props.match.params.id)
            .then(e => props.refresh())
      },
      {
        label: 'Remove',
        disabled: false,
        action: () =>
          agreements
            .remove(agreement, props.match.params.id)
            .then(e => props.refresh())
      },
      {
        label: 'Print',
        disabled: false,
        action: () => setPrintAgreement(agreement)
      },
      {
        label: 'Forward',
        disabled: false,
        action: () => setForwardAgreement(agreement)
      },
      {
        label: 'Preview',
        disabled: agreement.agreement_id ? false : true,
        action: () => previewDocument(agreement)
      },
      {
        label: 'Download',
        disabled: !applications.allSignaturesComplete(agreement.signatures),
        action: () => {
          addDownload(agreement.template_id);
          props.refresh();
          setLongMessage('Downloading...');
          agreements
            .download(agreement, app)
            .then(e => {
              props.refresh();
              removeDownload(agreement.template_id);

              setLongMessage('Download Complete');
              setTimeout(() => setLongMessage(undefined), 3000);
            })
            .catch(() => removeDownload(agreement.template_id));
        }
      }
    ];

    return array;
  }

  async function sendInvite() {
    try {
      const location = await communities.get(resident.data.facilityId);
      let email;
      if (resident.data.invitationCode) {
        email = emailer.generateResidentInviteEmail(
          resident.data.email,
          location.data.details.name,
          'https://user.seniorsign.io',
          resident.data.invitationCode,
          resident.data.name
        );
        email = emailer.generateNewApplicationEmail(
          resident.data.email,
          location.data.details.name,
          'https://user.seniorsign.io',
          resident.data.name
        );
      } else {
        email = emailer.generateNewApplicationEmail(
          resident.data.email,
          location.data.details.name,
          'https://user.seniorsign.io',
          resident.data.name
        );
      }
      await emailer.send(email);
      setLongMessage('Email sent successfully');
      change(!changed);
    } catch (error) {
      setLongMessage('Error sending email');
      change(!changed);
    }
  }

  function addDownload(template_id) {
    downloads.push(template_id);
    setDownloads(downloads);
    setMessage('Downloading...');
  }

  function removeDownload(template_id) {
    setDownloads(downloads.filter(dl => dl !== template_id));
  }

  function previewDocument(agreement) {
    setPreviewDoc(agreement);
    setshowPreview(true);
  }

  //setPrintAllAgreement(app)

  function signInPerson() {
    const agreementsReady = props.application.agreements.resident.filter(
      item => item.agreement_id
    );
    //if(agreementsReady.length > 0) props.history.push(`/in-person/${props.application.id}`)
    props.history.push(`/in-person/${props.application.id}`);
  }

  function toggleAgreement(agreement) {
    for (var i in app.agreements.resident) {
      if (app.agreements.resident[i].template_id == agreement.template_id)
        app.agreements.resident[i].selected = !app.agreements.resident[i]
          .selected;
    }
  }

  function printAll() {
    const printPacket = {
      agreements: {
        resident: app.agreements.resident.filter(
          agreement => agreement.selected
        )
      }
    };
    if (
      app.agreements.resident.filter(agreement => agreement.selected).length > 0
    ) {
      setPrintAllAgreement(printPacket);
      setShowPrintAllModal(false);
    } else {
      alert('Please select at least one document to print');
    }
  }

  useEffect(
    () => {
      async function getResident() {
        const res = await user.getResident(props.application.userId);
        setResident(res);
      }

      getResident();
      for (var i in props.application.agreements.resident)
        props.application.agreements.resident[i].selected = true;
      setApplication(props.application);
    },
    [props.application]
  );

  return (
    <Row direction="column" padding={'32px 40px'}>
      <Row
        height={'56px'}
        justify="space-between"
        alignItems="center"
        padding={'0 5px 16px 5px'}
      >
        <SelectPacket {...props} refresh={props.refresh} application={app} />

        <Row justify="flex-end">
          <Actions>
            {resident &&
              resident.data.invitationCode && (
                <Button onClick={sendInvite} outline>
                  Send Invite
                </Button>
              )}
            {resident &&
              !resident.data.invitationCode && (
                <Button onClick={sendInvite} outline>
                  Resend Invite
                </Button>
              )}
            <Button onClick={signInPerson} primary>
              Sign In Person
            </Button>
          </Actions>
        </Row>
      </Row>

      <Box>
        <BoxRow
          padding={'16px 24px'}
          justify="space-between"
          alignItems="center"
        >
          <Text kind="p" weight={800} size={'14px'} uppercase>
            Document Name
          </Text>

          <Cells>
            <Cell width={120}>
              <Text kind="p" center weight={800} size={'14px'} uppercase>
                Due Date
              </Text>
            </Cell>

            <Cell width={105}>
              <Text kind="p" center weight={800} size={'14px'} uppercase>
                Status
              </Text>
            </Cell>

            <Cell width={35}>
              <Menu
                options={[
                  {
                    label: 'Add Agreement(s)',
                    action: () => setShowAddAgreements(true)
                  },
                  {
                    label: 'Download All',
                    action: () => props.downloadAll(app)
                  },
                  {
                    label: 'Print All',
                    action: () => setShowPrintAllModal(true)
                  }
                ]}
              />
            </Cell>
          </Cells>
        </BoxRow>

        {app.agreements.resident.length &&
          app.agreements.resident.map((agreement, index) => {
            return (
              <BoxRow
                padding={'16px 24px'}
                justify="space-between"
                alignItems="center"
                key={index}
              >
                <Row alignItems="center">
                  {applications.residentTurn(agreement.signatures) && (
                    <Indicator src="/icon-waiting.svg" />
                  )}
                  {applications.communityTurn(agreement.signatures) && (
                    <Indicator src="/icon-prepare.svg" />
                  )}
                  {applications.managerTurn(agreement.signatures) && (
                    <Indicator src="/icon-prepare.svg" />
                  )}
                  {applications.allSignaturesComplete(agreement.signatures) && (
                    <Check checked />
                  )}
                  <Text kind="p" size={'15px'}>
                    {agreement.name}{' '}
                    {agreement.details &&
                      agreement.details.required && <Required />}
                  </Text>
                </Row>

                <Cells>
                  <Cell width={120}>
                    <Text
                      kind="p"
                      center
                      color={'#aaa'}
                      size={'12px'}
                      uppercase
                    >
                      11/12/2019
                    </Text>
                  </Cell>

                  <Cell width={105}>
                    {applications.communityTurn(agreement.signatures) && (
                      <Button
                        secondary
                        onClick={() =>
                          applications.prepareAgreement(
                            agreement,
                            props.match.params.id,
                            props
                          )
                        }
                      >
                        Prepare
                      </Button>
                    )}
                    {applications.residentTurn(agreement.signatures) && (
                      <Pill gray>Prepared</Pill>
                    )}
                    {applications.managerTurn(agreement.signatures) && (
                      <a
                        href={
                          '/sign/' +
                          agreement.agreement_id +
                          '/' +
                          props.match.params.id +
                          '/Manager/application'
                        }
                      >
                        <Button full>Sign</Button>
                      </a>
                    )}
                    {applications.allSignaturesComplete(
                      agreement.signatures
                    ) && <Pill green>Completed</Pill>}
                  </Cell>

                  <Cell width={35}>
                    <Dots
                      downloading={downloads.includes(agreement.template_id)}
                      src="icon-dots.svg"
                      options={generateOptions(agreement)}
                    />
                  </Cell>
                </Cells>
              </BoxRow>
            );
          })}
      </Box>

      <Modal
        title={'Print All'}
        show={showPrintAllModal}
        close={() => setShowPrintAllModal(false)}
      >
        <Row padding="24px" direction="column">
          <Text weight="700" padding="0 0 16px">
            Select Documents
          </Text>

          {app.agreements.resident.length &&
            app.agreements.resident.map((agreement, index) => {
              return (
                <Row key={index} alignItems="center" padding="8px 0">
                  <Checkbox
                    onClick={() => toggleAgreement(agreement)}
                    selected={agreement.selected}
                  />
                  <Text>{agreement.name}</Text>
                </Row>
              );
            })}

          <Row
            justify="flex-end"
            alignItems="center"
            padding="32px 0 8px"
            margin="0 -6px 0 0"
          >
            <Text size="12px" padding="0 8px 0 0">
              Include Audit Trails?
            </Text>
            <Checkbox
              nomargin
              onClick={() => setIncludeAuditTrail(!includeAuditTrail)}
              selected={includeAuditTrail}
            />
          </Row>
          <Row justify="flex-end">
            <Button onClick={printAll}>Print</Button>
          </Row>
        </Row>
      </Modal>

      <Message show={longMessage}>{longMessage}</Message>

      <MessageBar message={message} listen={changed} />

      <AddAgreements
        application={app}
        show={showAddAgreements}
        close={() => setShowAddAgreements(false)}
        complete={() => props.refresh()}
      />

      <Preview
        agreement={previewDoc}
        show={showPreview}
        close={() => setshowPreview(false)}
      />

      <Print agreement={printAgreement} />

      <PrintAll application={printAgreements} auditTrail={includeAuditTrail} />

      <ForwardAgreement
        user={app}
        agreement={forwardAgreement}
        close={() => setForwardAgreement(undefined)}
      />
    </Row>
  );
};
