import axios from "axios"
import urls from './urls'
import applications from './application'
import pdf from './pdf'
import { arrayBufferToBlob } from 'blob-util'
import aws from './aws'

async function getAgreements(companyId, communityId){
  const promise = new Promise((resolve, reject) => {
    let templates = []

    let companyPage = 0

    async function companyIterate(){
      const companyTemplates = await getCompanyTemplates(companyId, companyPage)

      if(companyTemplates.data.length) {
        templates.push.apply(templates, companyTemplates.data)
        companyPage++
        companyIterate()
      } else {
        commmunityIterate()
      }
    }

    let communityPage = 0

    async function commmunityIterate(){
      const communityTemplates = await getCommunityTemplates(communityId, communityPage)

      if(communityTemplates.data.length) {
        templates.push.apply(templates, communityTemplates.data)
        communityPage++
        commmunityIterate()
      } else {
        resolve(templates)
      }
    }

    companyIterate()
  })

  return promise
}

function create(agreement){
  var object = {
    details: agreement.details,
    pages: agreement.pages,
    templateId: agreement.id,
    auditTrail: {}
  }
  return axios.post(urls.api + 'agreement/', object, {'headers':{
    'X-Auth-Token': localStorage.token,

  }});
}

function get(id){
  return axios.get(urls.api + 'agreement/' + id, {'headers':{
    'X-Auth-Token': localStorage.token,
  }});
}

function update(agreement){
  return axios.patch(urls.api + 'agreement/', agreement, {'headers':{
    'X-Auth-Token': localStorage.token,
  }})
}

function getCompanyTemplates(id, offset){
  return axios.get(urls.api + 'companyTemplates/' + id + '?offset=' + (offset ? (offset*20) : 0), {'headers':{
    'X-Auth-Token': localStorage.token,

  }});
}

function getCommunityTemplates(id, offset){
  return axios.get(urls.api + 'communityTemplates/' + id + '?offset=' + (offset ? (offset*20) : 0), {'headers':{
    'X-Auth-Token': localStorage.token,

  }})
}

async function assignNewAgreementId(newAgreementId, agreementId, applicationId){
  //GET LATEST APPLICATION TO PREVENT OVERWRITTING
  const application = await applications.get(applicationId)
  for(var i in application.data.agreements.resident){
    if(application.data.agreements.resident[i].template_id == agreementId) application.data.agreements.resident[i].agreement_id = newAgreementId
  }
  //UPDATE ONLY AGREEMENT ID ON AGREEMENT
  return applications.update(application.data)
}

async function download(agreement, application){
  const promise = new Promise((resolve, reject) => {

    async function runDownload(){
      try {
        let file_name

        //if(!agreement.agreement_filename){
        if(true){
          const response = await get(agreement.agreement_id)
          const html = applications.generateHtml(response.data)
          const generated = await pdf.generate(html, null, 'pdf')
          file_name = generated.data.Key
          agreement.agreement_filename = file_name
          const latestApplication = await applications.get(application.id)
          for(var i in latestApplication.data.agreements.resident){
            if(latestApplication.data.agreements.resident[i].template_id == agreement.template_id) latestApplication.data.agreements.resident[i] = agreement
          }
          const updatedApplication = await applications.update(latestApplication.data)
        } else {
          file_name = agreement.agreement_filename
        }

        const buffer = await aws.getObject(file_name)
        const blob = arrayBufferToBlob(buffer.data, 'application/pdf')
        const url = window.URL.createObjectURL(blob)
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url
        a.download = 'test'
        a.click();
        window.URL.revokeObjectURL(url);
        resolve(true)
      } catch (error) {

        reject('Error')
        alert('Please change browser permissions to download files, and/or disable any ad blockers')
      }
    }

    runDownload()
  })

  return promise
}

async function generatePhysicianFaxPdf(application){
  const imagesList = { pages: { pages: [] }, details: {} }
  for(var i in application.agreements.physician){
    if(application.agreements.physician[i].marked) {
      for(var j in application.agreements.physician[i].pages){
        imagesList.pages.pages.push(application.agreements.physician[i].pages[j])
      }
    }
  }
  const html = applications.generateHtml(imagesList)
  return pdf.generate(html, null, 'pdf')
}

async function reset(agreement, applicationId){
  var check = window.confirm(
    "This will remove all signatures and reset all fields from all signers"
  );
  if (check) {
    if (agreement.signatures.Resident)
      agreement.signatures.Resident.signed = false;
    if (agreement.signatures.Community)
      agreement.signatures.Community.signed = false;
    if (agreement.signatures.Manager)
      agreement.signatures.Manager.signed = false;
    delete agreement.agreement_id;
    delete agreement.agreement_filename;

    const application = await applications.get(applicationId)

    for (var i in application.data.agreements.resident) {
      if (application.data.agreements.resident[i].template_id == agreement.template_id)
        application.data.agreements.resident[i] = agreement;
    }

    return applications.update(application.data)
  }
}

async function remove(agreement, applicationId){
  var check = window.confirm("Are you sure you want to remove this agreement?");
  if (check) {
    return applications.removeAgreement(applicationId, agreement)
  }
}

const functions = {
  getAgreements,
  create,
  get,
  download,
  reset,
  remove,
  assignNewAgreementId,
  update,
  generatePhysicianFaxPdf
}

export default functions
