import axios from "axios"
import urls from './urls'

function getSignedUrl(file, filename) {
    return axios.get( urls.api + "aws/signedUrl?file_name=" + filename + "&file_type=" + file.type + "", file, {'headers':{
      'X-Auth-Token': localStorage.token,

    }})
  };

function uploadImage(file, signed_request) {
  return axios.put(signed_request, file, {'headers':{
    'X-Auth-Token': localStorage.token,

  }})
};

function getObject(file_name) {
  return axios.get(urls.api + "aws/getObject?file_name=" + file_name, {
    responseType: "arraybuffer",
    'headers':{
      'X-Auth-Token': localStorage.token,

    }
  })
};

function getDocument(file_name) {
  return axios.get(urls.api + "aws/getDocument?file_name=" + file_name, {
    responseType: "arraybuffer",
    'headers':{
      'X-Auth-Token': localStorage.token,

    }
  })
};

function putObject(file, file_name) {
  var config = {
    onUploadProgress: function(progressEvent) {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
    },
    'headers':{
      'X-Auth-Token': localStorage.token,

    }
  };

  return axios.post(urls.api + "aws/putObject?file_name=" + file_name, file, config)
};

function getPreviewSignedUrl(key) {
  return axios.post(urls.api + "aws/getSignedUrl", { key: key }, {'headers':{
    'X-Auth-Token': localStorage.token,

  }})
};

const functions = {
  getSignedUrl,
  uploadImage,
  getObject,
  getDocument,
  putObject,
  getPreviewSignedUrl
}

export default functions
