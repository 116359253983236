import axios from "axios"
import urls from './urls'
import $ from 'jquery'
import _ from 'lodash'
import applications from "../util/application";

function parsePrefills(application){
  var object = {}
  for(var i in application){
    for(var j in application[i]){
      object[j] = application[i][j]
    }
  }
  object.name_first = application.contact_info.first_name;
  object.name_middle = application.contact_info.middle_name;
  object.name_last = application.contact_info.last_name;
  object.name_full = application.contact_info.name;
  object.address_street = application.contact_info.street_address;
  object.address_city = application.contact_info.city;
  object.address_state = application.contact_info.state;
  object.address_zip = application.contact_info.zip;
  object.email_address = application.contact_info.email;
  return object
}

function cleanValue(val) {
  if (Number.isInteger(val)) val = val + "";
  try {
    val = val.replace(/_/g, " ");
  } catch {

  }
  return val;
};

function convertToString(val) {
  if (Number.isInteger(val)) val = val + "";
  return val;
};

function loadPages(pages, filler, prefills, size){
  const html = ''
  for(var i in pages){
    var fields = ''
    for(var j in pages[i].fields){
      var field = pages[i].fields[j]
      if(pages[i].fields[j].filler == filler){
        fields += '<div class="esign-field"\
        data-fieldid='+field.fieldid+'\
        data-type='+field.type+'\
        data-validation='+field.validation+'\
        data-isrequired='+field.isrequired+'\
        data-multiline='+field.multiline+'\
        data-groupname='+field.groupname+'\
        data-grouprequirements='+field.grouprequirements+'\
        data-textalignment='+field.textalignment+'\
        data-signer='+field.signer+'\
        data-filler='+field.filler+'\
        data-prefill='+field.prefill+'\
        data-format='+field.format+' \
        '+ ((field.placeholder && field.placeholder !== "undefined") ? 'data-placeholder='+field.placeholder.replace(/ /g, "_")+'' : null) +' \
        data-value='+(field.value ? convertToString(field.value).replace(/ /g, "_") : null)+'\
        style="position:absolute;\
        width:'+(parseInt(field.position.w))+'px;\
        height:'+(parseInt(field.position.h))+'px;\
        left:'+(parseInt(field.position.x))+'px;\
        top:'+(parseInt(field.position.y))+'px;">\
        </div>'
      } else {
        fields += '<div class="esign-field"\
        data-fieldid='+field.fieldid+'\
        data-type='+field.type+'\
        data-multiline='+field.multiline+'\
        data-textalignment='+field.textalignment+'\
        data-signer='+field.signer+'\
        data-filler='+field.filler+'\
        data-prefill='+field.prefill+'\
        data-value='+ (field.value ? convertToString(field.value).replace(/ /g, "_") : null)+'\
        style={{position:absolute,\
        width:'+(parseInt(field.position.w))+'px,\
        height:'+(parseInt(field.position.h))+'px,\
        left:'+(parseInt(field.position.x))+'px,\
        top:'+(parseInt(field.position.y))+'px}}>\
        </div>'
      }
    }
    var styles = 'background: url('+pages[i].background+'); background-size: cover;'
    if(size){
      styles += 'width: '+ (size.width*72) +'px; height: '+ (size.height*72) +'px;'
    }
    html.concat('<div class="esign-page" style="'+styles+'">'+ fields +'</div>')
  }
  return html
  //buildFields(filler, prefills)
  //$('[data-toggle="tooltip"]').tooltip()
}

function buildFields(filler, prefills){
  //Text Fields
  $('.esign-field[data-type="textbox"]').each(function(){
    var placeholder = $(this).data('placeholder')
    if($(this).data('filler') == filler){
      if($(this).data('multiline') == 'true' || $(this).data('multiline') == true){
        $(this).html('<textarea class="text-input"></textarea>')
      } else {
        //IF FIELD IS EMPTY AND IS A PREFILL
        if($(this).data('prefill') && $(this).data('value') == undefined && $(this).data('value') !== 'null'){
          for(var i in prefills){
            if(i == $(this).data('prefill')){
              if(!$.trim($(this).html()).length){
                var prefill = prefills[i] + ''
                if(prefill !== 'null' && prefill.replace(/\s/g, '').length){
                  placeholder ? $(this).html('<input class="text-input complete" type="text" placeholder="'+placeholder+'" value="'+prefill+'"/>') : $(this).html('<input class="text-input complete" type="text" value="'+prefill+'"/>')

                } else {
                  placeholder ? $(this).html('<input class="text-input" placeholder="'+placeholder+'" type="text"/>') : $(this).html('<input class="text-input" type="text"/>')
                }
              }
            }
          }
          if(!$.trim($(this).html()).length){
            placeholder ? $(this).html('<input class="text-input" placeholder="'+placeholder+'" type="text"/>') : $(this).html('<input class="text-input" type="text"/>')

          }
        }
        //IF FIELD HAS A PREVIOUS ASSIGNED VALUE
        else if($(this).data('value')){
          //REMOVE _ FROM DATA-VALUE TAGS
          var value = cleanValue($(this).data('value'))
          placeholder ? $(this).html('<input class="text-input" placeholder="'+placeholder+'" type="text" value="'+value+'"/>') : $(this).html('<input class="text-input" type="text" value="'+value+'"/>')

        }
        //ELSE CREATE AN EMPTY FIELD
        else {
          placeholder ? $(this).html('<input class="text-input" placeholder="'+placeholder+'" type="text"/>') : $(this).html('<input class="text-input" type="text"/>')

        }
      }}
    else {
      if($(this).data('value')){
        //REMOVE _ FROM DATA-VALUE TAGS
        var value = cleanValue($(this).data('value'))
        $(this).html('<p class="text">'+ value +'</p>')
      } else {
        placeholder ? $(this).html('<input class="text-input disabled" readonly="readonly" type="text" placeholder="'+placeholder+'" data-toggle="tooltip" data-placement="top" title="Resident Field"/>') : $(this).html('<input class="text-input disabled" readonly="readonly" type="text" data-toggle="tooltip" data-placement="top" title="Resident Field"/>')
      }
    }
  })
  //Initials
  $('.esign-field[data-type="initials"]').each(function(){
    if($(this).data('filler') == filler){
      if($(this).data('value') == undefined || $(this).data('value') == 'undefined') {
        $(this).append('<div class="initial-box"><div class="initials"></div><div class="instructions">CLICK TO SIGN</div></div>')
      }
      else {
        var value = cleanValue($(this).data('value'))
        $(this).append('<div class="initial-box"><div class="initials"><p style="font-family: Homemade Apple, cursive;">'+ value +'</p></div><div class="instructions">CLICK TO SIGN</div></div>')
      }
    } else {
      if($(this).data('value')){
        var value = cleanValue($(this).data('value'))
        $(this).html('<p class="text initials">'+ value +'</p>')
      } else {
        $(this).html('<p class="text disabled" data-toggle="tooltip" data-placement="top" title="Resident Field"></p>')
      }
    }
  })
  //Signatures
  $('.esign-field[data-type="signature"]').each(function(){
    if($(this).data('filler') == filler){
      if($(this).data('value') == undefined || $(this).data('value') == 'undefined') {
        $(this).append('<div class="signature-box" onClick={openSigner}><div class="signature"></div><div class="instructions">CLICK TO SIGN</div></div>')
      }
      else {
        var value = cleanValue($(this).data('value'))
        var size = parseInt($(this).css('height').replace('px', '')) - 2
        $(this).append('<div class="signature-box"><div class="signature"><p style="font-family: Homemade Apple, cursive; font-size: '+size+'px;">'+ value +'</p></div><div class="instructions">CLICK TO SIGN</div></div>')
      }
    } else {
      if($(this).data('value')){
        var value = cleanValue($(this).data('value'))
        var size = parseInt($(this).css('height').replace('px', '')) - 2
        $(this).html('<p style="font-size: '+size+'px;" class="signature">'+ value +'</p>')
      }
      else {
       $(this).html('<p class="signature-box disabled" data-toggle="tooltip" data-placement="top" title="Resident Field"></p>')
     }
    }
  })
  //Sign Dates
  $('.esign-field[data-type="date"]').each(function(){
    if($(this).data('value')){
      var value = cleanValue($(this).data('value'))
      $(this).html('<p class="date">'+ value +'</p>')
    }
  })
  //Checkbox
  $('.esign-field[data-type="checkbox"]').each(function(){
    if($(this).data('filler') == filler){
      if($(this).data('value') == undefined || $(this).data('value') == 'undefined') {
        $(this).append('<div class="checkbox-outline"><input class="checkbox-input" type="checkbox"/></div>')
      }
      else {
        if($(this).data('value') == 'true' || $(this).data('value') == true){
          $(this).append('<div class="checkbox-outline"><input class="checkbox-input" type="checkbox" checked="checked"/></div>')
        } else {
          $(this).append('<div class="checkbox-outline"><input class="checkbox-input" type="checkbox"/></div>')
        }
      }
    } else {
      if($(this).data('value') == 'true' || $(this).data('value') == true){
        $(this).append('<img style="width: 20px; height: auto;" src="https://s3-us-west-2.amazonaws.com/docuvault-uploads/check-mark-transparent-pink-27.png"/>')
      }
      else {
       $(this).append('<div class="checkbox-outline disabled" data-toggle="tooltip" data-placement="top" title="Resident Field"></div>')
     }
    }
  })
  assignTabOrder(filler)
  //Create Auto font sizing
  // $('.esign-field input[type=text]').inputfit({
  //   minSize: 8
  // })
  assignMasksAndValidations()
}

function assignTabOrder(filler){
  var positions = []
  $('.esign-field[data-filler="'+filler+'"]').each(function(){
    var object = {
      id: $(this).data('fieldid'),
      top: $(this).offset().top,
      left: parseInt($(this).css('left'))
    }
    positions.push(object)
  })
  positions = _.sortBy(positions, ['top','left'])
  for(var j in positions){
    var currentPos = positions[j]
    var nextPos = positions[parseInt(j)+1]
    if(nextPos){
      if((nextPos.top - currentPos.top) < 15 && currentPos.left > nextPos.left){
        positions[parseInt(j)+1] = positions[j]
        positions[j] = nextPos
      }
    }
  }
  for(var i in positions){
    $('.esign-field[data-fieldid="'+positions[i].id+'"]').find('.text-input').attr('tabindex', (parseInt(i)+1))
    $('.esign-field[data-fieldid="'+positions[i].id+'"]').find('.initial-box').attr('tabindex', (parseInt(i)+1))
    $('.esign-field[data-fieldid="'+positions[i].id+'"]').find('.signature-box').attr('tabindex', (parseInt(i)+1))
    $('.esign-field[data-fieldid="'+positions[i].id+'"]').find('.checkbox-input').attr('tabindex', (parseInt(i)+1))
  }
}

function assignMasksAndValidations(){
  //MASKS
  //$('.esign-field[data-validation="Social_Security_number"] input').mask('(000-00-0000')
  //VALIDATIONS/RESTRICTIONS
  $('.esign-field[data-validation="Numbers_Only"] input').keyup(function () {
    this.value = convertToString(this.value).replace(/[^0-9 \.]/g,'');
  });
  $('.esign-field[data-validation="Letters_Only"] input').keyup(function () {
    this.value = convertToString(this.value).replace(/[^a-zA-Z \.]/g,'');
  });
}

function setFunctions(){

}

async function addPrefills(prefills, applicationId) {
  const application = await applications.get(applicationId);
  let _obj = {};
  prefills.map((_field) => {
    if (_field.value && _field.prefill !== "undefined")
      _obj[_field.prefill] = _field.value;
  });
  application.data.myInfo.prefills = {
    ...application.data.myInfo.prefills,
    ..._obj,
  };
  delete application.data.myInfo.prefills.undefined;
  delete application.data.myInfo.prefills.null;
  await applications.update(application.data);
  return true;
}

const functions = {
  loadPages,
  parsePrefills,
  setFunctions,
  convertToString,
  addPrefills
}

export default functions
