import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Contentful from '../util/contentful'
import { Link, Tabs, Tab, Header, Row, Sidebar, Dashboard, Text, Markdown } from '../components'

const Page = styled.div`
  background: white;
  padding: 48px;
  box-shadow: 0 0 8px rgba(0,0,0,0.2);
  border-radius: 6px;
  width: 100%;
`

const Links = styled(Text)`
  color: #52a0ce;
  display: inline-block;
  position: relative;
  padding-right: 22px;
  &:after {
    content: '';
    background: url(/icon_arrow_right.svg);
    width: 8px;
    height: 10px;
    background-size: contain !important;
    background-repeat: no-repeat;
    position: absolute;
    top: 7px;
    right: 0;
    opacity: 0.7;
  }
`

const Breadcrumb = styled.a`
  color: inherit;
  &:hover {
    color: inherit;
  }
`

const Article = (props) => {
  const [category, setCategory] = useState()
  const [articles, setArticles] = useState()

  useEffect(() => {
    async function getArticles(){
      const _category = await Contentful.query({content_type: 'category', 'fields.url': '/' + window.location.pathname.split('/').pop()})
      const _articles = await Contentful.query({content_type: 'helpArticle', 'fields.category.sys.id': _category.items[0].sys.id})
      setCategory(_category.items[0].fields)
      setArticles(_articles.items)
    }

    getArticles()
  }, [])

  return (
    <main>
      <Header />
      <Row>
        <Sidebar/>
        <Dashboard padding={"0 0 128px"}>
          <Tabs>
            <Link to="/help-center?tab=topics">
              <Tab active>
                <Text kind="p" weight={700} size={'14px'} uppercase>Help Topics</Text>
              </Tab>
            </Link>
            <Link to="/help-center?tab=updates">
              <Tab>
                <Text kind="p" weight={700} size={'14px'} uppercase>New Updates</Text>
              </Tab>
            </Link>
            <Link to="/help-center?tab=contact">
              <Tab>
                <Text kind="p" weight={700} size={'14px'} uppercase>Contact Us</Text>
              </Tab>
            </Link>
          </Tabs>

          <Row padding={"48px"}>
            <Page>
              <Link to="/help-center"><Text size={"12px"} weight={700} margin={"0 0 8px"}>{`< Back`}</Text></Link>

              {category && <Text weight={800} size={"24px"} margin={"0 0 32px"} kind="h2">{category.name}</Text>}

              <Row direction="column">
                {articles && articles.map((item, index) => (
                  <a href={`/help-center/article${item.fields.url}`}><Links margin="0 0 8px" weight="700" key={index}>{item.fields.tItle}</Links></a>
                ))}
              </Row>
            </Page>
          </Row>
        </Dashboard>
      </Row>
    </main>
  )
}

export default Article
