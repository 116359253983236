import axios from "axios"
import urls from './urls'

function get(id){
  return axios.get(urls.api + 'template/' + id, {'headers':{
    'X-Auth-Token': localStorage.token,
    
  }})
}

const functions = {
  get
}

export default functions
