import React from 'react'
import styled from "styled-components"

const Box = styled.div`
  background: white;
  border: 1px solid #ddd;
  width: 100%;
  border-radius: 6px;
  min-height: ${props => props.height ? props.height : 'auto'}
  margin: 0 auto;
  max-width: ${props =>  props.width ? props.width + 'px' : 'auto'}
  @media (prefers-color-scheme: dark) {
    background: #333232;
    border: 1px solid #3c3c3c;
    p {
      color: white;
    }
  }
`

export default props => <Box {...props} />
